<template>
	<fieldset>
		<div class="wrap_line_heading">
			<h5 class="font-weight-semibold">Data Pasien</h5>
			<div class="row">

				<div class="form-group col-md-4 col-lg-3">
					<label>Kewarganegaraan<small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Kewarganegaraan" v-model="isParent.row.ap_kewarganegaraan"
						:options="Config.mr.statusKewarganegaraan" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Kewarganegaraan" v-model="isParent.row.ap_kewarganegaraan"
						:rules="toValidate(isParent.mrValidation.ap_kewarganegaraan)" />
				</div>

				<div class="form-group col-md-4">
					<label>Nama Lengkap <small class="txt_mandatory">*</small></label>
					<b-form-input v-model="isParent.row.ap_fullname" :formatter="isParent.$parent.normalText" type="text"
						name="namaLengkap" id="namaLengkap" class="form-control" placeholder="Nama Lengkap" />
					<VValidate name="Nama Lengkap" v-model="isParent.row.ap_fullname"
						:rules="toValidate(isParent.mrValidation.ap_fullname)" />
				</div>

				<div class="form-group col-md-4 col-lg-3">
					<label>No. KTP/KITAS <small class="text-muted"></small> <small class="txt_mandatory">*</small></label>
					<b-form-input v-model="isParent.row.ap_nik" :formatter="isParent.$parent.number" type="text" name="noKTP"
						id="noKTP" class="form-control" placeholder="No. KTP/KITAS" />
					<VValidate name="No. KTP/KITAS" v-model="isParent.row.ap_nik"
						:rules="toValidate(isParent.mrValidation.ap_nik)" />
				</div>

				<div class="w-100"></div>
				<div class="form-group col-md-3">
					<label>Suku</label>
					<v-select placeholder="Pilih Suku" v-model="isParent.row.ap_suku" :options="isParent.mRoSuku" label="text"
						:clearable="true" :reduce="v=>v.value">
					</v-select>

				</div>

				<div class="form-group col-md-5">

					<label>Bahasa yang dikuasai</label>
					<v-select placeholder="Pilih Bahasa" v-model="isParent.row.ap_language_id" :options="isParent.mRoBahasa"
						label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>

				</div>

				<div class="form-group col-md-5" v-if="isParent.row.ap_language_id != 1">
					<label>Bahasa <small class="text-muted"></small></label>
					<b-form-input v-model="isParent.row.ap_language" :formatter="isParent.$parent.normalText" type="text"
						name="bahasa" id="bahasa" class="form-control" placeholder="Bahasa" />

				</div>

				<div class="form-group col-md-3">
					<label>Jenis Kelamin<small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Jenis Kelamin" v-model="isParent.row.ap_gender"
						:options="Config.mr.StatusKelamin" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Jenis Kelamin" v-model="isParent.row.ap_gender"
						:rules="toValidate(isParent.mrValidation.ap_gender)" />
				</div>

				<div class="form-group col-md-3">
					<label>Tempat Lahir <small class="text-muted"></small> <small class="txt_mandatory">*</small></label>
					<b-form-input v-model="isParent.row.ap_pob" :formatter="isParent.$parent.normalText" type="text" name="bahasa"
						id="bahasa" class="form-control" placeholder="Tempat Lahir" />
					<VValidate name="Tempat Lahir" v-model="isParent.row.ap_pob"
						:rules="toValidate(isParent.mrValidation.ap_pob)" />
				</div>

				<div class="form-group col-md-3">
					<label>Tanggal Lahir<small class="txt_mandatory">*</small></label>
					<div class="input-group">
						<input type="text" placeholder="DD/MM/YYYY" class="form-control" v-mask="'##/##/####'"
							v-model="isParent.row.ap_dob">
						<div class="input-group-append calendar-group">
							<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
						</div>
					</div>
					<VValidate name="Tanggal Lahir" v-model="isParent.row.ap_dob"
						:rules="toValidate(isParent.mrValidation.ap_dob)" />
				</div>

				<div class="form-group col-md-4">
					<label>No Selular<small class="txt_mandatory">*</small></label>
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text" id="basic-addon2">+62</span>
						</div>
						<b-form-input v-model="isParent.row.ap_phone_number" :formatter="isParent.$parent.number" type="text"
							name="bahasa" id="bahasa" class="form-control" placeholder="No Telepon" />
					</div>
					<span><small>Pastikan No.Selular terhubung dengan WhatsApp.</small></span><br />
					<VValidate name="No Selular" v-model="isParent.row.ap_phone_number"
						:rules="toValidate(isParent.mrValidation.ap_phone_number)" />
				</div>

				<div class="form-group col-md-4">
					<label>Email</label>
					<b-form-input v-model="isParent.row.ap_email" type="text" name="email" id="email" class="form-control"
						placeholder="Email" />
				</div>

				<div class="form-group col-md-3">
					<label>Nama Ayah Kandung </label>
					<b-form-input v-model="isParent.row.ap_nama_ayah" :formatter="isParent.$parent.normalText" type="text"
						name="bahasa" id="bahasa" class="form-control" placeholder="Nama Ayah Kandung" />
				</div>

				<div class="form-group col-md-3">
					<label>Nama Ibu Kandung</label>
					<b-form-input v-model="isParent.row.ap_nama_ibu" :formatter="isParent.$parent.normalText" type="text"
						name="bahasa" id="bahasa" class="form-control" placeholder="Nama Ibu Kandung" />
				</div>

				<div class="form-group col-md-3">
					<label>Agama</label>
					<v-select placeholder="Pilih Agama" v-model="isParent.row.ap_agama" :options="isParent.mAgama" label="text"
						:clearable="true" :reduce="v=>v.value">
					</v-select>
				</div>

				<div class="form-group col-md-3" v-if="isParent.row.ap_agama == 99999">
					<label>Agama Lainnya <small class="text-muted"></small> <small class="txt_mandatory">*</small></label>
					<b-form-input v-model="isParent.row.ap_agama_text" :formatter="isParent.$parent.normalText" type="text"
						name="bahasa" id="bahasa" class="form-control" placeholder="Agama Lainnya" />
					<VValidate name="Agama Lainnya" v-model="isParent.row.ap_agama_text"
						:rules="toValidate(isParent.mrValidation.ap_agama_text)" />
				</div>

				<div class="w-100"></div>
				<div class="form-group col-md-3">
					<label>Status Perkawinan</label>
					<v-select placeholder="Pilih Status Perkawinan" v-model="isParent.row.ap_status_pernikahan"
						:options="Config.mr.StatusPernikahan" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
				</div>

				<div class="form-group col-md-3 col-lg-2">
					<label>Golongan Darah</label>
					<v-select placeholder="Pilih Golongan Darah" v-model="isParent.row.ap_gol_darah" :options="Config.mr.golDarah"
						label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
				</div>

				<div class="form-group col-md-5 col-lg-4">
					<label>Pekerjaan</label>
					<v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.ap_pekerjaan" :options="isParent.mPekerjaan"
						label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
				</div>

				<div class="form-group col-md-4 col-md-3" v-if="isParent.row.ap_pekerjaan == 99999">
					<label>Pekerjaan Lainnya <small class="text-muted"></small> <small class="txt_mandatory">*</small></label>
					<b-form-input v-model="isParent.row.ap_pekerjaan_text" :formatter="isParent.$parent.normalText" type="text"
						name="bahasa" id="bahasa" class="form-control" placeholder="Pekerjaan Lainnya" />
					<VValidate name="Pekerjaan Lainnya" v-model="isParent.row.ap_pekerjaan_text"
						:rules="toValidate(isParent.mrValidation.ap_pekerjaan_text)" />
				</div>

				<div class="form-group col-md-3 col-lg-2">
					<label>Pendidikan</label>
					<v-select placeholder="Pilih Pendidikan" v-model="isParent.row.ap_pendidikan" :options="isParent.mPendidikan"
						label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
				</div>
			</div>
		</div>
		<hr>
		<div class="wrap_line_heading">
			<h6 class="font-weight-semibold">Alamat KTP & Domisili</h6>
			<div class="row">
				<div class="form-group col-lg-3 col-md-4">
					<label>Provinsi <small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Provinsi" v-model="isParent.row.ap_provinsi"
						@input="selectKota($event,'mCity',['ap_kota','ap_kecamatan','ap_kelurahan','ap_postal_code'])"
						:options="isParent.mProvinsi" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Provinsi" v-model="isParent.row.ap_provinsi"
						:rules="toValidate(isParent.mrValidation.ap_provinsi)" />
				</div>

				<div class="form-group col-lg-3 col-md-4">
					<label>Kabupaten/Kota <small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Kabupaten/Kota " v-model="isParent.row.ap_kota"
						@input="selectKec(isParent.row.ap_provinsi,$event,'mDistrict',['ap_kecamatan','ap_kelurahan','ap_postal_code'])"
						:options="isParent.mCity" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Kabupaten/Kota " v-model="isParent.row.ap_kota"
						:rules="toValidate(isParent.mrValidation.ap_kota)" />
				</div>

				<div class="form-group col-lg-3 col-md-4">
					<label>Kecamatan<small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Kecamatan" v-model="isParent.row.ap_kecamatan"
						@input="selectKel(isParent.row.ap_provinsi,isParent.row.ap_kota,$event,'mSubDistrict',['ap_kelurahan','ap_postal_code'])"
						:options="isParent.mDistrict" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Kecamatan" v-model="isParent.row.ap_kecamatan"
						:rules="toValidate(isParent.mrValidation.ap_kecamatan)" />
				</div>
				<div class="w-100"></div>
				<div class="form-group col-md-4 col-lg-3">
					<label>Kelurahan<small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Kelurahan" v-model="isParent.row.ap_kelurahan"
						@input="selectPostal(isParent.row.ap_provinsi,isParent.row.ap_kota,isParent.row.ap_kecamatan,$event,'ap_postal_code')"
						:options="isParent.mSubDistrict" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Kelurahan" v-model="isParent.row.ap_kelurahan"
						:rules="toValidate(isParent.mrValidation.ap_kelurahan)" />
				</div>

				<div class="form-group col-md-2">
					<label>Kode Pos <small class="txt_mandatory">*</small></label>
					<input type="text" disabled v-model="isParent.row.ap_postal_code" name="PenanggungJawab" id="PenanggungJawab"
						class="form-control" placeholder=" " value="11620" readonly>
				</div>

				<div class="form-group col-md-3">
					<label>RT/RW <small class="txt_mandatory">*</small></label>
					<div class="input-group">
						<b-form-input v-model="isParent.row.ap_rt" :formatter="isParent.$parent.number" type="text" name="RT"
							id="RT" class="form-control" placeholder="RT" />
						<b-form-input v-model="isParent.row.ap_rw" :formatter="isParent.$parent.number" type="text" name="RW"
							id="RW" class="form-control" placeholder="RW" />
					</div>

					<VValidate name="RT" v-model="isParent.row.ap_rt" :rules="toValidate(isParent.mrValidation.ap_rt)" />
					<VValidate name="RW" v-model="isParent.row.ap_rw" :rules="toValidate(isParent.mrValidation.ap_rw)" />
				</div>
				<div class="form-group col-md-9">
					<label>Alamat KTP <small class="txt_mandatory">*</small></label>
					<b-textarea v-model="isParent.row.ap_address" name="patientNotPresent" id="patientNotPresent" rows="3"
						class="form-control" placeholder="Alamat"></b-textarea>
					<VValidate name="Alamat KTP" v-model="isParent.row.ap_address"
						:rules="toValidate(isParent.mrValidation.ap_address)" />
				</div>

				<div class="col-md-12 mt-2 mb-4">
					<b-form-checkbox v-model="isParent.row.ap_is_same_dom" value="Y" unchecked-value="N"
						@change="changeIsDom($event)">
						Alamat Domisili sama dengan alamat KTP.
					</b-form-checkbox>
				</div>
			</div>


			<!-- DOM -->
			<div class="row">
				<div class="form-group col-md-4">
					<label>Provinsi <small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Provinsi" :disabled="isParent.row.ap_is_same_dom == 'Y'"
						v-model="isParent.row.ap_dom_provinsi"
						@input="selectKota($event,'mCityDom',['ap_dom_kota','ap_dom_kecamatan','ap_dom_kelurahan','ap_dom_postal_code'])"
						:options="isParent.mProvinsiDom" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Provinsi Domisili" v-model="isParent.row.ap_dom_provinsi"
						:rules="toValidate(isParent.mrValidation.ap_dom_provinsi)" />
				</div>

				<div class="form-group col-md-4">
					<label>Kabupaten/Kota <small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Kabupaten/Kota " v-model="isParent.row.ap_dom_kota"
						:disabled="isParent.row.ap_is_same_dom == 'Y'"
						@input="selectKec(isParent.row.ap_dom_provinsi,$event,'mDistrictDom',['ap_dom_kecamatan','ap_dom_kelurahan','ap_dom_postal_code'])"
						:options="isParent.mCityDom" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Kabupaten/Kota Domisili" v-model="isParent.row.ap_dom_kota"
						:rules="toValidate(isParent.mrValidation.ap_dom_kota)" />
				</div>

				<div class="form-group col-md-4">
					<label>Kecamatan<small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Kecamatan" v-model="isParent.row.ap_dom_kecamatan"
						:disabled="isParent.row.ap_is_same_dom == 'Y'"
						@input="selectKel(isParent.row.ap_dom_provinsi,isParent.row.ap_dom_kota,$event,'mSubDistrictDom',['ap_dom_kelurahan','ap_dom_postal_code'])"
						:options="isParent.mDistrictDom" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Kecamatan Domisili" v-model="isParent.row.ap_dom_kecamatan"
						:rules="toValidate(isParent.mrValidation.ap_dom_kecamatan)" />
				</div>
				<div class="w-100"></div>
				<div class="form-group col-md-4">
					<label>Kelurahan<small class="txt_mandatory">*</small></label>
					<v-select placeholder="Pilih Kelurahan" v-model="isParent.row.ap_dom_kelurahan"
						:disabled="isParent.row.ap_is_same_dom == 'Y'"
						@input="selectPostal(isParent.row.ap_dom_provinsi,isParent.row.ap_dom_kota,isParent.row.ap_dom_kecamatan,$event,'ap_dom_postal_code')"
						:options="isParent.mSubDistrictDom" label="text" :clearable="true" :reduce="v=>v.value">
					</v-select>
					<VValidate name="Kelurahan Domisili" v-model="isParent.row.ap_dom_kelurahan"
						:rules="toValidate(isParent.mrValidation.ap_dom_kelurahan)" />
				</div>

				<div class="form-group col-md-2">
					<label>Kode Pos <small class="txt_mandatory">*</small></label>
					<input type="text" disabled v-model="isParent.row.ap_dom_postal_code" name="PenanggungJawab"
						id="PenanggungJawab" class="form-control" placeholder=" " value="11620" readonly>
				</div>

				<div class="form-group col-md-3">
					<label>RT/RW <small class="txt_mandatory">*</small></label>
					<div class="input-group">
						<b-form-input :disabled="isParent.row.ap_is_same_dom == 'Y'" v-model="isParent.row.ap_dom_rt"
							:formatter="isParent.$parent.number" type="text" name="RT" id="RT" class="form-control"
							placeholder="RT" />
						<b-form-input :disabled="isParent.row.ap_is_same_dom == 'Y'" v-model="isParent.row.ap_dom_rw"
							:formatter="isParent.$parent.number" type="text" name="RW" id="RW" class="form-control"
							placeholder="RW" />
					</div>

					<VValidate name="RT Domisili" v-model="isParent.row.ap_dom_rt"
						:rules="toValidate(isParent.mrValidation.ap_dom_rt)" />
					<VValidate name="RW Domisili" v-model="isParent.row.ap_dom_rw"
						:rules="toValidate(isParent.mrValidation.ap_dom_rw)" />
				</div>
				<div class="form-group col-md-9">
					<label>Alamat Domisili <small class="txt_mandatory">*</small></label>
					<b-textarea :disabled="isParent.row.ap_is_same_dom == 'Y'" v-model="isParent.row.ap_dom_address"
						name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control" placeholder="Alamat">
					</b-textarea>
					<VValidate name="Alamat Domisili" v-model="isParent.row.ap_dom_address"
						:rules="toValidate(isParent.mrValidation.ap_dom_address)" />
				</div>
			</div>
		</div>
	</fieldset>
</template>

<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'

export default {
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    }, 
    methods: {
        changeIsDom(e){
            if(e == "Y"){
                this.isParent.row.ap_dom_address = this.isParent.row.ap_address
                this.isParent.row.ap_dom_rt = this.isParent.row.ap_rt
                this.isParent.row.ap_dom_rw = this.isParent.row.ap_rw
                this.isParent.row.ap_dom_kelurahan = this.isParent.row.ap_kelurahan
                this.isParent.row.ap_dom_kecamatan = this.isParent.row.ap_kecamatan
                this.isParent.row.ap_dom_kota = this.isParent.row.ap_kota
                this.isParent.row.ap_dom_provinsi = this.isParent.row.ap_provinsi
                this.isParent.row.ap_dom_postal_code = this.isParent.row.ap_postal_code
                
                this.mProvinsiDom = this.mProvinsi
                this.mCityDom = this.mCity
                this.mDistrictDom = this.mDistrict
                this.mSubDistrictDom = this.mSubDistrict
            }else{
                this.isParent.row.ap_dom_address = null
                this.isParent.row.ap_dom_rt = null
                this.isParent.row.ap_dom_rw = null
                this.isParent.row.ap_dom_kelurahan = null
                this.isParent.row.ap_dom_kecamatan = null
                this.isParent.row.ap_dom_kota = null
                this.isParent.row.ap_dom_provinsi = null
                this.isParent.row.ap_dom_postal_code = null
            }
        },
        toValidate(val){
            return {...val}
        },
        selectKota(provinsi,master,nullval=[]){
            let data = {
                provinsi : provinsi,
                type : 'select-city'
            }
            this.isParent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent[master] = res.data
                
                for(let i = 0; i < (nullval||[]).length; i++){
                    this.isParent.row[nullval[i]] = null    
                }
                this.isParent.$parent.loadingOverlay = false
            })
        },
        selectKec(provinsi,kota,master,nullval=[]){
            let data = {
                provinsi : provinsi,
                kota : kota,
                type : 'select-kec'
            }
            this.isParent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent[master] = res.data
                
                for(let i = 0; i < (nullval||[]).length; i++){
                    this.isParent.row[nullval[i]] = null    
                }
                this.isParent.$parent.loadingOverlay = false

            })
            
        },
        selectKel(provinsi,kota,kecamatan,master,nullval=[]){
            let data = {
                provinsi : provinsi,
                kota : kota,
                kecamatan : kecamatan,
                type : 'select-kel'
            }
            this.isParent.$parent.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent[master] = res.data
                
                for(let i = 0; i < (nullval||[]).length; i++){
                    this.isParent.row[nullval[i]] = null    
                }
                this.isParent.$parent.loadingOverlay = false

            })
        },
        selectPostal(provinsi,kota,kecamatan,kelurahan,postal){
            let data = {
                provinsi : provinsi,
                kota : kota,
                kecamatan : kecamatan,
                kelurahan : kelurahan,
                type : 'select-postal'
            }
            this.isParent.$parent.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent.row[postal] = res.data
                this.isParent.$parent.loadingOverlay = false
            })
        }
    },
}
</script>