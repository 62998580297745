<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 mb-2">
        <div class="bg_card_blue p-3">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Reg</h4>
                    <p>{{isParent.rowReg.aranr_reg_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{isParent.rowReg.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Pasien</h4>
                    <p>{{isParent.rowReg.ap_fullname||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir </h4>
                    <p>{{isParent.rowReg.ap_address||"-"}}, 
                    <span v-if="isParent.rowReg.ap_dob">{{isParent.rowReg.ap_dob | moment("DD MMMM YYYY")}}</span>
                    <span v-else> - </span>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{isParent.rowReg.ap_usia_with_ket}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{isParent.rowReg.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card" v-if="isParent.row.ap_usia <= 18">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Kajian Humpty Dumpty</h6>
      </div>
      <table class="table table-bordered table-sm">
        <tbody>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Umur</h4>
                <p v-if="rowData.arankprjd_humpty_umur != null">{{getConfigDynamic(Config.mr.hdUmur,rowData.arankprjd_humpty_umur)}} <strong class="text-info-800">({{rowData.arankprjd_humpty_umur}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Jenis Kelamin</h4>
                <p v-if="rowData.arankprjd_humpty_jenis_kelamin != null">{{getConfigDynamic(Config.mr.hdJK,rowData.arankprjd_humpty_jenis_kelamin)}} <strong class="text-info-800">({{rowData.arankprjd_humpty_jenis_kelamin}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Diagnosis</h4>
                <p v-if="rowData.arankprjd_humpty_diagnosis != null">{{getConfigDynamic(Config.mr.hdDiagnosis,rowData.arankprjd_humpty_diagnosis)}} <strong class="text-info-800">({{rowData.arankprjd_humpty_diagnosis}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Gangguan Kognitif</h4>
                <p v-if="rowData.arankprjd_humpty_gangguan_kognitif != null">{{getConfigDynamic(Config.mr.hdGangguanKognitif,rowData.arankprjd_humpty_gangguan_kognitif)}} <strong class="text-info-800">({{rowData.arankprjd_humpty_gangguan_kognitif}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Faktor Lingkungan</h4>
                <p v-if="rowData.arankprjd_humpty_faktor_lingkungan != null">{{getConfigDynamic(Config.mr.hdFaktorLingkungan,rowData.arankprjd_humpty_faktor_lingkungan)}} <strong class="text-info-800">({{rowData.arankprjd_humpty_faktor_lingkungan}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Respon Pembedahan</h4>
                <p v-if="rowData.arankprjd_humpty_respon_pembedahan != null">{{getConfigDynamic(Config.mr.hdPembedahan,rowData.arankprjd_humpty_respon_pembedahan)}} <strong class="text-info-800">({{rowData.arankprjd_humpty_respon_pembedahan}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Penggunaan obat</h4>
                <p v-if="rowData.arankprjd_humpty_respon_penggunaan_obat != null">{{getConfigDynamic(Config.mr.hdPenggunaanObat,rowData.arankprjd_humpty_respon_penggunaan_obat)}} <strong class="text-info-800">({{rowData.arankprjd_humpty_respon_penggunaan_obat}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="table-info">
            <td colspan="5" class="text-info-700">
              <span class="text-uppercase">Tingkat Risiko Humpty Dumpty: {{hdTotal}}</span>
              <span v-if="hdTotal < 7" class="border-left ml-2 pl-2 font-weight-semibold">Tidak Beresiko</span>
              <span v-else-if="hdTotal >= 7 && hdTotal <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
              <span v-else-if="hdTotal >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="card" v-else-if="isParent.row.ap_usia > 18 && isParent.row.ap_usia <= 60">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Kajian Morse Fall Scale</h6>
      </div>
      <table class="table table-bordered table-sm">
        <tbody>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Riwayat Jatuh atau dalam 3 bulan terakhir</h4>
                <p v-if="rowData.arankprjd_morshe_riwayat_jatuh != null">{{getConfigDynamic(Config.mr.snRiwayatJatuh,rowData.arankprjd_morshe_riwayat_jatuh)}} <strong class="text-info-800">({{rowData.arankprjd_morshe_riwayat_jatuh}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Diagnosis medis sekunder > 1</h4>
                <p v-if="rowData.arankprjd_morshe_diagnosa != null">{{getConfigDynamic(Config.mr.snDiagnosa,rowData.arankprjd_morshe_diagnosa)}} <strong class="text-info-800">({{rowData.arankprjd_morshe_diagnosa}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Alat bantu jalan</h4>
                <p v-if="rowData.arankprjd_morshe_alat_bantu != null">{{getConfigDynamic(Config.mr.snAlatBantuJalan,rowData.arankprjd_morshe_alat_bantu)}} <strong class="text-info-800">({{rowData.arankprjd_morshe_alat_bantu}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Pakai infus</h4>
                <p v-if="rowData.arankprjd_morshe_inpus != null">{{getConfigDynamic(Config.mr.snPakaiInfus,rowData.arankprjd_morshe_inpus)}} <strong class="text-info-800">({{rowData.arankprjd_morshe_inpus}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Cara berjalan / berpindah</h4>
                <p v-if="rowData.arankprjd_morshe_cara_jalan != null">{{getConfigDynamic(Config.mr.snCaraJalan,rowData.arankprjd_morshe_cara_jalan)}} <strong class="text-info-800">({{rowData.arankprjd_morshe_cara_jalan}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Status mental</h4>
                <p v-if="rowData.arankprjd_morshe_status_mental != null">{{getConfigDynamic(Config.mr.snStatusMental,rowData.arankprjd_morshe_status_mental)}} <strong class="text-info-800">({{rowData.arankprjd_morshe_status_mental}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="table-info">
            <td colspan="5" class="text-info-700">
              <span class="text-uppercase">Tingkat Risiko Morse Fall Scale: {{morsheTotal}}</span>
              <span v-if="morsheTotal < 24" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
              <span v-else-if="morsheTotal >= 25 && morsheTotal <= 44" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
              <span v-else-if="morsheTotal > 45" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
            </td>
          </tr>
        </tfoot>
      </table>
      <div class="row no-gutters">
        <div class="col-md-6">
          <table class="table table-sm table-striped table-bordered">
            <thead>
              <th>Hasil</th>
              <th>Tindakan</th>
            </thead>
            <tbody>
              <tr>
                <td>Risiko Rendah</td>
                <td>Lakukan intervensi jatuh standar</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card" v-else-if="isParent.row.ap_usia > 60">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Kajian Skala Ontario Modified Stratify - Sydney Scoring</h6>
      </div>
      <table class="table table-sm table-bordered">
        <tbody>
          <tr class="table-warning">
            <th colspan="3">
              <span class="font-weight-semibold">Riwayat Jatuh <span class="text-info">({{rowData.arankprjd_riwayat_jatuh_is_jatuh=='Y' || rowData.arankprjd_riwayat_jatuh_is_jatuh2bulan=='Y'?6:0}})</span></span>
            </th>
          </tr>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Apakah pasien datang ke rumah sakit karena jatuh?</h4>
                
                <p v-if="rowData.arankprjd_riwayat_jatuh_is_jatuh != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_riwayat_jatuh_is_jatuh)}}</p>
                <p v-else> - </p>

              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Jika tidak, apakah pasien mengalami jatuh dalam 2 bulan terakhir?</h4>
                <p v-if="rowData.arankprjd_riwayat_jatuh_is_jatuh2bulan != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_riwayat_jatuh_is_jatuh2bulan)}}</p>
                <p v-else> - </p>
              </div>
            </td>
            <td></td>
          </tr>
          <tr class="table-warning">
            <th colspan="3">
              <span class="font-weight-semibold">Status Mental <span class="text-info">({{(rowData.arankprjd_status_mental_dellirium == "Y" || rowData.arankprjd_status_mental_disorientasi == "Y" ||
              rowData.arankprjd_status_mental_agitasi == "Y") ? 14 : 0}})</span></span>
            </th>
          </tr>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Apakah pasien delirium? (tidak membuat keputusan, pola pikir tidak terorganisir, gangguan daya ingat)</h4>
                <p v-if="rowData.arankprjd_status_mental_dellirium != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_status_mental_dellirium)}}</p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Apakah pasien disorientasi? (salah menyebutkan waktu, tempat atau orang)</h4>
                <p v-if="rowData.arankprjd_status_mental_disorientasi != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_status_mental_disorientasi)}}</p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Apakah pasien mengalami agatis? (ketakutan, gelisah dan cemas)</h4>
                <p v-if="rowData.arankprjd_status_mental_agitasi != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_status_mental_agitasi)}}</p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
          <tr class="table-warning">
            <th colspan="3">
              <span class="font-weight-semibold">Penglihatan <span class="text-info">(
                {{(rowData.arankprjd_status_mental_dellirium == "Y" || rowData.arankprjd_status_mental_disorientasi == "Y" ||
                rowData.arankprjd_status_mental_agitasi == "Y" )?1:0}}
                )</span></span>
            </th>
          </tr>
          <tr>
            <td>
              <div class="result_tab">
                <h4>Apakah pasien memakai kacamata?</h4>
                <p v-if="rowData.arankprjd_penglihatan_kacamata != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_penglihatan_kacamata)}}</p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Apakah pasien mengeluh penglihatan buram?</h4>
                <p v-if="rowData.arankprjd_penglihatan_buram != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_penglihatan_buram)}}</p>
                <p v-else> - </p>
              </div>
            </td>
            <td>
              <div class="result_tab">
                <h4>Apakah pasien mempunyai glaucoma, katarak, atau degenerasi makula?</h4>
                <p v-if="rowData.arankprjd_penglihatan_glaucoma != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_penglihatan_glaucoma)}}</p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
          <tr class="table-warning">
            <th colspan="3">
              <span class="font-weight-semibold">Kebiasaan <span class="text-info">({{rowData.arankprjd_kebiasaan == 'Y'?2:0}})</span></span>
            </th>
          </tr>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Apakah terdapat perubahan perilaku berkemih? (frekuensi, urgensi, inkontinensia, nokturia)</h4>
                <p v-if="rowData.arankprjd_kebiasaan != null">{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankprjd_kebiasaan)}}</p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
          <tr class="table-warning">
            <th colspan="3">
              <span class="font-weight-semibold">Transfer (dari tempat tidur ke kursi dan kembali ke tempat tidur)</span>
            </th>
          </tr>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Transfer</h4>
                <p v-if="rowData.arankprjd_transfer != null">{{getConfigDynamic(Config.mr.syTransfer,rowData.arankprjd_transfer)}} <strong class="text-info-800">({{rowData.arankprjd_transfer}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
          <tr class="table-warning">
            <th colspan="3">
              <span class="font-weight-semibold">Mobilitas</span>
            </th>
          </tr>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Mobilitas</h4>
                <p v-if="rowData.arankprjd_mobilitas != null">{{getConfigDynamic(Config.mr.syMobilitas,rowData.arankprjd_mobilitas)}} <strong class="text-info-800">({{rowData.arankprjd_mobilitas}})</strong></p>
                <p v-else> - </p>
              </div>
            </td>
          </tr>
        </tbody>
        
        <tfoot>
          <tr class="table-info">
            <td colspan="5" class="text-info-700">
              <span class="text-uppercase">Tingkat Risiko Ontario Modified Stratify - Sydney Scoring: {{getTotalSydney}}</span>

              <span v-if="getTotalSydney < 6" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
              <span v-else-if="getTotalSydney >= 6 && getTotalSydney < 17" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
              <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
            </td>
            <!-- Penilaian Tingkat Risiko Ontario Modified Stratify - Sydney Scoring
            0 - 5 = Risiko Rendah
            6 - 16 = Risiko Sedang
            17 - 30 = Risiko Tinggi
            -->
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Checklist Pengaman</h6>
      </div>
      <table class="table table-bordered table-sm">
        <tbody>
          <tr class="table-warning">
            <th><span class="font-weight-semibold">TEMPAT TIDUR</span></th>
            <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
            </tr>
            <tr class="table-secondary ">
            <td colspan="2">
                Pegangan sisi tempat tidur
            </td>
          </tr>
          <tr>
            <td>
              Mudah dinaikkan dan diturunkan
            </td>
            <td>
              {{rowData.arankprjd_bed_cek_naik_turun == 'Y' ? "Ya" : "Tidak"}}
            </td>
          </tr>
          <tr>
            <td>
                Terkunci dengan aman saat dinaikkan
            </td>
            <td>
              {{rowData.arankprjd_bed_cek_aman_saat_naik == 'Y' ? "Ya" : "Tidak"}}
            </td>
          </tr>

            <tr>
            <td>
                Hanya digunakan untuk mobilitas
            </td>
            <td>
              {{rowData.arankprjd_bed_cek_saat_mobilitas == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Roda
            </td>
            </tr>
            
            <tr>
            <td>
                Mudah berputar/diarahkan, tidak melekat
            </td>
            <td>
              {{rowData.arankprjd_bed_cek_roda == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Rem
            </td>
            </tr>

            <tr>
            <td>
                Mengamankan tempat tidur saat dioperasikan
            </td>
            <td>
              {{rowData.arankprjd_bed_cek_rem == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Mekanik
            </td>
            </tr>

            <tr>
            <td>
              Pengaturan ketinggian tempat tidur mudah dilakukan
            </td>
            <td>
              {{rowData.arankprjd_bed_cek_mekanik == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Meja samping tempat tidur
            </td>
            </tr>

            <tr>
            <td>
                Roda terkunci dengan baik
            </td>
            <td>
                {{rowData.arankprjd_bed_cek_meja_terkunci == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Letaknya disamping tempat tidur, menempel didinding
            </td>
            <td>
                {{rowData.arankprjd_bed_cek_meja_didinding == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>
        </tbody>
        
        <tbody>
            <tr class="table-warning">
            <th><span class="font-weight-semibold">TIANG INFUS</span></th>
            <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Tiang
            </td>
            </tr>

            <tr>
            <td>
                Mudah dinaikkan dan diturunkan
            </td>
            <td>
                {{rowData.arankprjd_tiang_cek_naik_turun == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Stabil, tidak mudah goyang
            </td>
            <td>
                {{rowData.arankprjd_tiang_cek_stabil == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Roda
            </td>
            </tr>

            <tr>
            <td>
                Mudah berputar/diarahkan, tidak melekat
            </td>
            <td>
                {{rowData.arankprjd_tiang_cek_berputar == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>


        </tbody>
        
        <tbody>
            <tr class="table-warning">
            <th><span class="font-weight-semibold">TUMPUAN KAKI</span></th>
            <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
            </tr>
            <tr class="table-secondary ">
            <td colspan="2">
                Kaki kursi
            </td>
            </tr>

            <tr>
            <td>
                Proteksi karet anti-selip dikesemua kaki
            </td>
            <td>
                {{rowData.arankprjd_tumpuan_proteksi == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Stabil, tidak goyang
            </td>
            <td>
                {{rowData.arankprjd_tumpuan_stabil == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Bagian atas kursi
            </td>
            </tr>

            <tr>
            <td>
                Permukaan tidak licin
            </td>
            <td>
                {{rowData.arankprjd_tumpuan_tidak_licin == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>
        </tbody>

        <tbody>
            <tr class="table-warning">
            <th><span class="font-weight-semibold">BEL PANGGILAN/ PENCAHAYAAN</span></th>
            <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
            </tr>
            <tr class="table-secondary ">
            <td colspan="2">
                Operasional
            </td>
            </tr>

            <tr>
            <td>
                Lampu diluar kamar
            </td>
            <td>
                {{rowData.arankprjd_bel_lampu_kamar == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Alarm berbunyi di counter
            </td>
            <td>
                {{rowData.arankprjd_bel_alarm == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Nomor kamar muncul di monitor
            </td>
            <td>
                {{rowData.arankprjd_bel_nomor_kamar == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Sinyal panel kamar
            </td>
            <td>
                {{rowData.arankprjd_bel_sinyal_kamar == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Akses
            </td>
            </tr>

            <tr>
            <td>
                Mudah diraih saat di kamar mandi
            </td>
            <td>
                {{rowData.arankprjd_bel_mudah_diraih == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Dalam jangkauan saat pasien di tempat tidur
            </td>
            <td>
                {{rowData.arankprjd_bel_tempat_tidur == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>
        </tbody>

        <tbody>
            <tr class="table-warning">
            <th><span class="font-weight-semibold">WALKER/ CANE</span></th>
            <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
            </tr>
            <tr class="table-secondary ">
            <td colspan="2">
                Keamanan
            </td>
            </tr>

            <tr>
            <td>
                Ujung karet pada alat berfungsi dengan baik
            </td>
            <td>
                {{rowData.arankprjd_walker_ujung_karet == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Stabil
            </td>
            <td>
                {{rowData.arankprjd_walker_stabil == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>
        </tbody>

        <tbody>
            <tr class="table-warning">
            <th><span class="font-weight-semibold">TOILET BERJALAN</span></th>
            <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
            </tr>
            <tr class="table-secondary ">
            <td colspan="2">
                Roda
            </td>
            </tr>

            <tr>
            <td>
                Mudah berputar/diarahkan, tidak melekat
            </td>
            <td>
                {{rowData.arankprjd_toilet_berputar == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Stabil saat pasien duduk diatasnya
            </td>
            <td>
                {{rowData.arankprjd_toilet_stabil == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>


            <tr class="table-secondary ">
            <td colspan="2">
                Rem
            </td>
            </tr>

            <tr>
            <td>
                Mengamankan tempat tidur saat dioperasikan
            </td>
            <td>
                {{rowData.arankprjd_rem == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

        </tbody>

        <tbody>
            <tr class="table-warning">
            <th><span class="font-weight-semibold">KURSI BERODA (MOBILITY CHAIR)</span></th>
            <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
            </tr>
            <tr class="table-secondary ">
            <td colspan="2">
                Kursi
            </td>
            </tr>

            <tr>
            <td>
                Tingginya disesuaikan dengan pasien, untuk meminimalisir terjatuh/ terjungkal
            </td>
            <td>
                {{rowData.arankprjd_kursi_tinggi_sesuai == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Roda
            </td>
            </tr>

            <tr>
            <td>
                Mudah berputar/diarahkan, tidak melekat
            </td>
            <td>
                {{rowData.arankprjd_kursi_roda_berputar == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>
            <tr class="table-secondary ">
            <td colspan="2">
                Rem
            </td>
            </tr>

            <tr>
            <td>
                Dioperasikan saat kursi dalam posisi diam
            </td>
            <td>
                {{rowData.arankprjd_kursi_rem_dioperasikan == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Mengamankan toilet saat dioperasikan
            </td>
            <td>
                {{rowData.arankprjd_kursi_rem_aman == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr>
            <td>
                Pengaman kursi
            </td>
            <td>
                {{rowData.arankprjd_kursi_rem_pengaman == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Tumpuan kaki
            </td>
            </tr>

            <tr>
            <td>
                Dapat dilipat
            </td>
            <td>
                {{rowData.arankprjd_kursi_kaki_dapat_dilipat == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Posisi
            </td>
            </tr>

            <tr>
            <td>
                Diposisikan dengan derajat kemiringan yang sesuai untuk mencegah terjungkal kedepan/
                merosot
            </td>
            <td>
                {{rowData.arankprjd_kursi_kaki_derajat_kemiringan == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

            <tr class="table-secondary ">
            <td colspan="2">
                Nampan
            </td>
            </tr>

            <tr>
            <td>
                Dalam posisi aman
            </td>
            <td>
                {{rowData.arankprjd_kursi_kaki_posisi_aman == 'Y' ? "Ya" : "Tidak"}}
            </td>
            </tr>

        </tbody>
      </table>
    </div>
    
    <div class="card ">
      <div class="card-header bg-info">
      <h5 class="card-title font-weight-semibold">Jadwal Monitoring</h5>
      </div>
      <div>
        <table class="table table-bordered table-sm table-striped mt-2">
        <thead>
          <tr>
          <td>Jadwal</td>
          <td>Tanggal dan Jam</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v,k) in (rowData.arankprjd_jadwal_monitoring||[])" :key="k+'jm'">
            <td>
              Ke-{{k+1}}
            </td>
            <td>
              {{v.date | moment("DD MMMM YYYY")}} {{v.jam}}
            </td>
          </tr>
          <tr v-if="!(rowData.arankprjd_jadwal_monitoring||[]).length">
            <td colspan="99" class="text-center">Tidak ada data</td>
          </tr>
        </tbody>
        </table>
    </div>
    </div>
  </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
    resikoIntervensiType(){
      if(this.isParent.row.ap_usia <= 18){
          // humpty dumpty
          if(this.hdTotal < 7){
              return 'TB'
          }else if(this.hdTotal >= 7 && this.hdTotal <= 11){
              return 'S'
          }else{
              return 'T'
          }
      }else if(this.isParent.row.ap_usia > 18 && this.isParent.row.ap_usia <= 60){
          // morshe                
          if(!this.morsheTotal){
              return 'TB'
          }else if(this.morsheTotal >= 1 && this.morsheTotal < 24){
              return 'S'
          }else if(this.morsheTotal >= 25 && this.morsheTotal <= 44){
              return 'S'
          }else{
              return 'T'
          }
      }else if(this.isParent.row.ap_usia > 60){
          if(!this.getTotalSydney){
              return 'TB'
          }else if(this.getTotalSydney >= 1 && this.getTotalSydney < 6){
              return 'S'
          }else if(this.getTotalSydney >= 6 && this.getTotalSydney < 17){
              return 'S'
          }else{
              return 'T'
          }
      }else{
          return 'TB'
      } 
    },
    
    edTotal(){
        return (this.rowData.arankprjd_ed_umur||0) + 
        (this.rowData.arankprjd_ed_status_mental||0) + 
        ((this.rowData.arankprjd_ed_pola_pikir == 11 ? (11+1): this.rowData.arankprjd_ed_pola_pikir)||0) + 
        (this.rowData.arankprjd_ed_pengobatan||0) + 
        (this.rowData.arankprjd_ed_diagnosis||0) + 
        (this.rowData.arankprjd_ed_ambulasi||0) + 
        (this.rowData.arankprjd_ed_nutrisi||0) + 
        (this.rowData.arankprjd_ed_riwayat_jatuh||0) 
    },

    hdTotal(){
        return (this.rowData.arankprjd_humpty_umur||0) + 
        (this.rowData.arankprjd_humpty_jenis_kelamin||0) + 
        (this.rowData.arankprjd_humpty_diagnosis||0) + 
        (this.rowData.arankprjd_humpty_gangguan_kognitif||0) + 
        (this.rowData.arankprjd_humpty_faktor_lingkungan||0) + 
        (this.rowData.arankprjd_humpty_respon_pembedahan||0) +
        (this.rowData.arankprjd_humpty_respon_penggunaan_obat||0) 
    },

    morsheTotal(){
        return (this.rowData.arankprjd_morshe_riwayat_jatuh||0) + 
        (this.rowData.arankprjd_morshe_diagnosa||0) + 
        (this.rowData.arankprjd_morshe_alat_bantu||0) + 
        (this.rowData.arankprjd_morshe_inpus||0) + 
        (this.rowData.arankprjd_morshe_cara_jalan||0) + 
        (this.rowData.arankprjd_morshe_status_mental||0) 
    },

    
    getTotalSydney(){
      let total = 0
      
      if(this.rowData.arankprjd_riwayat_jatuh_is_jatuh == "Y" || this.rowData.arankprjd_riwayat_jatuh_is_jatuh2bulan == "Y"){
          total = total + 6
      }

      if(this.rowData.arankprjd_status_mental_dellirium == "Y" || this.rowData.arankprjd_status_mental_disorientasi == "Y" ||
      this.rowData.arankprjd_status_mental_agitasi == "Y" ){
          total = total + 14
      }
      
      if(this.rowData.arankprjd_penglihatan_kacamata == "Y" || this.rowData.arankprjd_penglihatan_buram == "Y" ||
      this.rowData.arankprjd_penglihatan_glaucoma == "Y" ){
          total = total + 1
      }

      if(this.rowData.arankprjd_kebiasaan == "Y"){
          total = total + 2
      }

      if(this.rowData.arankprjd_transfer + this.rowData.arankprjd_mobilitas > 3){
          total = total + 7
      }
      return total  
    },
  },
  data() {
    return {
      rowData: {},
      rowReg: {},
    }
  },
  methods: {
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    apiGetForm(params = {}, page = 1){
      if(!this.isParent.regId) if(!this.pageSlug){
        this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = (this.isParent.regId ? "RanapPasienKajianResikoJatuh" : this.modulePage)+'/'+(this.$parent.regId||this.$route.params.pageSlug)+'/'+this.isParent.rowEdit.arankprj_id
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })
    },
  },
  mounted() {
    console.log(this.isParent)
    this.apiGetForm()
  }
}

</script>