<template>
  <div>
    <view-data v-if="parentData.viewType === 'VIEW' || !parentData.viewType" />

    <form-data v-else-if="parentData.viewType === 'FORM_INTENSIVE'" />    
  </div>
</template>

<script>
import ViewData from './ViewData.vue'
import FormData from './FormData.vue'
import Gen from '@/libs/Gen'

export default {
  components: {
    ViewData, FormData
  },

  props: {
    regId: Number,
  },

  data(){
    return {
      tekananDarah: {
        data: {}
      },
      balanceCairan: {},
      hermodinamy: {
        data: {},
      },

      mrValidation: {},

      mrNadi: [],
      mrGCS: {
        responseMata: [],
        responseVerbal: [],
        responseMotorik: [],
      },
      mrGCSCategory: {},
      mrReaksiCahaya: [],
      mrCRT: [],
      mrKeadaanUmum: [],
      mrGerakan: [],
      mrVentilasiType: [],

      tHistoryData:{
        data:[],
        page:{
          total_data:0,
          per_page: 10,
          current_page:1
        }
      },
      pageNoTData: 1,

      formData: {},
    }
  },

  mounted() {
    if(this.parentData.viewType === 'VIEW') {
      this.getGraphicData()
    } else if(this.parentData.viewType === 'FORM_INTENSIVE') {
      this.getFormData()
    }
  },

  methods: {
    getGraphicData(page=1){
      Gen.apiRest(
        "/get/"+'RanapPasienMonitoringIntensive',
        {
          params: {
            page,
            type: 'get-graphic-data',
            id_registrasi: (this.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res=>{
        const { 
          balanceCairan, hermodinamy,
          tekananDarah
        }  = res.data.data

        this.$set(this, 'balanceCairan', balanceCairan)
        this.$set(this, 'hermodinamy', hermodinamy)
        this.$set(this, 'tekananDarah', tekananDarah)
      })
    },

    getFormData(){
      Gen.apiRest(
        "/get/"+'RanapPasienMonitoringIntensive',
        {
          params: {
            type: 'get-form',
            id_registrasi: (this.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res=>{
        const { 
          mrValidation,
          mrNadi, mrReaksiCahaya, mrCRT, 
          mrGCS, mrGCSCategory, mrKeadaanUmum,
          mrGerakan, mrVentilasiType, formData
        }  = res.data

        this.$set(this, 'formData', formData)
        this.$set(this, 'mrValidation', mrValidation)
        this.$set(this, 'mrNadi', mrNadi)
        this.$set(this, 'mrReaksiCahaya', mrReaksiCahaya)
        this.$set(this, 'mrCRT', mrCRT)
        this.$set(this, 'mrGCS', mrGCS)
        this.$set(this, 'mrGCSCategory', mrGCSCategory)
        this.$set(this, 'mrKeadaanUmum', mrKeadaanUmum)
        this.$set(this, 'mrGerakan', mrGerakan)
        this.$set(this, 'mrVentilasiType', mrVentilasiType)
      })
    },

    getHistoryData(page=1){
      Gen.apiRest(
        "/get/"+'RanapPasienMonitoringIntensive',
        {
          params: {
            page,
            type: 'get-history',
            id_registrasi: (this.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res=>{
        const { 
          historyData,
        }  = res.data

        this.$set(this, 'tHistoryData', historyData)
      })
    },

    doSubmitIntensive(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/"+'RanapPasienMonitoringIntensive',
        { 
          data: {
            ...data,
            id_registrasi: (this.regId||this.$route.params.pageSlug)
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$set(this.$emit('changeViewType', 'VIEW'))
          }
        })
      }).catch(()=>{
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },
  },

  computed: {
    parentData(){
      return this.$parent
    },

    moduleRole(){
      return this.parentData.moduleRole
    },

    timeSQLtoNormal(){
      return this.parentData.timeSQLtoNormal
    },
  },

  watch: {
    'parentData.viewType'(v){
      Gen.scrollToTop()

      if(v === 'VIEW') {
        this.getGraphicData()
      } else if(v === 'FORM_INTENSIVE') {
        this.getFormData()
      }
    },

    'pageNoTData'(v){
      this.getHistoryData(v)
    }
  }
}
</script>