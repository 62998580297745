<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 mb-2">
        <div class="bg_card_blue p-3">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Reg</h4>
                    <p>{{isParent.rowReg.aranr_reg_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{isParent.rowReg.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Pasien</h4>
                    <p>{{isParent.rowReg.ap_fullname||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir </h4>
                    <p>{{isParent.rowReg.ap_address||"-"}}, 
                    <span v-if="isParent.rowReg.ap_dob">{{isParent.rowReg.ap_dob | moment("DD MMMM YYYY")}}</span>
                    <span v-else> - </span>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{isParent.rowReg.ap_usia_with_ket}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{isParent.rowReg.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    
    <div class="mt-2">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tekanan Darah</h4>
                          <p>{{rowData.arankpkrd_ttv_tekanan_darah_min||"-"}}/{{rowData.arankpkrd_ttv_tekanan_darah_max||"-"}} mmHG</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Nadi</h4>
                          <p>{{rowData.arankpkrd_ttv_nadi||"-"}}x/mnt</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Gula Darah</h4>
                          <p>{{rowData.arankpkrd_ttv_gula_darah||"-"}}mg/dL</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Pernafasan</h4>
                          <p>{{rowData.arankpkrd_ttv_pernafasan||"-"}}x/mnt - {{getConfigDynamic(Config.mr.StatusRegular,rowData.arankpkrd_ttv_label)||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>SPO2</h4>
                          <p>{{rowData.arankpkrd_ttv_spo2||"-"}}%</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Suhu</h4>
                          <p>{{rowData.arankpkrd_ttv_suhu||"-"}}C</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Berat Badan</h4>
                          <p>{{rowData.arankpkrd_ttv_weight||"-"}}kg</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tinggi</h4>
                          <p>{{rowData.arankpkrd_ttv_height||"-"}}Cm</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Lingkar Kepala</h4>
                          <p>{{rowData.arankpkrd_ttv_lingkar_kepala||"-"}}cm</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>BMI</h4>
                          <p>{{rowData.arankpkrd_ttv_bmi||"-"}}kg/m2</p>
                        </div>
                      </td>
                      <td v-if="isParent.rowReg.ap_usia <= 15">
                        <div class="result_tab">
                          <h4>Luas Permukaan Tubuh Anak</h4>
                          <p>{{rowData.arankpkrd_ttv_luas_permukaan_anak||"-"}}m<sup>2</sup></p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Ukuran Pupil</h4>
                          <p>{{rowData.arankpkrd_ttv_ukuran_pupil||"-"}} mm/Ki</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row mt-2">
        <div class="col-md-4">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Hasil Observasi</h6>
            </div>
            <div class="card-body p-3">
              <ul v-if="(rowData.arankpkrd_hasil_observasi||[]).length">                            
                <li v-for="(v,k) in (rowData.arankpkrd_hasil_observasi||[])" :key="k">{{v||"-"}}</li>
              </ul>
              <span v-if="!(rowData.arankpkrd_hasil_observasi||[]).length">Tidak ada Hasil</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Pertimbangan Klinis</h6>
            </div>
            <div class="card-body p-3">
              <ul v-if="(rowData.arankpkrd_pertimbangan_klinis||[]).length">                            
                <li v-for="(v,k) in (rowData.arankpkrd_pertimbangan_klinis||[])" :key="k">{{v||"-"}}</li>
              </ul>
              <span v-if="!(rowData.arankpkrd_pertimbangan_klinis||[]).length">Tidak ada Hasil</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Penilaian dan Order Dokter</h6>
            </div>
            <div class="card-body p-3">
              <ul v-if="(rowData.arankpkrd_order_dokter||[]).length">                            
                <li v-for="(v,k) in (rowData.arankpkrd_order_dokter||[])" :key="k">{{v||"-"}}</li>
              </ul>
              <span v-if="!(rowData.arankpkrd_order_dokter||[]).length">Tidak ada Hasil</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Kesadaran GCS</h6>
        </div>
        <div class="card-body p-3">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td style="width:33%;">
                  <div class="result_tab">
                    <h4>Respons Mata</h4>
                    <p>{{getConfigDynamic(Config.mr.ksResponseMata,rowData.arankpkrd_response_mata)||"-"}}<span
                        class="font-weight-semibold text-info">({{rowData.arankpkrd_response_mata||"-"}})</span></p>
                  </div>
                </td>
                <td style="width:33%;">
                  <div class="result_tab">
                    <h4>Respons Verbal</h4>
                    <p>{{getConfigDynamic(Config.mr.ksResponseVerbal,rowData.arankpkrd_response_verbal)||"-"}} <span
                        class="font-weight-semibold text-info">({{rowData.arankpkrd_response_verbal||"-"}})</span></p>
                  </div>
                </td>
                <td style="width:33%;">
                  <div class="result_tab">
                    <h4>Respons Motorik</h4>
                    <p>{{getConfigDynamic(Config.mr.ksResponseMotorik,rowData.arankpkrd_response_motorik)||"-"}} <span
                        class="font-weight-semibold text-info">({{rowData.arankpkrd_response_motorik||"-"}})</span></p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Nilai GCS</h4>
                    <template v-if="rowData.arankpkrd_response_gcs">
                      <div class="">
                      <span class="font-weight-semibold">Nilai GCS: {{rowData.arankpkrd_response_gcs}}</span>
                      <span v-if="rowData.arankpkrd_response_gcs >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                      <span v-else-if="rowData.arankpkrd_response_gcs >= 12 && rowData.arankpkrd_response_gcs <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                      <span v-else-if="rowData.arankpkrd_response_gcs >= 10 && rowData.arankpkrd_response_gcs <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                      <span v-else-if="rowData.arankpkrd_response_gcs >= 7 && rowData.arankpkrd_response_gcs <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                      <span v-else-if="rowData.arankpkrd_response_gcs >= 5 && rowData.arankpkrd_response_gcs <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                      <span v-else-if="rowData.arankpkrd_response_gcs >= 4 && rowData.arankpkrd_response_gcs <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                      <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                      </div>
                    </template>
                    <span v-else> - </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    
    <div class="mt-2">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <div class="col-md-12">
            
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tindak Lanjut</h4>
                          <p>{{rowData.arankpkr_is_next_monitoring == 'Y'?'Monitoring Dilanjutkan':'Monitoring Dihentikan'}}</p>
                        </div>
                      </td>

                      <td>
                        <div class="result_tab">
                          <h4>Pemeriksaan Selanjutnya</h4>
                          <p v-if="rowData.arankpkr_is_next_monitoring == 'Y'">
                          {{rowData.arankpkrd_waktu_result | moment("DD MMMM YYYY, HH:mm")}}</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  data() {
    return {
      rowData: {},
      rowReg: {},
      resepObat: [],
    }
  },
  methods: {
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    apiGetForm(params = {}, page = 1){
      if(!this.regId) if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = (this.isParent.regId ? "RanapPasienKajianRestrain" : this.modulePage)+'/'+(this.$parent.regId||this.$route.params.pageSlug)+'/'+this.isParent.rowEdit.arankpkr_id
      
      console.log("/get/"+url)
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })          
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })
    },
  },
  mounted() {
    this.apiGetForm()
  }
}
</script>