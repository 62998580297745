<template>
  <div>
    <view-data v-if="parentData.viewType === 'VIEW' || !parentData.viewType" />

    <form-data v-else-if="parentData.viewType === 'FORM_PEWS'" />    
  </div>
</template>

<script>
import ViewData from './ViewData.vue'
import FormData from './FormData.vue'
import Gen from '@/libs/Gen'

export default {
  components: {
    ViewData, FormData
  },

  props: {
    regId: Number,
  },

  data(){
    return {
      tekananDarah: {
        data: {}
      },
      balanceCairan: {},
      pews: {
        xAxis: []
      },
      hermodinamy: {
        data: {},
      },

      tHistoryData:{
        data:[],
        page:{
          total_data:0,
          per_page: 10,
          current_page:1
        }
      },

      pageNoTData: 1,


      mrValidation: {},
      formData: {},
    }
  },

  mounted() {
    if(this.parentData.viewType === 'VIEW') {
      this.getGraphicData()
    } else if(this.parentData.viewType === 'FORM_PEWS') {
      this.getHistoryData(this.pageNoTData)
    }
  },

  methods: {
    getGraphicData(page=1){
      Gen.apiRest(
        "/get/"+'RanapPasienMonitoringPEWS',
        {
          params: {
            page,
            type: 'get-graphic-data',
            id_registrasi: (this.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res=>{
        const { 
          balanceCairan, hermodinamy,
          pews, tekananDarah
        }  = res.data.data

        this.$set(this, 'balanceCairan', balanceCairan)
        this.$set(this, 'hermodinamy', hermodinamy)
        this.$set(this, 'pews', pews)
        this.$set(this, 'tekananDarah', tekananDarah)
      })
    },

    getHistoryData(page=1){
      Gen.apiRest(
        "/get/"+'RanapPasienMonitoringPEWS',
        {
          params: {
            page,
            type: 'get-form',
            id_registrasi: (this.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res=>{
        const { 
          historyData, mrValidation, formData
        }  = res.data

        this.$set(this, 'tHistoryData', historyData)
        this.$set(this, 'mrValidation', mrValidation)
        this.$set(this, 'formData', formData)
      })
    },

    doSubmitPEWS(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/"+'RanapPasienMonitoringPEWS',
        { 
          data: {
            ...data,
            id_registrasi: (this.regId||this.$route.params.pageSlug)
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$set(this.$emit('changeViewType', 'VIEW'))
          }
        })
      }).catch(()=>{
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },

    changePage(page){
      this.$set(this, 'pageNoTData', page)
    }
  },

  computed: {
    parentData(){
      if(!this.$parent.moduleRole){
        return this.$parent.$parent.$parent
      } else {
        return this.$parent
      }
    },

    moduleRole(){
      return this.parentData.moduleRole
    }
  },

  watch: {
    'parentData.viewType'(v){
      Gen.scrollToTop()

      if(v === 'VIEW') {
        this.getGraphicData()
      } else if(v === 'FORM_PEWS') {
        this.getHistoryData(1)
      }
    },
    'pageNoTData'(v){
      this.getHistoryData(v)
    }
  }
}
</script>