<template>
<div>
  <div class="row p-3" v-if="loading.coreData">
    <div class="col-md-12">
      <div class="d-flex justify-content-center align-items-center text-primary">
        <b-spinner class="mr-2 mb-0" small></b-spinner>
        <h6 class="font-weight-semibold mb-0">Loading...</h6>
      </div>
    </div>
  </div>

  <template v-else>
    <table class="table table-bordered table-sm patient-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Tanggal/Jam</th>
          <th>Diagnosa</th>
          <th>Perawat</th>
          <th>Status</th>
          <th style="width:15%">Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(v,k) in (dataKajian||[])" :key="k" :class="v.arankpks_is_parent == 'Y' ? 'table-info' : ''">
          <td>
            <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{k+1}}</span>
          </td>
          <td>{{v.arankpks_date | moment('DD MMMM YYYY')}}, {{v.arankpks_jam}}
            <span v-if="v.arankpks_is_parent == 'Y'" class="badge bg-success ml-2">Assesmen Awal </span>
            <span v-if="v.arankpks_is_parent != 'Y'" class="badge bg-success ml-2">Monitoring </span>

            <p class="mt-1" style="font-size: 11px;" v-if="v.arankpks_updated_date"> <b>Terakhir Diupdate pada : </b> <br />{{v.arankpks_updated_date | moment('DD MMMM YYYY HH:mm:ss')}} </p>
          </td>
          <td>{{v.arankpks_diagnosa||"-"}}</td>
          <td>{{v.name_petugas||"-"}}</td>
          <td>
            <span v-if="v.arankpks_is_draft == 'Y'" class="badge bg-warning">Draft </span>
            <template v-else>
              <span v-if="v.arankpks_status == 'DONE'" class="badge bg-success">Selesai Monitoring</span>
              <span v-else class="badge bg-info">Dalam Monitoring </span>
            </template>
          </td>
          <td>
            <a href="javascript:;" @click="toModal(v,'xl',(v.arankpks_is_parent=='Y'?'Detail Kajian Fibrinolisis':'Detail Kajian Fibrinolisis Monitoring'),(v.arankpks_is_parent=='Y'?'DetailKajian':'DetailKajianMonitoring'))" v-if="v.arankpks_is_draft != 'Y'" class="btn btn-icon ml-1 mb-1 alpha-blue border-blue" v-b-tooltip.hover title="Lihat Kajian Fibrinolisis"><i class="icon-file-eye"></i></a>
          </td>
        </tr>
        <tr v-if="!(dataKajian||[]).length">
          <td colspan="99" class="text-center">Data Tidak ada</td>
        </tr>
      </tbody>
    </table>

    <b-modal v-model="openModal" :size="modalConfig.size" no-close-on-backdrop hide-footer :title="modalConfig.title">
      <DetailKajian v-if="isModal == 'DetailKajian'" />
      <DetailKajianMonitoring v-if="isModal == 'DetailKajianMonitoring'" />
    </b-modal>
  </template>
</div>
</template>

<script>
import Gen from '@/libs/Gen.js'
import DetailKajianMonitoring from '../../../RanapPasienKajianStriptokinase/DetailKajianMonitoring.vue'
import DetailKajian from '../../../RanapPasienKajianStriptokinase/DetailKajian.vue'

const _ = global._

export default {
  name: "Fibrinolisis",
  components: {
    DetailKajianMonitoring,
    DetailKajian
  },

  props: {
    regId: Number,
  },

  data() {
    return {
      dataKajian: [],
      rowEdit: {},
      rowReg: {},
      rowData: {},
      row: {},

      isModal: '',
      modalConfig: {
        title: '',
        size: 'sm'
      },
      openModal: false,

      loading: {
        coreData: true,
      }
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/" + `RanapPasienKajianStriptokinase/${this.regId}`, {}
      ).then(res => {
        this.$set(this.loading, 'coreData', false)
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      }).catch(() => {
        this.$set(this.loading, 'coreData', false)
      })
    },

    toModal(row, size, title, isModal) {
      this.rowEdit = row
      this.modalConfig = {
        title: title,
        size: size
      }
      this.isModal = isModal
      this.openModal = true
    },
  }

}
</script>
