<template>
  <div class="card">
    <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
      <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" data-toggle="tab" data-target="#pasienTabInfo"
          :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Informasi Pasien</a></li>

      <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" data-toggle="tab" data-target="#pasienTabPJ"
          :class="activeTab == 4 ? 'nav-link active' : 'nav-link'">Informasi Penanggung Jawab</a></li>
      <li class="nav-item"><a href="javascript:;" @click="changeTab(5)" data-toggle="tab" data-target="#pasienTabKW"
          :class="activeTab == 5 ? 'nav-link active' : 'nav-link'">Informasi Kewenangan</a></li>
     
      <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" data-toggle="tab" data-target="#pasienTabRM"
          :class="activeTab == 2 ? 'nav-link active' : 'nav-link'">Riwayat Rekam Medis</a></li>

      <li class="nav-item" v-if="$parent.user.levelId != $parent.uAdmisi"><a href="javascript:;" @click="changeTab(3)" data-toggle="tab" data-target="#pasienTabRM"
          :class="activeTab == 3 ? 'nav-link active' : 'nav-link'">Arsip Rekam Medis</a></li>
    </ul>
    <div class="tab-content">
      <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabInfo">
        <div class="card-header row">
          <div class="col-md-8"> 
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Informasi Pasien</h6>
            </div>
          </div>
          <div class="col-md-4 text-right">
            <b-button type="button" @click="sinkronData" variant="primary" class="btn-rounded">Sinkronkan Data</b-button>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-bordered table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{row.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama</h4>
                    <p>{{row.ap_fullname||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>NIK</h4>
                    <p>{{row.ap_nik}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir</h4>
                    <p>{{row.ap_pob||"-"}}, {{row.ap_dob_true | moment("DD MMM YYYY")}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{row.ap_usia_with_ket||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Kategori Usia</h4>
                    <p>{{row.ap_gol_usia||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{row.cg_label||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Agama</h4>
                    <p v-if="row.ma_id !== 99999">{{row.ma_name||"-"}}</p>
                    <p v-else>{{row.ap_agama_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Suku</h4>
                    <p>{{row.mrsuk_name||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pekerjaan</h4>
                    <p v-if="row.mp_id !== 99999">{{row.mp_name||"-"}}</p>
                    <p v-else>{{row.ap_pekerjaan_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Pendidikan</h4>
                    <p>{{row.mpen_name||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Nama Ibu Kandung</h4>
                    <p>{{row.ap_nama_ibu||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>No. Telpon</h4>
                    <p>+62{{row.ap_phone_number||"-"}}</p>
                  </div>
                </td>
                <td colspan="2">
                  <div class="result_tab">
                    <h4>Email</h4>
                    <p v-if="row.ap_email">{{row.ap_email.trim()||"-"}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Status Pernikahan</h4>
                    <p v-if="row.ap_status_pernikahan == 1">Belum Kawin</p>
                    <p v-else-if="row.ap_status_pernikahan == 2">Kawin</p>
                    <p v-else-if="row.ap_status_pernikahan == 3">Cerai Hidup</p>
                    <p v-else-if="row.ap_status_pernikahan == 4">Cerai Mati</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Bahasa yang Dikuasai</h4>
                    <p>{{row.ap_language||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Golongan Darah</h4>
                    <p v-if="row.ap_gol_darah">{{row.ap_gol_darah.trim()||"-"}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="result_tab">
                    <h4>Alamat Domisili</h4>
                    <p>{{domisiliAddr||"-"}}</p>
                  </div>
                </td>
                <td :rowspan="2">
                  <div class="result_tab">
                    <h4>QR Code Pasien</h4>
                    <img v-if="row.ap_qr_no_rm" :src="$parent.uploader(row.ap_qr_no_rm)" style="width: 80%;height: 80%"/>
                    <template v-else>
                      <p>Tidak Terdapat QR Code</p>
                      <b-button v-if="row.ap_code" style="z-index: 99" @click="generateQr" class="mt-2 btn-rounded" variant="primary">Generate QR</b-button>
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Alamat Sesuai KTP</h4>
                    <p>{{ktpAddr||"-"}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabRM">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <h5 class="card-title font-weight-semibold mr-auto">Rekam Medis No. <span
                class="text-blue-600">{{row.ap_code||"-"}}</span></h5>
                
            <a href="javascript:;" @click="$parent.apiGet()"
              class="btn bg-primary btn-labeled btn-labeled-left mr-2">
              <b><i class="icon-spinner11"></i></b>
              Muat Ulang
            </a>
            <a href="javascript:;" v-if="(dataList||[]).length" @click="downloadPRMRJ(row)"
              class="btn bg-green-600 btn-labeled btn-labeled-left">
              <b><i class="icon-download"></i></b>
              Unduh PRMRJ
            </a>
          </div>
        </div>


        <div>
          <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
            :paginate-elements-by-height="1100" :filename="'Resume Medis'" :pdf-quality="2" :manual-pagination="true"
            pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf">
            <div
              style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;"
              slot="pdf-content"
              >

              <table v-if="resumeMedisType == 'RJ' && Object.keys(dataRiwayatRMDokter||{}).length" border="1" style="border-collapse: collapse;width: 100%;">
                <tr>
                  <td style="padding:0;">
                    <table border="1" style="border-collapse: collapse;width: 100%;">
                      <tr>
                        <td width="50%" style="padding:8px 12px;">
                          <div
                            style="float: left;width: 15%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                            <img :src="$parent.assetLocal('global_assets/images/logo-rsh-mark.png')"
                              style="width: 100%;" alt="">
                          </div>
                          <div
                            style="text-transform: uppercase;float: left;width: 85%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                            <h1 style="margin:0;font-size: 18px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit
                              Harapan Magelang</h1>
                            <p style="margin:0;font-size: 13px;">Jl.P Senopati No 11, Magelang 56123</p>
                          </div>
                          <div style="clear: both;"></div>
                        </td>
                        <td width="50%" style="padding:8px 12px;">
                          <div>
                            <table style="border-collapse: collapse;width: 100%;">
                              <tr>
                                <th width="108px" style="text-align:left;">No. RM</th>
                                <td width="12px">:</td>
                                <td>{{row.ap_code||"-"}}</td>
                              </tr>
                              <tr>
                                <th width="108px" style="text-align:left;">Nama Pasien</th>
                                <td width="12px">:</td>
                                <td>{{row.ap_fullname||"-"}} </td>
                                <td>
                                  <div style="display: inline-block;">
                                    <div
                                      style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 13px;width: 20px;height: 20px;box-sizing: border-box;line-height: 16px;vertical-align: middle;">
                                      <span style="line-height: 16px;">
                                        {{row.ap_gender == 1 ? '&check;' : ''}}
                                      </span>
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;">
                                      L
                                    </div>
                                  </div>
                                  <div style="display: inline-block; margin-left: 12px;">
                                    <div
                                      style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 13px;width: 20px;height: 20px;box-sizing: border-box;line-height: 16px;vertical-align: middle;">
                                      <span style="line-height: 16px;">
                                        {{row.ap_gender == 2 ? '&check;' : ''}}
                                      </span>
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;">
                                      P
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th width="108px" style="text-align:left;">Tgl. Lahir</th>
                                <td width="12px">:</td>
                                <td>{{ row.ap_dob | moment("DD MMM YYYY")}}</td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <h2 style="margin:0;text-align: center;text-transform: uppercase;font-size: 18px;">Resume
                            Medis Rawat Jalan</h2>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="padding:0;">
                          <table style="width:100%;">
                            <tr>
                              <td width="50%" style="padding:2px 12px;">
                                <strong>Tgl. Periksa: </strong>
                                {{dataRiwayatRMDokter.row.arm_created_date | moment("dddd, DD MMMM YYYY")}}
                              </td>
                              <td width="50%" style="padding:2px 12px;">
                                <strong>Nama Dokter: </strong>
                                {{dataRiwayatRMDokter.rowReg.bu_full_name||"-"}}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th style="text-align: left;padding: 2px 12px;">Ringkasan Riwayat Penyakit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Keluhan Utama:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokter.row.apsd_keluhan||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Riwayat Penyakit:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokter.row.apsd_anamnesa||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Pemeriksaan Fisik:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokter.row.apod_desc||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Tanda-tanda Vital:</strong></div>
                            <table style="width:100%;border-collapse: collapse;" border="1">
                              <tr>
                                <td style="padding:4px;width:25%;">Tekanan Darah:
                                  {{dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_min}} /
                                  {{dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_max}}</td>
                                <td style="padding:4px;width:25%;">Nadi:
                                  {{dataRiwayatRMDokter.row.apod_ttv_nadi||"-"}} x / menit
                                  {{dataRiwayatRMDokter.row.apod_ttv_label == 'I' ? 'Irreguler' : 'Reguler'}}</td>
                                <td style="padding:4px;width:25%;">Gula Darah:
                                  {{dataRiwayatRMDokter.row.apod_ttv_gula_darah||"-"}} mg/dL</td>
                                <td style="padding:4px;width:25%;">Pernafasan:
                                  {{dataRiwayatRMDokter.row.apod_ttv_pernafasan||"-"}} x/menit</td>
                              </tr>
                              <tr>
                                <td style="padding:4px;width:25%;">SPO2:
                                  {{dataRiwayatRMDokter.row.apod_ttv_spo2||"-"}}%</td>
                                <td style="padding:4px;width:25%;">Suhu:
                                  {{dataRiwayatRMDokter.row.apod_ttv_suhu||"-"}}C</td>
                                <td style="padding:4px;width:25%;">Berat Badan:
                                  {{dataRiwayatRMDokter.row.apod_ttv_weight||"-"}}kg</td>
                                <td style="padding:4px;width:25%;">Tinggi Badan:
                                  {{dataRiwayatRMDokter.row.apod_ttv_height||"-"}}cm</td>
                              </tr>
                              <tr>
                                <td style="padding:4px;width:25%;">Lingkar Kepala:
                                  {{dataRiwayatRMDokter.row.apod_ttv_lingkar_kepala||"-"}}cm</td>
                                <td style="padding:4px;width:25%;">BMI:
                                  {{dataRiwayatRMDokter.row.apod_ttv_bmi||"-"}}m2</td>
                                <td style="padding:4px;width:25%;">Kesadaran:
                                  {{getConfigDynamic(mKesadaran,dataRiwayatRMDokter.row.apod_ttv_kesadaran)||"-"}}</td>
                                <td style="padding:4px;width:25%;" v-if="dataRiwayatRMDokter.row.ap_usia <= 15">Luas
                                  Permukaan Anak:
                                  {{dataRiwayatRMDokter.row.apod_ttv_luas_permukaan_anak||"-"}}kg/m2
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Hasil Pemeriksaan Unit Penunjang
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Laboratorium</th>
                          <th width="50%" style="padding: 2px 12px;">Radiologi</th>
                        </tr>
                        <tr>
                          <td style="padding:2px 12px;">
                            <ul style="margin:0;padding-left: 16px;"
                              v-if="(dataRiwayatRMDokter.row.appdl_hasil||[]).length">
                              <li v-for="(v,k) in (dataRiwayatRMDokter.row.appdl_hasil||[])" :key="k">
                                {{v.value||"-"}}
                              </li>
                            </ul>
                            <span v-else> - </span>
                          </td>
                          <td style="padding:2px 12px;">
                            <ul style="margin:0;padding-left: 16px;"
                              v-if="(dataRiwayatRMDokter.row.appdr_hasil||[]).length">
                              <li v-for="(v,k) in (dataRiwayatRMDokter.row.appdr_hasil||[])" :key="k">
                                {{v.value||"-"}}
                              </li>
                            </ul>
                            <span v-else> - </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Diagnosa Dokter</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Diagnosa</th>
                          <th width="50%" style="padding: 2px 12px;">ICD10</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokter.dataICD10||[])" :key="k">
                          <td style="padding:2px 12px;">
                            <span>{{v.api10_diagnosis||"-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Tindakan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Tindakan</th>
                          <th width="50%" style="padding: 2px 12px;">ICD9</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokter.dataICD9||[])" :key="k">
                          <td style="padding:2px 12px;">
                            <span>{{v.api9_tindakan||"-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="99" style="text-align: left;padding: 2px 12px;">Riwayat Obat yang Diberikan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th style="padding: 2px 12px;">Obat</th>
                          <th style="padding: 2px 12px;" width="36">Dosis</th>
                          <th style="padding: 2px 12px;">Frekuensi</th>
                          <th style="padding: 2px 12px;" width="36">Rute</th>
                          <th style="padding: 2px 12px;">Instruksi</th>
                          <th style="padding: 2px 12px;" width="36">Jumlah</th>
                          <th style="padding: 2px 12px;" width="36">Satuan</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokter.resepDokter||[])" :key="k" style="page-break-inside: avoid;">
                          <td style="padding:2px 12px;">{{v.ard_nama||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.ard_dosis||"-"}} {{v.ard_satuan_dosis||"-"}}</td>
                          <td style="padding:2px 12px;">
                            <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                            <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span v-if="v.ard_jenis == 1">{{v.mob_rute_pemberian||"-"}}</span>
                            <span v-else> - </span>
                          </td>
                          <td style="padding:2px 12px;">{{v.ard_keterangan||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.ard_jumlah||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.ard_satuan||"-"}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr style="page-break-inside: avoid;">
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Tindak Lanjut</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tindak Lanjut</h4>
                              <p>{{dataRiwayatRMDokter.row.atld_is_edukasi == "Y"?'Edukasi':''}}</p>
                              <p>{{dataRiwayatRMDokter.row.atld_is_kontrol == "Y"?'Kontrol':''}}</p>
                              <p>{{dataRiwayatRMDokter.row.atld_is_rawat_inap == "Y"?'Rawat Inap':''}}</p>
                              <p>{{dataRiwayatRMDokter.row.atld_is_done == "Y"?'Selesai':''}}</p>
                            </div>
                          </td>
                          <div class="result_tab">
                            <h4>Cara Pulang Pasien</h4>
                            <p>{{getConfigDynamic(Config.mr.mCaraPulang,dataRiwayatRMDokter.row.atld_cara_pulang)||"-"}}</p>
                          </div>
                          <td>
                            <div class="result_tab">
                              <template v-if="dataRiwayatRMDokter.row.atld_is_edukasi == 'Y'">
                              <h4>Catatan Edukasi</h4>
                              <p>{{dataRiwayatRMDokter.row.atld_notes_edukasi||"-"}}</p>
                              </template>

                              <template v-if="dataRiwayatRMDokter.row.atld_is_kontrol == 'Y'">
                              <h4>Tanggal Pemeriksaan</h4>
                              <p v-if="dataRiwayatRMDokter.row.atld_notes_kontrol">{{dataRiwayatRMDokter.row.atld_notes_kontrol|moment("DD MMM YYYY")}}</p>
                              <span v-else> - </span>
                              
                              <h4 class="mt-2">Catatan Kontrol</h4>
                              <p>{{dataRiwayatRMDokter.row.atld_catatan_kontrol||"-"}}</p>
                              </template>
                              
                              <template v-if="dataRiwayatRMDokter.row.atld_is_rawat_inap == 'Y'">
                              <h4>Catatan Rawat Inap</h4>
                              <p>{{dataRiwayatRMDokter.row.atld_notes_rawat_inap||"-"}}</p>
                              <h4>Ruangan</h4>
                              <p>{{dataRiwayatRMDokter.row.mruan_name||"-"}}</p>
                              <h4>Dokter</h4>
                              <p>{{dataRiwayatRMDokter.row.mruan_dokter_name||"-"}}</p>
                              <h4>Tanggal Rawat Inap</h4>
                              <p v-if="dataRiwayatRMDokter.row.atld_tanggal_ranap">{{dataRiwayatRMDokter.row.atld_tanggal_ranap | moment("DD MMMM YYYY")}}</p>
                              <p v-else> - </p>
                              </template>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>

              <table v-if="resumeMedisType == 'UGD'">
                 <tr>
                  <td style="padding:0;">
                    <table border="1" style="border-collapse: collapse;width: 100%;">
                      <tr>
                        <td width="50%" style="padding:8px 12px;">
                          <div
                            style="float: left;width: 15%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                            <img :src="$parent.assetLocal('global_assets/images/logo-rsh-mark.png')"
                              style="width: 100%;" alt="">
                          </div>
                          <div
                            style="text-transform: uppercase;float: left;width: 85%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                            <h1 style="margin:0;font-size: 18px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit
                              Harapan Magelang</h1>
                            <p style="margin:0;font-size: 13px;">Jl.P Senopati No 11, Magelang 56123</p>
                          </div>
                          <div style="clear: both;"></div>
                        </td>
                        <td width="50%" style="padding:8px 12px;">
                          <div>
                            <table style="border-collapse: collapse;width: 100%;">
                              <tr>
                                <th width="108px" style="text-align:left;">No. RM</th>
                                <td width="12px">:</td>
                                <td>{{row.ap_code||"-"}}</td>
                              </tr>
                              <tr>
                                <th width="108px" style="text-align:left;">Nama Pasien</th>
                                <td width="12px">:</td>
                                <td>{{row.ap_fullname||"-"}} </td>
                                <td>
                                  <div style="display: inline-block;">
                                    <div
                                      style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 13px;width: 20px;height: 20px;box-sizing: border-box;line-height: 16px;vertical-align: middle;">
                                      <span style="line-height: 16px;">
                                        {{row.ap_gender == 1 ? '&check;' : ''}}
                                      </span>
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;">
                                      L
                                    </div>
                                  </div>
                                  <div style="display: inline-block; margin-left: 12px;">
                                    <div
                                      style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 13px;width: 20px;height: 20px;box-sizing: border-box;line-height: 16px;vertical-align: middle;">
                                      <span style="line-height: 16px;">
                                        {{row.ap_gender == 2 ? '&check;' : ''}}
                                      </span>
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;">
                                      P
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th width="108px" style="text-align:left;">Tgl. Lahir</th>
                                <td width="12px">:</td>
                                <td>{{ row.ap_dob | moment("DD MMM YYYY")}}</td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <h2 style="margin:0;text-align: center;text-transform: uppercase;font-size: 18px;">Resume
                            Medis UGD</h2>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="padding:0;">
                          <table style="width:100%;">
                            <tr>
                              <td width="50%" style="padding:2px 12px;">
                                <strong>Tgl. Periksa: </strong>
                                {{dataRiwayatRMDokterUGD.row.aurm_created_date | moment("dddd, DD MMMM YYYY")}}
                              </td>
                              <td width="50%" style="padding:2px 12px;">
                                <strong>Nama Dokter: </strong>
                                {{dataRiwayatRMDokterUGD.rowReg.bu_full_name||"-"}}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th style="text-align: left;padding: 2px 12px;">Ringkasan Riwayat Penyakit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Keluhan Utama:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokterUGD.row.ausd_keluhan||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Riwayat Penyakit:</strong></div>
                            <p style="margin: 0">{{dataRiwayatRMDokterUGD.row.ausd_riwayat||"-"}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 2px 12px 8px;">
                            <div style="margin-bottom: 4px;"><strong>Tanda-tanda Vital:</strong></div>
                            <table style="width:100%;border-collapse: collapse;" border="1">
                              <tr>
                                <td style="padding:4px;width:25%;">Tekanan Darah:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_tekanan_darah_min}} /
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_tekanan_darah_max}}</td>
                                <td style="padding:4px;width:25%;">Nadi:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_nadi||"-"}} x / menit
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_label == 'I' ? 'Irreguler' : 'Reguler'}}</td>
                                <td style="padding:4px;width:25%;">Gula Darah:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_gula_darah||"-"}} mg/dL</td>
                                <td style="padding:4px;width:25%;">Pernafasan:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_pernafasan||"-"}} x/menit</td>
                              </tr>
                              <tr>
                                <td style="padding:4px;width:25%;">SPO2:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_spo2||"-"}}%</td>
                                <td style="padding:4px;width:25%;">Suhu:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_suhu||"-"}}C</td>
                                <td style="padding:4px;width:25%;">Berat Badan:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_weight||"-"}}kg</td>
                                <td style="padding:4px;width:25%;">Tinggi Badan:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_height||"-"}}cm</td>
                              </tr>
                              <tr>
                                <td style="padding:4px;width:25%;">Lingkar Kepala:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_lingkar_kepala||"-"}}cm</td>
                                <td style="padding:4px;width:25%;">BMI:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_bmi||"-"}}m2</td>
                                <td style="padding:4px;width:25%;">Kesadaran:
                                  {{getConfigDynamic(mKesadaran,dataRiwayatRMDokterUGD.row.auod_ttv_kesadaran)||"-"}}</td>
                                <td style="padding:4px;width:25%;" v-if="dataRiwayatRMDokterUGD.row.ap_usia <= 15">Luas
                                  Permukaan Anak:
                                  {{dataRiwayatRMDokterUGD.row.auod_ttv_luas_permukaan_anak||"-"}}kg/m2
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Hasil Pemeriksaan Unit Penunjang
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Laboratorium</th>
                          <th width="50%" style="padding: 2px 12px;">Radiologi</th>
                        </tr>
                        <tr>
                          <td style="padding:2px 12px;">
                            <ul style="margin:0;padding-left: 16px;"
                              v-if="(dataRiwayatRMDokterUGD.row.appdl_hasil||[]).length">
                              <li v-for="(v,k) in (dataRiwayatRMDokterUGD.row.appdl_hasil||[])" :key="k">
                                {{v.value||"-"}}
                              </li>
                            </ul>
                            <span v-else> - </span>
                          </td>
                          <td style="padding:2px 12px;">
                            <ul style="margin:0;padding-left: 16px;"
                              v-if="(dataRiwayatRMDokterUGD.row.appdr_hasil||[]).length">
                              <li v-for="(v,k) in (dataRiwayatRMDokterUGD.row.appdr_hasil||[])" :key="k">
                                {{v.value||"-"}}
                              </li>
                            </ul>
                            <span v-else> - </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Diagnosa Dokter</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Diagnosa</th>
                          <th width="50%" style="padding: 2px 12px;">ICD10</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokterUGD.dataICD10||[])" :key="k">
                          <td style="padding:2px 12px;">
                            <span>{{v.auicd10d_diagnosa||"-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="2" style="text-align: left;padding: 2px 12px;">Tindakan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th width="50%" style="padding: 2px 12px;">Tindakan</th>
                          <th width="50%" style="padding: 2px 12px;">ICD9</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokterUGD.dataICD9||[])" :key="k">
                          <td style="padding:2px 12px;">
                            <span>{{v.auicd9d_tindakan||"-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0;">
                    <table style="border-collapse: collapse;width: 100%;" border="1">
                      <thead>
                        <tr style="background-color: #d3d3d3;">
                          <th colspan="99" style="text-align: left;padding: 2px 12px;">Riwayat Obat yang Diberikan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th style="padding: 2px 12px;">Obat</th>
                          <th style="padding: 2px 12px;" width="36">Dosis</th>
                          <th style="padding: 2px 12px;">Frekuensi</th>
                          <th style="padding: 2px 12px;" width="36">Rute</th>
                          <th style="padding: 2px 12px;">Instruksi</th>
                          <th style="padding: 2px 12px;" width="36">Jumlah</th>
                          <th style="padding: 2px 12px;" width="36">Satuan</th>
                        </tr>
                        <tr v-for="(v,k) in (dataRiwayatRMDokterUGD.resepDokter||[])" :key="k" style="page-break-inside: avoid;">
                          <td style="padding:2px 12px;">{{v.aurd_nama||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.aurd_dosis||"-"}} {{v.aurd_satuan_dosis||"-"}}</td>
                          <td style="padding:2px 12px;">
                            <span v-if="v.aurd_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                            <span v-else>{{v.aurd_frekuensi_lainnya || "-"}}</span>
                          </td>
                          <td style="padding:2px 12px;">
                            <span v-if="v.aurd_jenis == 1">{{v.mob_rute_pemberian||"-"}}</span>
                            <span v-else> - </span>
                          </td>
                          <td style="padding:2px 12px;">{{v.aurd_keterangan||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.aurd_jumlah||"-"}}</td>
                          <td style="padding:2px 12px;">{{v.aurd_satuan||"-"}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </vue-html2pdf>
        </div>
        <!-- DOKUMEN END -->
        
        <ul class="nav nav-tabs nav-justified nav-tabs-highlight mb-0">
            <li class="nav-item">
                <a href="javascript:;" @click="changeTabRiwayat(1)" data-toggle="tab" :class="activeTabList == 1 ? 'nav-link active' : 'nav-link'">Rawat Jalan</a>
            </li>
            <li class="nav-item">
                <a href="javascript:;" @click="changeTabRiwayat(2)" data-toggle="tab" :class="activeTabList == 2 ? 'nav-link active' : 'nav-link'">UGD</a>
            </li>
            <li class="nav-item">
                <a href="javascript:;" @click="changeTabRiwayat(3)" data-toggle="tab" :class="activeTabList == 3 ? 'nav-link active' : 'nav-link'">Rawat Inap</a>
            </li>
        </ul>
        <div class="tab-content">
          <div id="tabRmUgd" :class="activeTabList ==  1 ? 'tab-pane fade active show':'tab-pane fade'">    
            <table class="table table-bordered table-striped table-hover table-sm patient-table">
              <thead>
                <tr>
                  <th>No. Reg</th>
                  <th>Tanggal Reg</th>
                  <th>Nama Dokter</th>
                  <th>Keluhan</th>
                  <th>Kajian</th>
                  <th>Cara Bayar</th>
                  <th v-if="$parent.user.levelId != $parent.uAdmisi">Riwayat RM</th>
                  <th v-if="$parent.user.levelId != $parent.uAdmisi">Riwayat Insiden</th>
                  <th v-if="$parent.user.levelId == $parent.uAdmisi">Diagnosa Primer</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody v-if="(dataList||[]).length">
                <template v-for="(v,k) in (dataList||[])">
                  <tr :key="k">
                    <td class="font-weight-semibold">
                      {{v.ar_reg_code||"-"}}
                      <p class="badge bg-warning mt-1" v-if="v.ar_esep_status == 'M'">Menunggu Download E-SEP</p>
                    </td>
                    <td>
                      <span v-if="v.ar_reg_date">{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                      <span v-else>{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                    </td>
                    <td>
                      <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                    </td>

                    <td>
                      <span>{{v.keluhan||"-"}}</span>
                    </td>
                    
                    <td>
                      <a href="javascript:;" v-if="v.kajianPerawatId" @click="toDetail(v)" class="btn btn-sm btn-icon"
                        :class="v.arm_is_kajian_awal == 'Y' ? 'alpha-blue border-blue' : 'alpha-green border-green'"
                        v-b-tooltip.hover="'Lihat Kajian '+(v.arm_is_kajian_awal == 'Y' ? 'Awal' : 'Lanjutan')"><i
                          class="icon-file-eye"></i></a>
                      <span v-else>-</span>
                    </td>
                    
                    <td>
                      <span>{{v.mcp_name||"-"}}</span>
                    </td>


                    <td v-if="$parent.user.levelId != $parent.uAdmisi"><a href="javascript:;"
                      @click="doOpenRiwayatRM(v)" v-b-tooltip.hover="'Lihat Riwayat Rekam Medis'"
                        class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
                    </td>

                    <td v-if="$parent.user.levelId != $parent.uAdmisi">
                      <a href="javascript:;" @click="doOpenTindakan(v)" data-toggle="modal" data-target="#modalInsiden"
                        class="btn alpha-pink btn-sm border-pink btn-icon" data-popup="tooltip" v-b-tooltip.hover
                        title="Lihat Riwayat Insiden"><i class="icon-file-eye"></i></a>
                    </td>
                    
                    <td v-if="$parent.user.levelId == $parent.uAdmisi">
                      <span>{{v.diag_primer||"-"}}</span>
                    </td>
                    
                    <td>
                      
                      <a href="javascript:;" v-if="v.kajianDokter" @click="downloadRM(v)"
                        class="btn alpha-success rounded-round btn-sm border-success text-success btn-icon"
                        data-popup="tooltip" v-b-tooltip.hover title="Unduh Resume Medis"><i class="icon-download"></i></a>
                      
                      <a href="javascript:;" v-if="v.ar_mcp_id == 138 || v.ar_mcp_id == 137" @click="doCetakSEP(v,k)"
                        class="btn alpha-success rounded-round btn-sm border-success text-success btn-icon"
                        data-popup="tooltip" v-b-tooltip.hover title="Unduh E-SEP"><i class="icon-download"></i></a>
                      
                      <a href="javascript:;" v-if="(v.ar_mcp_id == 138 || v.ar_mcp_id == 137) && v.ar_esep_status == 'D'" @click="doCetakSEP(v,k,1)"
                        class="btn alpha-warning rounded-round btn-sm border-warning text-warning btn-icon"
                        data-popup="tooltip" v-b-tooltip.hover title="Generate Ulang E-SEP"><i class="icon-download"></i></a>
                        

                      <span v-if="(!v.ar_mcp_id == 138 || !v.ar_mcp_id == 137) && !v.kajianDokter"> - </span>

                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else-if="!(dataList||[]).length && dataList">
                <tr>
                  <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                    Data Tidak Ditemukan
                  </th>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                    <div class="skeletal-comp mb-2"></div>
                    <div class="skeletal-comp mb-2"></div>
                    <div class="skeletal-comp"></div>
                  </th>
                </tr>
              </tbody>
              <tfoot v-if="(dataList||[]).length && pageNow < $parent.data.last_page">
                <tr>
                  <td class="text-center" colspan="99">
                    <a href="javascript:;" @click="loadMore"
                      class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div id="tabRmUgdData" :class="activeTabList ==  2 ? 'tab-pane fade active show':'tab-pane fade'">
            <table class="table table-bordered table-striped table-hover table-sm patient-table">
              <thead>
                  <tr>
                      <th>No. Reg</th>
                      <th>Tanggal Reg</th>
                      <th>Nama Dokter</th>
                      <th>Keluhan</th>
                      <th>Kajian</th>
                      <th>Riwayat RM</th>
                      <th>Catatan Asuhan</th>
                      <th v-if="$parent.uRadiologi == $parent.user.levelId">Dokumen</th>
                      <th>Aksi</th>
                  </tr>
              </thead>
              <template v-if="(dataUGDList||[]).length">
                  <template v-for="(v,k) in (dataUGDList||[])" >
                      <tbody :class="v.isHead? 'isHead': ''" :key="k">
                          <tr :class="v.aurm_is_draft == 'Y' ? 'table-warning' : ''">
                              <td>
                                  <a v-b-tooltip.hover v-if="!(v.aurm_is_draft == 'Y' && v.aur_is_menolak_radiologi == 'Y' && $parent.user.levelId == $parent.uRadiologi)" title="Edit Data" href="javascript:;" @click="redirectUGD(v)" class="text-primary font-weight-semibold border-bottom">{{v.aur_reg_code||"-"}}</a>
                                  
                                  <a v-b-tooltip.hover v-else href="javascript:;" class="text-primary font-weight-semibold border-bottom">{{v.aur_reg_code||"-"}}</a>

                                  
                              
                                  <template v-if="v.aurm_is_draft == 'Y'">
                                  
                                      <span v-if="v.aurm_is_draft == 'Y' && v.aur_is_menolak_radiologi == 'Y' && $parent.user.levelId == $parent.uRadiologi" :class="`badge bg-warning ml-1`">
                                      Pasien Menolak Tindakan
                                      </span>
                                      <span v-else :class="`badge bg-warning ml-1`">
                                      Draft
                                      </span>
                                      <br/>
                                      <small>{{v.petugas||"-"}}</small>
                                  </template>

                                  <p class="badge bg-warning mt-1" v-if="v.aur_esep_status == 'M'">Menunggu Download E-SEP</p>                      
                              </td>
                              <td>
                                  <span v-if="v.aur_reg_date">{{ v.aur_reg_date | moment("DD MMM YYYY")}}</span>
                                  <span v-else>{{ v.aur_reg_date | moment("DD MMM YYYY")}}</span>
                              </td>
                              <td>
                                  <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                              </td>

                              <td>
                                  <span>{{v.keluhan||"-"}}</span>
                              </td>
                              
                              
                              <td>
                              
                              <a href="javascript:;" v-if="v.aur_is_secondary_assesment_done == 'Y'" @click="toDetailKajianPerawatUGD(v,v.aurm_ar_id)" class="btn btn-sm btn-icon" :class="'alpha-blue border-blue'" v-b-tooltip.hover="'Lihat Kajian Perawat'"><i class="icon-file-eye"></i></a>
                              <span v-else>-</span>
                              
                              </td>
                              
                              <td>
                              <a href="javascript:;" v-if="v.aur_is_primary_assesment_done == 'Y'" @click="doOpenRiwayatRMUGD(v)" v-b-tooltip.hover="'Lihat Riwayat Rekam Medis'" class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
                              <span v-else>-</span>
                              
                              </td>

                              <td>
                                  <template v-if="v.aurm_is_draft != 'Y'">
                                      <a href="javascript:;" v-if="$parent.uPerawatUGD == $parent.user.levelId" @click="toCatatanAsuhan(v.aur_id)" class="btn btn-icon bg-teal mr-2" v-b-tooltip.hover :title="v.aur_have_catatan_asuhan !='Y' ? 'Tambah Catatan Asuhan' : 'Edit Catatan Asuhan'">
                                          <i class="icon-file-plus"></i>
                                      </a>
                                      
                                      <a href="javascript:;" @click="toCatatanAsuhanDetail(v.aur_id)" class="btn btn-icon alpha-teal border-teal text-teal-800" data-popup="tooltip" title="" data-toggle="modal" data-original-title="Lihat Catatan Asuhan">
                                      <i class="icon-file-eye"></i>
                                      </a>
                                  </template>
                                  <span v-else> - </span>
                              </td>

                              <td v-if="$parent.uRadiologi == $parent.user.levelId">
                                  <a href="javascript:;" @click="lihatDokumen(v)" v-b-tooltip.hover="'Lihat Dokumen'" class="btn btn-sm btn-icon alpha-danger border-danger"><i class="icon-file-eye"></i></a>
                              </td>
                              
                              <td>
                              
                                <a href="javascript:;" v-if="v.kajianDokter" @click="downloadRMUGD(v)"
                                  class="btn alpha-success rounded-round btn-sm border-success text-success btn-icon"
                                  data-popup="tooltip" v-b-tooltip.hover title="Unduh Resume Medis"><i class="icon-download"></i></a>

                                 
                                <a href="javascript:;" v-if="v.aur_mcp_id == 138 || v.aur_mcp_id == 137" @click="doCetakSEPUGD(v,k)"
                                class="btn alpha-success rounded-round btn-sm border-success text-success btn-icon"
                                data-popup="tooltip" v-b-tooltip.hover title="Unduh E-SEP"><i class="icon-download"></i></a>

                                 <a href="javascript:;" v-if="(v.aur_mcp_id == 138 || v.aur_mcp_id == 137) && v.aur_esep_status == 'D'" @click="doCetakSEPUGD(v,k,1)"
                                class="btn alpha-warning rounded-round btn-sm border-warning text-warning btn-icon"
                                data-popup="tooltip" v-b-tooltip.hover title="Generate Ulang E-SEP"><i class="icon-download"></i></a>
                                
                                
                                <router-link v-if="v.aur_status > 3" :to="{name: 'UGDTindakLanjut', params: {pageSlug: v.aurm_aur_id}, query: {isDetailKeluarUGD : true}}" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success" title="Lihat Dokumen Tindak Lanjut"><i class="icon-eye"></i></router-link>
                              </td>
                          </tr>
                      </tbody>
                  </template>
              </template>
              <tbody v-else-if="!(dataUGDList||[]).length && dataUGDList">
                  <tr>
                      <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                          Data Tidak Ditemukan
                      </th>
                  </tr>      
              </tbody>
              <tbody v-else>
                  <tr>
                      <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                          <div class="skeletal-comp mb-2"></div>
                          <div class="skeletal-comp mb-2"></div>
                          <div class="skeletal-comp"></div>
                      </th>
                  </tr>
              </tbody>
              
              <tfoot v-if="(dataUGDList||[]).length && pageNow < $parent.dataUGD.last_page">
                  <tr>
                      <td class="text-center" colspan="99">
                          <a href="javascript:;" @click="loadMoreUGD" class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
                      </td>
                  </tr>
              </tfoot>
            </table>
          </div>

          <div id="tabRmRanapData" :class="activeTabList ==  3 ? 'tab-pane fade active show':'tab-pane fade'">
            <div class="table-responsive">
              <table class="table table-bordered table-striped table-hover patient-table">
                <thead>
                  <tr>
                    <th>No. Reg</th>
                    <th>Tanggal Reg</th>
                    <th>Nama Dokter</th>
                    <th>Diagnosa</th>
                    <th>Cara Bayar</th>
                    <th>Kajian Awal</th>
                    <th>Riwayat RM</th>
                    <th>Dokumen Persetujuan Ranap</th>
                    <th>Edukasi Terintegrasi</th>
                    <th>Dicharge Planning</th>
                    <th>Hais</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <template v-if="(dataRanapList||[]).length">
                  <template v-for="(v,k) in (dataRanapList||[])" >
                    <tbody :class="v.isHead? 'isHead': ''" :key="k">
                      <tr>
                        <td>{{ v.aranr_reg_code }}</td>
                        <td>{{ v.aranr_reg_date | moment("LL") }}</td>
                        <td>
                          <span>
                            <strong>{{ v.bu_full_name }}</strong>
                          </span>                          
                        </td>
                        <td>{{ v.diagnosa_primer || '-' }}</td>
                        <td>{{ v.mcp_name }}</td>
                        <td>
                          <a href="javascript:;" @click="openKajianRanap(v)" class="btn btn-icon alpha-info border-info text-info-800" v-b-tooltip.hover title="Lihat Kajian">
                            <i class="icon-file-eye"></i>
                          </a>
                        </td>
                        <td>
                          <a href="javascript:;" @click="openRiwayatRMRanap(v)" class="btn btn-icon alpha-teal border-teal text-teal-800" v-b-tooltip.hover title="Lihat Riwayat">
                            <i class="icon-file-eye"></i>
                          </a>
                        </td>
                        <td>
                          <a href="javascript:;" @click="openDokumenPersetujuan(v)" class="btn btn-icon alpha-info border-info text-info-800" v-b-tooltip.hover title="Lihat Dokumen">
                            <i class="icon-file-eye"></i>
                          </a>
                        </td>
                        <td>
                          <a href="javascript:;" @click="openEdukasiTerintegrasi(v)" class="btn btn-icon alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Lihat Edukasi Terintegrasi">
                            <i class="icon-file-eye"></i>
                          </a>
                        </td>
                        <td>
                          <a href="javascript:;" @click="openDischargePlanning(v)" class="btn btn-icon alpha-orange border-orange text-orange-800" v-b-tooltip.hover title="Lihat Discharge Planning">
                            <i class="icon-file-eye"></i>
                          </a>
                        </td>
                        <td>
                          <a href="javascript:;" @click="openHais(v)" class="btn btn-icon alpha-info border-info text-info-800" v-b-tooltip.hover title="Lihat Hais">
                            <i class="icon-file-eye"></i>
                          </a>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </template>
                </template>
                <tbody v-else-if="!(dataRanapList||[]).length">
                  <tr>
                    <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                      Data Tidak Ditemukan
                    </th>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                      <div class="skeletal-comp mb-2"></div>
                      <div class="skeletal-comp mb-2"></div>
                      <div class="skeletal-comp"></div>
                    </th>
                  </tr>
                </tbody>                
              </table>
            </div>
          </div>
        </div>

      </div>
      <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabArchive">
        <b-card-header>
          <div class="d-flex justify-content-between">
            <h6 class="card-title font-weight-semibold mb-0">Arsip Dokumen Rekam Medis</h6>
            <b-button variant="transparent" @click="openAdd=true;addNew()" class="btn-labeled btn-labeled-left bg-success">
              <b><i class="icon-file-text2"></i></b>
              <span>Unggah Arsip Baru</span>
            </b-button>
          </div>
        </b-card-header>
        <table class="table table-bordered table-striped table-hover table-sm patient-table">
          <thead>
            <tr>
              <th>No. Reg</th>
              <th>Tanggal Reg</th>
              <th>Nama Dokter</th>
              <th>Keluhan</th>
              <th>Rekam Medis</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in (arsip||[])" :key="k">
              <td><span>{{v.aadr_reg_code||"-"}}</span></td>
              <td><span>{{v.aadr_reg_date |  moment("DD MMM YYYY")}}</span></td>
              <td><strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name||"-"}}</small></strong></td>
              <td><span>{{v.aadr_keluhan||"-"}}</span></td>
              <td>
                <b-button
                  @click="doOpenDetail(v)"
                  variant="transparent"
                  v-b-tooltip.hover="'Lihat Arsip Rekam Medis'"
                  class="btn-icon btn-sm alpha-orange border-orange"
                >
                  <i class="icon-file-eye"></i>
                </b-button>
              </td>
              <td>
                <b-button @click="deleteData(v.aadr_id)" variant="transparent" v-b-tooltip.hover="'Hapus Data'" class="btn-icon btn-sm alpha-danger border-danger">
                  <i class="icon-trash text-danger-800"></i>
                </b-button>
              </td>
            </tr>
            <tr v-if="!(arsip||[]).length">
              <td colspan="99" class="text-center">Tidak Ada Data Arsip</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabPJ">
        <div class="card-body">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Hubungan Keluarga</h4>
                    <p>{{row.arpj_hubungan_keluarga_text||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Penanggung Jawab</h4>
                    <p>{{row.arpj_fullname||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Pekerjaan </h4>
                    <p v-if="row.arpj_pekerjaan !== 99999">{{row.pekerjaan_pj||"-"}}</p>
                    <p v-else>{{row.arpj_pekerjaan_text||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{row.gender_pj||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Agama </h4>
                    <p v-if="row.arpj_agama !== 99999">{{row.agama_pj||"-"}}</p>
                    <p v-else>{{row.arpj_agama_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>No Handphone</h4>
                    <p>+62{{row.arpj_phone_number}}</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Alamat Domisili</h4>
                    <p>{{pjAddr||"-"}}</p>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div :class="activeTab == 5 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabKW">
        <div class="card-body">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Hubungan Keluarga</h4>
                    <p>{{row.arki_hubungan_keluarga_text||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Penanggung Jawab</h4>
                    <p>{{row.arki_fullname||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Pekerjaan </h4>
                    <p v-if="row.arki_pekerjaan !== 99999">{{row.pekerjaan_ki||"-"}}</p>
                    <p v-else>{{row.arki_pekerjaan_text||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{row.gender_ki||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Agama </h4>
                    <p v-if="row.arki_agama !== 99999">{{row.agama_ki||"-"}}</p>
                    <p v-else>{{row.arki_agama_text||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>No Handphone</h4>
                    <p>+62{{row.arki_phone_number}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
      <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
      <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
    </b-modal>

    <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
      <RiwayatRM v-bind="passToSubRM" />
    </b-modal>

    <b-modal v-model="openTindakan" :title="'Riwayat Insiden'" size="xl" hide-footer>
      <RiwayatInsiden v-bind="passToSubRiwayatInsiden" />
    </b-modal>

    <b-modal id="modalMedicalRecord" v-model="openDetail" title="Detail Arsip Rekam Medis" size="lg" ok-only ok-title="Tutup">
      <table class="table table-bordered">
        <tr>
          <td width="33%">
            <div class="result_tab">
              <h4>No. Reg</h4>
              <p>{{rowDetail.aadr_reg_code||"-"}}</p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Tgl. Reg</h4>
              <p>{{rowDetail.aadr_reg_date | moment("DD MMM YYYY")}}</p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Nama Dokter</h4>
              <p>
                <span class="font-weight-semibold">{{rowDetail.bu_full_name||"-"}}</span><br />
                <span>{{rowDetail.mpo_name||"-"}}</span>
              </p>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="result_tab">
              <h4>Keluhan</h4>
              <p>{{rowDetail.aadr_keluhan||"-"}}</p>
            </div>
          </td>
        </tr>
      </table>
      <h6 class="mt-4 mb-2">Dokumen Rekam Medis</h6>
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr>
            <th>No.</th>
            <th>Dokumen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v,k) in (rowDetail.aadr_dokumen||[])" :key="k">
            <td>{{k+1}}</td>
            <td>
              <span v-if="v">
                <b-button
                  variant="transparent"
                  class="btn-icon bg-blue"
                  v-b-tooltip.hover="'Lihat Dokumen Rekam Medis'"
                  :href="$parent.uploader(v)"
                >
                  <i class="icon-file-text2 text-white"></i>
                </b-button>
              </span>
              <span v-else> - </span>
            </td>
          </tr>
          <tr v-if="!(rowDetail.aadr_dokumen||[]).length">
            <td colspan="99" class="text-center">Tidak Ada Data</td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <validation-observer ref="VFormArsip">
    <b-form>
      <b-modal id="formMedicalRecord" v-model="openAdd" title="Tambah Arsip Rekam Medis" size="lg" @ok.prevent="submitArsip">
        <b-row>
          <b-col md="4">
            <b-form-group label="No. Registrasi" label-for="noReg">
              <b-form-input v-model="addData.aadr_reg_code" id="noReg" placeholder="e.g. RG00809613" />
              <VValidate 
                  name="No. Registrasi" 
                  v-model="addData.aadr_reg_code" 
                  :rules="{required : 1}"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Tgl. Registrasi" label-for="tglRegistrasi">
              <datepicker
                v-model="addData.aadr_reg_date"
                input-class="form-control"
                placeholder="Tanggal Registrasi" class="my-datepicker"
                id="tglRegistrasi"
                calendar-class="my-datepicker_calendar">
              </datepicker>
              <VValidate 
                  name="Tgl. Registrasi" 
                  v-model="addData.aadr_reg_date" 
                  :rules="{required : 1}"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Nama Dokter" label-for="namaDokter">
              <v-select
                placeholder="Pilih Dokter"
                :options="mDokterData"
                v-model="addData.aadr_dokter_id"
                class="med-selection"
                label="text"
                :clearable="true" :reduce="v=>v.value"
              >
                <template #option="{ text, poli }">
                  <span class="font-weight-semibold">{{ text }}</span> - {{ poli }}
                </template>
              </v-select>
              
              <VValidate 
                name="Nama Dokter" 
                v-model="addData.aadr_dokter_id" 
                :rules="{required : 1}"
              />

            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group label="Keluhan" label-for="keluhan">
              <b-form-textarea v-model="addData.aadr_keluhan" id="keluhan" placeholder="e.g. nyeri di paha kanan, ngilu di bagian lutut" rows="4" />
              
              <VValidate 
                name="Keluhan" 
                v-model="addData.aadr_keluhan" 
                :rules="{required : 1}"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h6 class="my-2 font-weight-semibold">Dokumen Rekam Medis</h6>
        <table class="table table-sm table-bordered table-striped">
          <thead>
            <tr>
              <th>No.</th>
              <th>Dokumen</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in (addData.aadr_dokumen||[])" :key="k">
              <td>{{k+1}}</td>
              <td>
                <Uploader v-model="addData.aadr_dokumen[k]" isDocument type="docimage" />
                <VValidate 
                  :name="'Dokumen '+(k+1)" 
                  v-model="addData.aadr_dokter_id" 
                  :rules="{required : 1}"
                />
              </td>
              <td>
                <b-button variant="transparent" @click="(addData.aadr_dokumen||[]).splice(k,1)" v-b-tooltip.hover="'Hapus Dokumen'" class="btn-icon btn-sm alpha-danger border-danger">
                  <i class="icon-trash text-danger-800"></i>
                </b-button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-center" colspan="4">
                <b-button @click="(addData.aadr_dokumen||[]).push(null)" variant="info" class="btn-labeled btn-labeled-left">
                  <b><i class="icon-plus22"></i></b>
                  Tambah Dokumen
                </b-button>
              </td>
            </tr>
          </tfoot>
        </table>
      </b-modal>
    </b-form>
    </validation-observer>

    <b-modal v-model="openCatatanAsuhan" title="Catatan Asuhan" size="xl" ok-only ok-title="Tutup">
        <div class="table-responsive">
            <table class="table table-bordered table-sm table-align-top">
                <thead>
                <tr>
                    <td colspan="6" class="bg-info">
                    <span class="font-weight-semibold">Catatan Asuhan Perawat</span>
                    </td>
                </tr>
                <tr>
                    <th>Waktu</th>
                    <th>Asesmen/Tindakan</th>
                    <th>Waktu Evaluasi</th>
                    <th>Evaluasi</th>
                    <th>PPA</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                
                <template v-if="Object.keys(dataCatatanAsuhan||{}).length ">
                <template v-if="dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp == 'Y'">
                <tr class="dpjpEl">
                    <td>
                        <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                        {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_waktu||"-"}}</span>
                    </td>
                    
                    <td>
                        <div class="tb-content">
                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                        <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama {{dataCatatanAsuhan.rowDataDokter.ausd_keluhan||"-"}} <span v-if="dataCatatanAsuhan.rowDataDokter.ausd_riwayat">, Pasien mempunyai riwayat penyakit dahulu: {{dataCatatanAsuhan.rowDataDokter.ausd_riwayat||"-"}} </span></p>
                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                            <ul class="mb-0" v-if="dataCatatanAsuhan.rowDataDokter.ausd_has_alergi == 'Y'">
                                <li v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.ausd_alergi||[])" :key="k"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}</li>
                            </ul>
                            <span v-else> - </span>
                        </p>
                        <!--
                        <div class="mb-2">
                            <span class="font-weight-semibold">Keluhan Saat ini</span>
                            <div>{{dataCatatanAsuhan.rowDataDokter.ausd_catatan_dokter||"-"}}</div>
                        </div>
                        -->
                        <div class="mb-2">
                            <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                            <div>{{dataCatatanAsuhan.rowDataDokter.aupd_rencana_pengobatan||"-"}}</div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Riwayat Pembedahan</span>
                            <div>
                            <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Invasif</span>
                            <template v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[]).length">
                            <ol class="mb-0"
                            v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else>Tidak ada Tindakan</p>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">A: </span>Keluhan saat ini
                            <div><span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_keluhan||"-"}}</span></div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                            <div>{{dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value}} 
                                <template v-if="(dataCatatanAsuhan.row.ap_usia > 6 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NP'))">
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value == 0">Tidak Ada Nyeri</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 1 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 3">Sedikit Nyeri</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 4 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 5">Agak Mengganggu</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 6 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 7">Mengganggu Aktivitas</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 8 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 9">Sangat Mengganggu</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 10">Tak Tertahankan</span>
                                </template>
                                
                                <template v-if="(dataCatatanAsuhan.row.ap_usia <= 6) || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null)">
                                    <template v-if="(dataCatatanAsuhan.row.ap_usia > 1 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'FL'))">
                                        <span v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                        <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 1 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                        <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 4 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    </template>

                                    <template v-if="((dataCatatanAsuhan.row.ap_usia <= 1  && dataCatatanAsuhan.row.ap_usia != null)|| (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                        <span v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                        <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 3 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tanda-tanda Vital</span>
                            <ul class="mb-0">
                            
                            <li>Tekanan Darah: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_tekanan_darah_min||"-"}}/{{dataCatatanAsuhan.rowDataDokter.auod_ttv_tekanan_darah_max||"-"}} mmHG</li>
                            <li>Nadi: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_nadi||"-"}} x/mnt - {{dataCatatanAsuhan.rowDataDokter.auod_ttv_label||"-"}}</li>
                            <li>Gula Darah: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_gula_darah||"-"}} mg/dL</li>
                            <li>Pernafasan: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_pernafasan||"-"}} x/mnt</li>
                            <li>SPO2: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_spo2||"-"}}%</li>
                            <li>Suhu: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_suhu||"-"}}C</li>
                            <li>Berat Badan {{dataCatatanAsuhan.rowDataDokter.auod_ttv_weight||"-"}} kg</li>
                            <li>Tinggi: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_height||"-"}} cm</li>
                            <li>Lingkar Kepala: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_lingkar_kepala||"-"}} cm</li>
                            <li>Luas Permukaan Tubuh Anak: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                            <li>Kesadaran:  <span>{{dataCatatanAsuhan.rowDataDokter.auod_gcs_total}} | 
                        
                                <span v-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 14"> Composmentis</span> 
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 12 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 13">Apatis</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 10 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 11">Delirium</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 7 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 9">Somonolen</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 5 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 6">Sopor</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 4 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>

                                <span class="ml-2" style="margin-right:20px;">E: 
                                    {{dataCatatanAsuhan.rowDataDokter.auod_response_mata||0}} 
                                </span>
                                <span style="margin-right:20px;">M: 
                                    {{dataCatatanAsuhan.rowDataDokter.auod_response_motorik||0}}                             
                                </span>
                                <span style="margin-right:20px;">V: 
                                    {{dataCatatanAsuhan.rowDataDokter.auod_response_verbal||0}}
                                </span>
                            </span></li>

                            </ul>
                        </div>
                        
                        <div class="mb-2">
                            <span class="font-weight-semibold">Pemeriksaan Fisik</span>
                            <ul class="mb-0">
                                <template v-for="(v,k) in (Config.mr.configVitalSignUGD||[])">
                                    <div :key="k" v-if="v.value != 'auod_vs_saraf'">
                                        <li v-if="dataCatatanAsuhan.rowDataDokter[v.value] != 'TP'"> 
                                            {{v.label}} : <span>{{dataCatatanAsuhan.rowDataDokter[v.value+'_text']}}</span>
                                        </li>
                                    </div>
                                    <div :key="k" v-else>
                                        <template v-if="dataCatatanAsuhan.rowDataDokter[v.value] != 'TP'">
                                            <li>
                                                <span> {{v.label}} :</span>
                                            </li>
                                            <p class="mb-0">Refleks Fisiologis : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                            <p class="mb-0">Refleks Patologis : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_r_patologis||"-"}}</p>
                                            <p class="mb-0">Babinski : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_babinski||"-"}}</p>
                                            <p class="mb-0">Lasegue : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_lasegue||"-"}}</p>
                                            <p class="mb-0">Bregard : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_bregard||"-"}}</p>
                                            <p class="mb-0">Sicard : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_sicard||"-"}}</p>
                                            <p class="mb-0">Kaku Duduk : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                            <p class="mb-0">Kernig Sign : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_kernig_sign||"-"}}</p>
                                            <p class="mb-0">Brudzinski 1: {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_brudzinski||"-"}}</p>
                                            <p class="mb-0">Brudzinski 2: {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_brudzinski2||"-"}}</p>
                                        </template>
                                    </div>
                                </template>

                            </ul>
                            <span v-if="!isHavePemeriksaanFisik">Hasil pemeriksaan fisik normal</span>
                        </div>

                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Lab</span>
                            <p>{{selectedLabInput||"-"}}</p>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Radiologi</span>
                            <p>{{selectedRadioInput||"-"}}</p>                            
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">R</span>
                            <div class="mb-1">
                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                            <template v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <span v-else>Tidak ada Tindakan</span>
                            

                            </div>
                            <div class="mb-1">
                            <span>Usulan kolaborasi</span>
                            <template v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <span v-else>Tidak ada Tindakan</span>

                            </div>
                        </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tanggal_evaluasi}} {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_waktu_evaluasi}}</span>
                    </td>
                    
                    <td>
                        <div class="form-group" 
                    v-for="(vU,kU) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                            <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                            <pre class="pre-input mb-1">{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['value']||"-"}}</pre>
                            <span v-if="dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">
                            Selesai pada {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                        </div>
                        <div id="verifikasiRanap">
                            <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                            
                            <div class="form-group">
                            <label for="">Nama DPJP</label>
                                <p>{{dataCatatanAsuhan.rowDataDokter.nama_dokter_ranap||"-"}}
                                </p>
                            </div>

                            <div class="form-group">
                            <label>Tanggal</label>
                                <p>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                            </div>
                            
                            <div class="form-group">
                            <label>Jam</label>
                                <p>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                            </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_ppa||"-"}}</span>
                    </td>
                </tr>
                <tr class="dpjpEl" v-for="(v,k) in (dataCatatanAsuhan.catatanDPJPDokter||[])" :key="k">
                    <td>
                        <span>{{v.auldd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                        {{v.auldd_laporan_dpjp_waktu||"-"}}</span>
                    </td>
                    
                    <td>
                        <div class="tb-content">
                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                        <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama {{v.auldd_subjektif||"-"}} <span v-if="v.auldd_riwayat_penyakit">, Pasien mempunyai riwayat penyakit dahulu: {{v.auldd_riwayat_penyakit||"-"}} </span></p>
                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                            <ul class="mb-0">
                                <li v-for="(v1,k1) in (v.auldd_riwayat_alergi||[])" :key="k1"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v1.jenis)}})</span>{{v1.name||"-"}}</li>
                            </ul>
                            <span v-if="!(v.auldd_riwayat_alergi||[]).length"> - </span>
                        </p>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Keluhan Saat Ini</span>
                            <div>{{v.auldd_subjektif||"-"}}</div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                            <div>{{v.auldd_catatan_dokter||"-"}}</div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Riwayat Pembedahan</span>
                            <div>
                            <span>{{v.auldd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Invasif</span>
                            <template v-if="(v.auldd_laporan_dpjp_tindakan_invasif||[]).length">
                            <ol class="mb-0"
                            v-for="(v,k) in (v.auldd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else>Tidak ada Tindakan</p>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">A: </span>Keluhan saat ini
                            <div><span>{{v.auldd_laporan_dpjp_keluhan||"-"}}</span></div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                            <div>{{v.auldd_hasil_skoring_nyeri}}
                              <template v-if="(dataCatatanAsuhan.row.ap_usia > 6 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NP'))">
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="v.auldd_hasil_skoring_nyeri == 0">Tidak Ada Nyeri</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri <= 3">Sedikit Nyeri</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri <= 5">Agak Mengganggu</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 6 && v.auldd_hasil_skoring_nyeri <= 7">Mengganggu Aktivitas</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 8 && v.auldd_hasil_skoring_nyeri <= 9">Sangat Mengganggu</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 10">Tak Tertahankan</span>
                              </template>
                              
                              <template v-if="(dataCatatanAsuhan.row.ap_usia <= 6) || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null)">
                                  <template v-if="(dataCatatanAsuhan.row.ap_usia > 1 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'FL'))">
                                      <span v-if="v.auldd_hasil_skoring_nyeri == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                      <span v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                      <span v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                      <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                  </template>

                                  <template v-if="((dataCatatanAsuhan.row.ap_usia <= 1  && dataCatatanAsuhan.row.ap_usia != null)|| (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                      <span v-if="v.auldd_hasil_skoring_nyeri < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                      <span v-else-if="v.auldd_hasil_skoring_nyeri >= 3 && v.auldd_hasil_skoring_nyeri < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                      <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                  </template>
                              </template>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tanda-tanda Vital</span>
                            <ul class="mb-0">
                            
                            <li>Tekanan Darah: {{v.auldd_ttv_tekanan_darah_min||"-"}}/{{v.auldd_ttv_tekanan_darah_max||"-"}} mmHG</li>
                            <li>Nadi: {{v.auldd_ttv_nadi||"-"}} x/mnt - {{v.auldd_ttv_label||"-"}}</li>
                            <li>Gula Darah: {{v.auldd_ttv_gula_darah||"-"}} mg/dL</li>
                            <li>Pernafasan: {{v.auldd_ttv_pernafasan||"-"}} x/mnt</li>
                            <li>SPO2: {{v.auldd_ttv_spo2||"-"}}%</li>
                            <li>Suhu: {{v.auldd_ttv_suhu||"-"}}C</li>
                            <li>Berat Badan {{v.auldd_ttv_weight||"-"}} kg</li>
                            <li>Tinggi: {{v.auldd_ttv_height||"-"}} cm</li>
                            <li>Lingkar Kepala: {{v.auldd_ttv_lingkar_kepala||"-"}} cm</li>
                            <li>Luas Permukaan Tubuh Anak: {{v.auldd_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                            <li>Kesadaran: <span>{{v.auldd_hasil_kesadaran}} | 
                        
                                <span v-if="v.auldd_hasil_kesadaran >= 14"> Composmentis</span> 
                                <span v-else-if="v.auldd_hasil_kesadaran >= 12 && v.auldd_hasil_kesadaran <= 13">Apatis</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 10 && v.auldd_hasil_kesadaran <= 11">Delirium</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 7 && v.auldd_hasil_kesadaran <= 9">Somonolen</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 5 && v.auldd_hasil_kesadaran <= 6">Sopor</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 4 && v.auldd_hasil_kesadaran <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>
                                       
                                <span class="ml-2" style="margin-right:20px;">E: 
                                    {{v.auldd_hasil_kesadaran_e||0}} 
                                </span>
                                <span style="margin-right:20px;">M: 
                                    {{v.auldd_hasil_kesadaran_m||0}}  
                                </span>
                                <span style="margin-right:20px;">V: 
                                    {{v.auldd_hasil_kesadaran_v||0}}
                                </span>
                            </span>
                            </li>

                            </ul>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Lab</span>
                            <template v-if="(v.auldd_hasil_lab||[]).length">
                            <ol class="mb-0" v-for="(v3,k3) in (v.auldd_hasil_lab||[])" :key="k3">
                                <li>{{v3.value}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                            
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Radiologi</span>
                            <template v-if="(v.auldd_hasil_radiologi||[]).length">
                            <ol class="mb-0" v-for="(v4,k4) in (v.auldd_hasil_radiologi||[])" :key="k4">
                                <li>{{v4.value}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                            
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">R</span>
                            <div class="mb-1">
                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                            <template v-if="(v.auldd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                            
                            

                            </div>
                            <div class="mb-1">
                            <span>Usulan kolaborasi</span>
                            <template v-if="(v.auldd_laporan_dpjp_usulan_kolaborasi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                            

                            </div>
                        </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{v.auldd_laporan_dpjp_tanggal_evaluasi}} {{v.auldd_laporan_dpjp_waktu_evaluasi}}</span>
                    </td>
                    
                    <td>
                        <div class="form-group" 
                        v-for="(vU,kU) in (v.auldd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                            <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                            <pre class="pre-input mb-1">{{ v.auldd_laporan_dpjp_evaluasi_data[kU]['value']||'-' }}</pre>
                                
                            <span v-if="v.auldd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">Selesai pada {{v.auldd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                        </div>
                        <div id="verifikasiRanap">
                            <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                            
                            <div class="form-group">
                            <label for="">Nama DPJP</label>
                                <p>{{v.nama_dokter_ranap||"-"}}
                                </p>
                            </div>

                            <div class="form-group">
                            <label>Tanggal</label>
                                <p>{{v.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                            </div>
                            
                            <div class="form-group">
                            <label>Jam</label>
                                <p>{{v.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                            </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{v.auldd_laporan_dpjp_ppa||"-"}}</span>
                    </td>
                </tr>
                </template>
                <tr class="table-info">
                    <td colspan="6"><span class="font-weight-semibold text-info-600">Catatan Asuhan Lainnya</span></td>
                </tr>
                </template>
                                
                <tr v-for="(v,k) in (dataCatatanAsuhan.data||[])" :key="k">
                    <td>
                    <span>{{v.aucap_date | moment("DD MMMM YYYY") }},{{v.aucap_jam||"-"}}</span>
                    </td>
                    <td>

                    <template v-if="v.aucap_type == 'L'">
                        <span>{{v.aucap_assesmen_tindakan||"-"}}</span>
                        
                        <template v-if="v['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                            <div class="form-inline">
                            <label>Injeksi pada</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                            </div>
                        </template>

                        <template v-if="v['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                            <div class="form-inline">
                            <label>Incici pada</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                            </div>
                        </template>

                        <template v-if="v['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                            <label>Warna gelang</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                        </template>
                        
                        
                        <template v-if="v['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                            <label>Nama Obat</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>                            
                        </template>
                        

                        
                        <template v-if="v['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                            <div class="mb-2">
                            <label>Nama Obat</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>                            
                            </div>
                            
                            <div class="mb-2">
                            <label>Dosis</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text2||"-"}}</span>                            
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div>
                            <span>{{v.aucap_assesmen_tindakan_text||"-"}}</span>
                        </div>
                    </template>

                    </td>
                    <td>
                    <span>{{v.aucap_evaluasi_date | moment("DD MMMM YYYY") }},{{v.aucap_evaluasi_jam||"-"}}</span>
                    </td>
                    <td>
                        <template v-if="v.aucap_type == 'L'">
                            
                        <div v-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan oksigen'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Oksigen terpasang</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>L/menit, SpO2</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan Perekaman EKG'">
                            <div class="mb-2 evalContent">
                                <span>Hasil Terlampir</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang bedside monitor'">
                            <div class="mb-2 evalContent">
                                <span>Monitor terpasang</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemeriksaan cek gula darah acak'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Hasil GDA</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan infus sesuai dengan advis dokter'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                    <label>Infus terpasang cairan</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                        
                                    <label>dengan tetesan</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                    <label>/menit tusukan pada</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                                    <label>dengan abocath</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t4||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Injeksi masuk lewat</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                        
                                <label>tanda-tanda alergi</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                        
                                <label>keluhan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>                                        
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan kateter tetap'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Kateter terpasang nomor</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>dengan balon WFI</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                <label>urin keluar</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>                                       
                                <label>CC setelah pemasangan</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan suction'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Lendir terhisap, suara nafas</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan penjahitan luka'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Luka terjahit</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>. Simpul dengan jenis dan ukuran benang</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                </div>
                            </div>
                            
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Kondisi luka setelah dilakukan perawatan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan ekstraksi kuku'">
                            <div class="mb-2 evalContent">
                                <span>Kuku bisa terangkat, luka bersih</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>sudah terambil perawatan luka telah dilakukan, luka bekas incici bersih</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Terpasang gelang identitas warna </label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>Pasien maupun keluarga paham penjelasan petugas</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang penanda alergi'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Terpasang gelang alergi warna merah dengan tulisan alergen </label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang bidai/spalk'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Terpasang bidai pada</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>Hasil pengecekan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                <label>tanda tanda kompartemen</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pengambilan benda asing/corpal'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Benda asing pada</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>pada area</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan irigasi mata'">
                            <div class="mb-2 evalContent">
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan lavement'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Lavement masuk</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>CC dengan cairan yang digunakan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                <label>hasil evaluasi feses</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pelepasan kateter tetap'">
                            <div class="mb-2 evalContent">
                                <span>Kateter berhasil dilakukan pelepasan dengan bleder training sebelumnya</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Nebulizer telah dilakukan, sesak nafas</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>SpO2</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                <label>sputum</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang NGT'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>NGT terpasang dengan ukuran</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>pada hidung sebelah</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                <label>hasil evaluasi terdengar bunyi blup pada area lambung</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka combutio'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Luka tampak</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>Bula</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                <label>perawatan menggunakan topical terapi</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                            <div class="mb-2 evalContent">
                                <span>Obat masuk melalui syring pump, lancar</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan CTG'">
                            <div class="mb-2 evalContent">
                                <span>Hasil terlampir</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memfasilitasi pasien untuk pemeriksaan radiologi'">
                            <div class="mb-2 evalContent">
                                <span>Hasil terlampir</span>
                            </div>
                        </div>                        
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Free Text'">
                            <span>{{dataCatatanAsuhan.data[k]['aucap_evaluasi_text']||"-"}}</span>
                        </div>

                        </template>
                        <template v-else>
                            <span class="ml-1">{{v.aucap_evaluasi_text||"-"}}</span>
                        </template>
                    
                    </td>
                    <td>
                    <span>{{v.aucap_ppa}}</span>
                    </td>
                    <td>
                    <span v-if="v.aucap_is_done != 'Y'"> - </span>
                    <span v-else>Selesai pada {{v.aucap_done_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                    </td>
                </tr>
                <tr v-if="!(dataCatatanAsuhan.data||[]).length">
                    <td colspan="6" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-sm table-align-top">
                <thead>
                <tr>
                    <td colspan="6" class="bg-indigo">
                    <span class="font-weight-semibold">Catatan Asuhan Dokter</span>
                    </td>
                </tr>
                <tr>
                    <th>Waktu</th>
                    <th>Asesmen/Tindakan</th>
                    <th>Waktu Evaluasi</th>
                    <th>Evaluasi</th>
                    <th>PPA</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in (dataCatatanAsuhan.dataKajianDokter||[])" :key="k">
                    <td>
                    <span>{{v.aucad_tanggal | moment("DD MMMM YYYY")}}, {{v.aucad_jam}}</span>
                    </td>
                    <td>
                    <span>{{v.aucad_assesmen||"-"}}</span>
                    </td>
                    <td>
                    <span>{{v.aucad_tanggal_evaluasi | moment("DD MMMM YYYY")}}, {{v.aucad_jam_evaluasi||"-"}}</span>
                    </td>
                    <td>                            
                    <span>{{v.aucad_evaluasi||"-"}}</span>
                    </td>
                    <td>
                    <span>{{v.aucad_ppa||"-"}}</span>
                    </td>
                    <td>
                    <span v-if="v.aucad_is_active != 'Y'"> - </span>
                    <span v-else>Selesai pada {{v.aucad_done_at | moment("DD MMMM YYYY, HH:mm")}}</span>
                    </td>
                </tr>
                <tr v-if="!(dataCatatanAsuhan.dataKajianDokter||[]).length">
                    <td colspan="6" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
        </div>
        
        <template v-if="Object.keys(dataCatatanAsuhan||{}).length ">
        <div class="table-responsive">
            <table v-if="showIntervensiCA || (row.ap_usia == null && row.ap_dob == null && (dataCatatanAsuhan.dataKajianPerawat.aukprj_ed_value >= 90 || dataCatatanAsuhan.dataKajianPerawat.aukprj_humpty_value >= 12 || dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 25 || 
            getTotalSydneyCA >=6))" class="table table-bordered table-sm table-striped">
            <thead>
                <tr>
                    <td colspan="2" class="bg-indigo">
                    <span class="font-weight-semibold">Intervensi Risiko Jatuh</span>
                    </td>
                </tr>
                <tr>
                    <th width="50%">Tindakan</th>
                    <th width="50%">Evaluasi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang == 'Y'">
                    <td>
                        <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                        
                    <td>
                        <label class="mb-0">Terpasang gelang warna kuning pada</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang_text || "-"}}</p>
                    </td>

                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">
                    <td>
                        <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                    <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">Pagar pengaman tempat tidur terpasang pada kedua sisi</span>
                    <span v-else> - </span>
                    </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y'">
                    <td>
                        <label class="mb-0">Mengunci roda tempat tidur</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                        <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y'">Roda tempat tidur terkunci dan tidak mudah tergeser</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y'">
                    <td>
                        <label class="mb-0">Memasang restrain</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                        <label class="mb-0">Terpasang restrain di</label>
                        <p v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y'">{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain_text||"-"}}</p>
                        <p v-else> - </p>
                    </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">
                    <td>
                        <label class="mb-0">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                        <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga</span>
                        <span v-else> - </span>
                    </td>
                </tr>
            </tbody>      
            </table>
        </div>
        </template>
    </b-modal>
    
    <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
        <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
    </b-modal>
    
    <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
        <RiwayatRMUGD v-bind="passToSubRMUGD" />
    </b-modal>

    <!-- Modal Ranap -->
    <b-modal v-model="modal.kajianAwal" :title="'Kajian Awal Rawat Inap'" size="xl" ok-only ok-title="Tutup">
      <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0" v-model="tabKajianAwal">
        <KajianAwal :kajianData="kajianData" :tab.sync="tabKajianAwal" />

        <KajianPersalinan :kajianData="kajianPersalinanData"  />
      </b-tabs>
    </b-modal>

    <b-modal v-model="modal.edukasiTerintegrasi" title="Edukasi Terintegrasi" size="xl" ok-only ok-title="Tutup">
      <edukasi-terintegrasi :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.dischargePlanning" title="Discharge Planning" size="xl" ok-only ok-title="Tutup">
      <discharge-planning :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.hais" title="Riwayat Hais" size="xl" ok-only ok-title="Tutup">
      <hais :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.dokumenPersetujuan" title="Dokumen Persetujuan" size="xl" ok-only ok-title="Tutup">
      <dokumen-persetujuan-ranap :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.riwayatRM" title="Riwayat Rekam Medis" size="xl" ok-only ok-title="Tutup">
      <riwayat-rm-ranap :reg-id.sync="dataRanap.regId" :row-data="dataRanap" />
    </b-modal>
    <!-- END Modal Ranap -->
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
const moment = require('moment')
import Gen from '@/libs/Gen.js'

import DetailKajianAwal from '@/components/RiwayatPOLI/DetailKajianAwal2.vue'
import DetailKajianLanjutan from '@/components/RiwayatPOLI/DetailKajianLanjutan2.vue'
import RiwayatRM from '@/components/RiwayatPOLI/RiwayatRM2.vue'
import RiwayatInsiden from './RiwayatInsiden.vue'
import VueHtml2pdf from 'vue-html2pdf'
import Datepicker from 'vuejs-datepicker'

import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD2.vue'
import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD2.vue'

import Hais from './FormDetailCmp/Hais.vue'
import KajianAwal from '@/components/Ranap/KajianAwal.vue'
import KajianPersalinan from '@/components/Ranap/KajianPersalinan.vue'
import EdukasiTerintegrasi from './FormDetailCmp/EdukasiTerintegrasi.vue'
import DischargePlanning from './FormDetailCmp/DischargePlanning.vue'
import DokumenPersetujuanRanap from './FormDetailCmp/DokumenPersetujuanRanap.vue'
import { default as RiwayatRmRanap } from './FormDetailCmp/RiwayatRM.vue'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    data: Object,
    arsip: Array,
    mDokterData: Array,
  },
  data() {
    return {
        activeTab: 1,
        pageNow: 1,

        openDetailKajian: false,
        openTindakan: false,
        rowReg: {},
        mPekerjaan: [],
        mAlatBantu: [],
        mSkrininGizi: [],
        mSkrininGiziV2: [],
        diagnosa: [],
        mSDKI: [],
        mSLKI: [],
        mSIKI: [],
        mPoli: [],
        mDokter: [],
        mKesadaran: [],
        mICD9: [],
        mICD10: [],
        mRadiologi: [],
        mLab: [],
        riwayatObat: [],
        resepDokter: [],
        mObatPreferensi: [],
        mDosis: [],
        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwyatRMPerawatIS: '',

        statusElektroterapi: [],
        statusMekanoTerapi: [],
        statusLatihan: [],
        mAlatKesehatan: [],
        mRacikan: [],
        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],

        dataRiwayatRMFarmasi: [],
        dataRiwayatRMFollowUp: {},

        dataInsiden: [],
        dataHais: [],

        downloadResumeMedis: false,
        
        dataICD9RM: [],
        dataICD10RM: [],
        dummyDoctors: [
          { name: 'dr. PRATISTA ADI KRISNA, Sp.PD', poli: 'POLI PENYAKIT DALAM	' }
        ],

        rowDetail: {},
        openDetail: false,

        addData: {},
        openAdd: false,


        // new
        activeTabList: 1,

        openDetailKajianPerawatUGD: false,
        openRiwayatRMUGD: false,

        dataKajianPerawat: {},
        dataKajianDokter: {},

        historiKajianPerawat: [],
        historiKajianDokter: [],

        masterKajianPerawat: {},
        masterKajianDokter: {},

        masterTriase: {},
        dataKajianTriase: {},

        dataCatatanAsuhan: {},
        openCatatanAsuhan: false,

        historiKajianRadiologi: [],
        historiKajianLab: [],
        historiKajianTriase: [],


        dataKajianRadiologi: {},
        dataKajianLab: {},

        dataKajianFarmasi: [],
        dataUGDReservasi: {},

        openRadiologi: false,
        activeTabRadiologi: 1,

        dataInformedConsentRadiologi: [],
        dataInformedConsentPetugasRadiologi: [],
        rowIntraRS: {},
        openDokumenRadiologi: false,

        regID : null,
        openRiwayatData : {},
        namaPetugasRadiologi: null,
        openModalIC: false,
        rowIC: {},
        
        resumeMedisType: null,

        // Ranap
        modal: {
          kajianAwal: false,
          edukasiTerintegrasi: false,
          hais: false,
          dokumenPersetujuan: false,
        },
        tabKajianAwal: 0,
        kajianData: {},
        kajianPersalinanData: {},
        dataRanap: {},
    }
  },
  components:{
    DetailKajianAwal,DetailKajianLanjutan,
    RiwayatRM,RiwayatInsiden,
    VueHtml2pdf,Datepicker,
    KajianPerawatUGD,RiwayatRMUGD,
    KajianAwal, KajianPersalinan,
    EdukasiTerintegrasi, DischargePlanning,
    Hais, DokumenPersetujuanRanap,
    RiwayatRmRanap
  },
  computed:{
    isHavePemeriksaanFisik(){
        let total = 0
        if(Object.keys(this.dataCatatanAsuhan||{}).length){
            for(let i = 0; i < (this.Config.mr.configVitalSignUGD||[]).length; i++){
                if(this.dataCatatanAsuhan.rowDataDokter[this.Config.mr.configVitalSignUGD[i]['value']] != 'TP'){
                    total += 1
                }
            }
        }
        return total
    },
    dataList(){return this.$parent.data.data },
    dataUGDList(){return this.$parent.dataUGD.data },
    dataRanapList(){return this.$parent.dataRanap.data },
    passToSub(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mPekerjaan: this.mPekerjaan,
        mAlatBantu: this.mAlatBantu,
        mSkrininGizi: this.mSkrininGizi,
        mSkrininGiziV2: this.mSkrininGiziV2,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSLKI: this.mSLKI,
        mSIKI: this.mSIKI,
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        mKesadaran: this.mKesadaran,
        resepDokter: this.resepDokter,
        historyKajianPerawat: this.historyKajianPerawat
      })
    },
    passToSubLanjutan(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSIKI: this.mSIKI,
        mKesadaran: this.mKesadaran,
        resepDokter: this.resepDokter,
        historyKajianPerawat: this.historyKajianPerawat
      })
    },
    passToSubRM(){
      return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,
          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          
          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,
      })
    },
    passToSubRiwayatInsiden(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataInsiden: this.dataInsiden,
        dataHais: this.dataHais,
      })
    },
    ktpAddr(){
      if(this.row.ap_address && this.row.ap_rt && this.row.ap_rw && this.row.ap_kelurahan && this.row.ap_kecamatan && this.row.ap_kota){
        return (this.row.ap_address||"-") + ", RT."+(this.row.ap_rt||"-")+"/"+(this.row.ap_rw||"-")+", DESA "+
        (this.row.ap_kelurahan||"-")+", KEC."+(this.row.ap_kecamatan||"-")+ ", KAB/KOTA"+(this.row.ap_kota||"-")
      }else{
        return "-"
      }
    },
    domisiliAddr(){
      if(this.row.ap_dom_address && this.row.ap_dom_rt && this.row.ap_dom_rw && this.row.ap_dom_kelurahan && this.row.ap_dom_kecamatan && this.row.ap_dom_kota){
        return (this.row.ap_dom_address||"-") + ", RT."+(this.row.ap_dom_rt||"-")+"/"+(this.row.ap_dom_rw||"-")+", DESA "+
        (this.row.ap_dom_kelurahan||"-")+", KEC."+(this.row.ap_dom_kecamatan||"-")+ ", KAB/KOTA"+(this.row.ap_dom_kota||"-")
      }else{
        return "-"
      }
    },
    pjAddr(){
      if(this.row.arpj_address && this.row.arpj_rt && this.row.arpj_rw && this.row.arpj_kelurahan && this.row.arpj_kecamatan && this.row.arpj_kota){
        return (this.row.arpj_address||"-") + ", RT."+(this.row.arpj_rt||"-")+"/"+(this.row.arpj_rw||"-")+", DESA "+
        (this.row.arpj_kelurahan||"-")+", KEC."+(this.row.arpj_kecamatan||"-")+ ", KAB/KOTA"+(this.row.ap_dom_kota||"-")
      }else{
        return "-"
      }
    },
    
    passToSubPerawatUGD(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataKajianPerawat: this.dataKajianPerawat,
        historiKajianPerawat: this.historiKajianPerawat,
        masterKajianPerawat: this.masterKajianPerawat,
      })
    },

    passToSubRMUGD(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataKajianDokter: this.dataKajianDokter,
        dataKajianTriase: this.dataKajianTriase,
        historiKajianDokter: this.historiKajianDokter,
        masterKajianDokter: this.masterKajianDokter,
        masterTriase: this.masterTriase,
        historiKajianRadiologi: this.historiKajianRadiologi,
        historiKajianLab: this.historiKajianLab,
        historiKajianTriase: this.historiKajianTriase,
        dataKajianRadiologi: this.dataKajianRadiologi,
        dataKajianLab: this.dataKajianLab,
        dataKajianFarmasi: this.dataKajianFarmasi,
        dataUGDReservasi: this.dataUGDReservasi
      })
    },

    

    showIntervensiCA(){
      if(Object.keys(this.dataCatatanAsuhan||{}).length){
        if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_is_gangguan_jiwa == 'Y'){
          return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_ed_value >= 90
        }
        else{
          if(this.row.ap_usia <= 18){
              return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 12
          }else if(this.row.ap_usia > 18 && this.row.ap_usia <= 60){
              return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 25
          }else if(this.row.ap_usia > 60){
              return this.getTotalSydney >=6
          }else{
              return false
          }
        }
      }
      return false
    },
    getTotalSydneyCA(){
        let total = 0
        if(Object.keys(this.dataCatatanAsuhan||{}).length){
          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh == "Y" || this.dataCatatanAsuhan.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh2bulan == "Y"){
              total = total + 6
          }

          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_dellirium == "Y" || this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_disorientasi == "Y" ||
          this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_agitasi == "Y" ){
              total = total + 14
          }
          
          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_kacamata == "Y" || this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_buram == "Y" ||
          this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_glaucoma == "Y" ){
              total = total + 1
          }

          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_kebiasaan == "Y"){
              total = total + 2
          }

          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_transfer + this.dataCatatanAsuhan.dataKajianPerawat.aukprj_mobilitas > 3){
              total = total + 7
          }
        }
        return total  
    },

    selectedLabInput(){
      let data = []
      for(let ik = 0; ik < (this.dataCatatanAsuhan.rowDataDokter.aupdl_data||[]).length; ik++){
        for(let jk = 0; jk < (this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData']||[]).length; jk++){
          for(let kk = 0; kk < (this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
              if(this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                data.push(this.dataCatatanAsuhan.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
              }
          }
        }
      }
      if(this.dataCatatanAsuhan.rowDataDokter.aupdl_hasil_lainnya){
        data.push(this.dataCatatanAsuhan.rowDataDokter.aupdl_hasil_lainnya)
      }
      return data.join(", ")
    },

    selectedRadioInput(){
      let data = []
      for(let i = 0; i < (this.dataCatatanAsuhan.rowDataDokter.aupdr_data||[]).length; i++){
        for(let j = 0; j < (this.dataCatatanAsuhan.rowDataDokter.aupdr_data[i]['data']||[]).length; j++){  
            if(this.dataCatatanAsuhan.rowDataDokter.aupdr_data[i]['data'][j]['selected']){
              data.push(this.dataCatatanAsuhan.rowDataDokter.aupdr_data[i]['data'][j]['text'])
            }
        }
      }
      if(this.dataCatatanAsuhan.rowDataDokter.aupdr_hasil_lainnya){
        data.push(this.dataCatatanAsuhan.rowDataDokter.aupdr_hasil_lainnya)
      }
      return data.join(", ")
    },

  },

  methods: {
    // Ranap Methods
    generateQr(){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest("/do/" + this.$parent.modulePage, {
        data: {
          type: "generate-qr-rm",
          pasien: this.row.ap_id
        }
      }, "POST")
      .then(res => {
        this.$swal.fire({
          icon: 'success',
          title: res.data.message
        }).then(() => {
          this.row.ap_qr_no_rm = res.data.data.file
        })
      })
      .catch(err => {
        console.log(err)
        this.$swal.fire({
          icon: 'error',
          title: err.response.data.message
        })
      })
    },
    openRiwayatRMRanap(data){
      this.$set(this, 'dataRanap', { ...data, regId: data.aranr_id })
      this.$set(this.modal, 'riwayatRM', true)
    },
    openDokumenPersetujuan(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_aranres_id })
      this.$set(this.modal, 'dokumenPersetujuan', true)
    },
    openHais(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_id })
      this.$set(this.modal, 'hais', true)
    },
    openDischargePlanning(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_id })
      this.$set(this.modal, 'dischargePlanning', true)
    },
    openEdukasiTerintegrasi(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_id }) // ET = Edukasi Terintegrasi
      this.$set(this.modal, 'edukasiTerintegrasi', true)
    },
    openKajianRanap(data){
      this.getKajianPersalinanData(data.aranr_id)

      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian",
            regId: data.aranr_id
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        if(!resp.isFound){
          return this.$swal({
            icon: 'error',
            title: 'Data Kajian awal Tidak Ditemukan'
          })
        }

        this.$set(this.modal, 'kajianAwal', true)
        this.kajianData = resp.data
      })
    },
    getKajianPersalinanData(regId){
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian-persalinan",
            regId: regId
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        if(!resp.isFound){
          return this.$swal({
            icon: 'error',
            title: 'Data Kajian persalinan Tidak Ditemukan'
          })
        }
        this.kajianPersalinanData = resp.data
      })
    },
    // -- 
    toCatatanAsuhanDetail(regId){
        this.$parent.loadingOverlay = true
        Gen.apiRest(
            "/get/"+"UGDCatatanAsuhan"+"/"+regId, 
        ).then(res=>{  
            this.$parent.loadingOverlay = false
            this.openCatatanAsuhan = true
            this.dataCatatanAsuhan = res.data
            this.regID = regId
        })
    },
    sinkronData(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan sinkronkan data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          let data = {
            code: this.row.ap_code,
            type: "sinkron-data"
          }
          this.$parent.loadingOverlay = true
          Gen.apiRest(
            "/do/"+this.$parent.modulePage,
            {data:data}, 
            "POST"
          ).then(res=>{
            this.$parent.loadingOverlay = false
            this.$parent.apiGet()
          })
        }
      })
    },
    
    addNew(){
      this.addData = {
        'aadr_ap_id' : this.$route.params.pageSlug,
        'aadr_dokter_id' : null,
        'aadr_reg_code' : null,
        'aadr_reg_date' : null,
        'aadr_keluhan' : null,
        'aadr_dokumen' : []
      }
    },

    deleteData(id){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan menghapus data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          let data = {
            id: id,
            type: "delete-arsip"
          }
          this.$parent.loadingOverlay = true
          Gen.apiRest(
            "/do/"+this.$parent.modulePage,
            {data:data}, 
            "POST"
          ).then(res=>{
            this.$parent.loadingOverlay = false
            this.$parent.apiGet()
          })
        }
      })
    },
    doOpenDetail(v){
      this.rowDetail = v
      this.openDetail = true
    },
    downloadPRMRJ(row){
      let data = {exptype: 'xlsx', type: "export", id: row.ap_id}
      let self = this

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'reportPRMRJ'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.$parent.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan-PRMRJ-${moment().format("YYYY-MM-DD")}.xlsx`
              link.click()
          },
          fail: data => {
              self.$parent.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
    downloadRM(v){
      Gen.apiRest(
          "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.arm_ar_id, 
      ).then(res=>{
          this.dataRiwayatRMDokter = res.data
          this.resumeMedisType = "RJ"
          this.$refs.html2Pdf.generatePdf()
      })
    },
    downloadRMUGD(v){
      Gen.apiRest(
          "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id, 
      ).then(res=>{
          this.dataRiwayatRMDokterUGD = res.data
          this.resumeMedisType = "UGD"
          this.$refs.html2Pdf.generatePdf()
      })
    },
    doOpenTindakan(v){
        this.$parent.loadingOverlay = true        
        let data = {
            id: v.ar_id,
            type: "get-hais-insiden"
        }
        Gen.apiRest(
            "/do/"+this.$parent.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.dataInsiden = res.data.dataInsiden
            this.dataHais = res.data.dataHais

            this.openTindakan = true
            this.$parent.loadingOverlay = false
    
        })
    },
    toDetail(v){
        this.$parent.loadingOverlay = true
        Gen.apiRest(
            "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianPerawatId+'?regId='+v.arm_ar_id, 
        ).then(res=>{
            this.$parent.loadingOverlay = false
            this.openDetailKajian = true
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
    },
    doOpenRiwayatRM(v){
        this.$parent.loadingOverlay = true
        
        if(v.kajianDokter){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMDokter = res.data
                this.dataRiwayatRMDokter.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMDokter = {}
        }

        if(v.kajianDokterTerapi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokterTerapi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMDokterTerapi = res.data
                this.dataRiwayatRMDokterTerapi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMDokterTerapi = {}
        }

        if(v.kajianGizi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianGizi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMDokterGizi = res.data
                this.dataRiwayatRMDokterGizi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMDokterGizi = {}
        }

        if(v.kajianFisio){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianFisio+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMFisio = res.data
                this.dataRiwayatRMFisio.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMFisio = {}
        }

        if(v.kajianRadiologi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMRadiologi = res.data
                this.dataRiwayatRMRadiologi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
          this.dataRiwayatRMRadiologi = {}
        }
        
        if(v.kajianLab){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMLab = res.data
                this.dataRiwayatRMLab.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMLab = {}
        }
        

        Gen.apiRest(
            "/do/"+'RekamMedis',
            {data:{type:'get-obat-farmasi', regId: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data
            this.dataRiwayatRMFarmasi = resp.data
            this.loadingOverlay = false
        })
        
        Gen.apiRest(
            "/do/"+'RekamMedis',
            {data:{type:'get-riwayat-rekam-medis', ar_id: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data

            this.historyKajianPerawat = resp.historyKajianPerawat
            this.historyKajianDokter = resp.historyKajianDokter
            this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
            this.historyKajianDokterGizi = resp.historyKajianDokterGizi
            this.historyKajianFisio = resp.historyKajianFisio
            this.historyKajianRadiologi = resp.historyKajianRadiologi
            this.historyKajianLab = resp.historyKajianLab
            

            this.loadingOverlay = false
        })

        Gen.apiRest(
            "/get/"+'FollowUp'+'/'+this.row.ap_id+'?regId='+v.arm_ar_id, 
        ).then(res=>{
            this.loadingOverlay = false
            this.dataRiwayatRMFollowUp = res.data
            this.dataRiwayatRMFollowUp.Config = this.Config
            this.openRwiayatRM = true
        })

        this.rowReg = v

        setTimeout(()=>{
            this.$parent.loadingOverlay = false
            this.openRwiayatRM = true
        },2000)
    },
    changeTab(e){
        this.activeTab = e
    },
    changeTabRiwayat(e){
        this.activeTabList = e
    },
    toValidate(val){
      return {...val}
    },
    loadMore(){
        this.pageNow = this.pageNow + 1
        this.$parent.loadingOverlay = true

        let url = this.$parent.modulePage+'/'+this.$route.params.pageSlug
        Gen.apiRest(
            "/get/"+url, 
            {
            params: {page : this.pageNow}
            }
        ).then(res=>{
            this.$parent.loadingOverlay = false
            let newData = res.data.data.data
            for(let i = 0; i < (newData||[]).length; i++){
                this.$parent.data.data.push(newData[i])        
            }
        }).catch(()=>{
            this.$parent.loadingOverlay = false
        })
    },
    
    loadMoreUGD(){
        this.pageNow = this.pageNow + 1
        this.$parent.loadingOverlay = true

        let url = this.$parent.modulePage+'/'+this.$route.params.pageSlug
        Gen.apiRest(
            "/get/"+url, 
            {
            params: {page : this.pageNow}
            }
        ).then(res=>{
            this.$parent.loadingOverlay = false
            let newData = res.data.data.data
            for(let i = 0; i < (newData||[]).length; i++){
                this.$parent.data.data.push(newData[i])        
            }
        }).catch(()=>{
            this.$parent.loadingOverlay = false
        })
    },

    submitArsip(){
      this.$refs['VFormArsip'].validate().then(success=>{
        if(success){
          this.$parent.loadingOverlay = true        
          let data = this.addData
          data.type = 'add-arsip'
          Gen.apiRest(
              "/do/"+this.$parent.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.openAdd = false
              this.$parent.loadingOverlay = false
              this.$parent.apiGet()
              return this.$swal({
                  icon: 'success',
                  title: 'Data Arsip Berhasil Ditambahkan'
              })

          })
        }
      })
    },
    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    doCetakSEPUGD(v,k,isGenerate = false){
      let data = {exptype: 'xlsx', type: "export", id: v.aur_id, isGenerate: isGenerate}
      let self = this
      self.$parent.loadingOverlay = true

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'downloadSEPCaseMixUGDQueueVer'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          // xhrFields:{
          //     responseType: 'blob'
          // },
          success: data => 
          { 
            self.$parent.loadingOverlay = false
              
            // var link = document.createElement('a')
            // link.href = window.URL.createObjectURL(data.file)
            // link.download = `${v.absd_no_sep}_${this.row.ap_fullname}_${this.row.ap_code}_${moment().format("YYYY-MM-DD")}.pdf`
            // link.click()
            console.log(self)
            if(data.code != 'D'){
              self.$parent.dataUGD.data[k]['aur_esep_status'] = 'M'
              return self.$swal({
                icon: data.status,
                title: data.message,
              })
            }else{
              self.$parent.dataUGD.data[k]['aur_esep_status'] = 'D'
              window.open(self.$parent.uploader(data.file), '_blank')
            }
          },
          fail: data => {
              self.$parent.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },

    doCetakSEP(v,k, isGenerate=false){
      let data = {exptype: 'xlsx', type: "export", id: v.ar_id, isGenerate: isGenerate}
      let self = this
      self.$parent.loadingOverlay = true

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'downloadSEPCaseMixQueueVer'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          // xhrFields:{
          //   responseType: 'blob'
          // },
          success: data => 
          {
              self.$parent.loadingOverlay = false
              
              // var link = document.createElement('a')
              // link.href = window.URL.createObjectURL(data.file)
              // link.download = `${v.absd_no_sep}_${this.row.ap_fullname}_${this.row.ap_code}_${moment().format("YYYY-MM-DD")}.pdf`
              // link.click()

              if(data.code != 'D'){
                self.$parent.data.data[k]['ar_esep_status'] = 'M'
                return self.$swal({
                  icon: data.status,
                  title: data.message,
                })
              }else{
                self.$parent.data.data[k]['ar_esep_status'] = 'D'
                window.open(self.$parent.uploader(data.file), '_blank')
              }
          },
          fail: data => {
              self.$parent.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },


    
    toDetailKajianPerawatUGD(v,regID){
        this.$parent.loadingOverlay = true
        Gen.apiRest(
            "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id, 
        ).then(res=>{
            this.openDetailKajianPerawatUGD = true    
            this.dataKajianPerawat = res.data
            this.$parent.loadingOverlay = false
        })

        Gen.apiRest(
            "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id+'&master=1', 
        ).then(res=>{
            this.masterKajianPerawat = res.data
            console.log(this.masterKajianPerawat)
        })
    },
    doOpenRiwayatRMUGD(v){
        this.$parent.loadingOverlay = true

        Gen.apiRest(
            "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id, 
        ).then(res=>{
            this.openRiwayatRMUGD = true    
            this.dataKajianDokter = res.data
            this.$parent.loadingOverlay = false
            this.openRiwayatData = v
        })

        Gen.apiRest(
            "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id+'&master=1', 
        ).then(res=>{
            this.masterKajianDokter = res.data
        })

        
        Gen.apiRest(
            "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aurm_aur_id, 
        ).then(res=>{
            this.dataKajianTriase = res.data
        })
        
        Gen.apiRest(
            "/get/"+'UGDTriase'+'/'+'master', 
        ).then(res=>{
            this.masterTriase = res.data
        })

        if(v.kajianLab){
            Gen.apiRest(
                "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.dataKajianLab = res.data
            })
        }

        if(v.kajianRadiologi){
            Gen.apiRest(
                "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.dataKajianRadiologi = res.data
            })
        }

        
        Gen.apiRest(
            "/do/"+'UGDAssesment',
            {data:{type:'get-riwayat-rekam-medis', aur_id: v.aurm_aur_id}}
        ).then(res=>{
            let resp = res.data
            this.historiKajianPerawat = resp.historyKajianPerawat
            this.historiKajianDokter = resp.historyKajianDokter
            this.historiKajianRadiologi = resp.historyKajianRadiologi
            this.historiKajianLab = resp.historyKajianLab
            this.historiKajianTriase = resp.historyKajianTriase
            this.$parent.loadingOverlay = false
        })
        

        Gen.apiRest(
            "/do/" + 'UGDAssesment', {
                data: {
                type: 'get-obat-farmasi',
                regId: v.aurm_aur_id
                }
            }
        ).then(res => {
            let resp = res.data
            this.dataKajianFarmasi = resp.data
            this.dataUGDReservasi = resp.dataUGDReservasi
            this.$parent.loadingOverlay = false
        })
        
        
        this.openRiwayatRMUGD = true
    },
  },
}
</script>