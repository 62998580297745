<template>
<div>
  <div class="row p-3" v-if="loading.coreData">
    <div class="col-md-12">
      <div class="d-flex justify-content-center align-items-center text-primary">
        <b-spinner class="mr-2 mb-0" small></b-spinner>
        <h6 class="font-weight-semibold mb-0">Loading...</h6>
      </div>
    </div>
  </div>

  <template v-else>
    <table class="table table-bordered table-sm patient-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Tanggal/Jam</th>
          <th>Hasil Pemeriksaan</th>
          <th>Perawat</th>
          <th>Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(v, k) in tData.data" :key="`datas-${k}`">
          <td>
            <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{ k+1 }}</span>
          </td>
          <td>
            {{ (v.updated_date||v.created_date) | moment('LLL') }}

            <p class="mb-0 mt-2" v-if="v.is_draft == 'Y'">
              <b-badge variant="danger">Draft</b-badge>
            </p>
          </td>
          <td>{{ "Thorax" }}</td>
          <td>{{ v.nama_petugas }}</td>
          <td>
            <a href="javascript:;" v-if="v.is_draft == 'N'" class="btn btn-icon alpha-blue border-blue mr-2" v-b-tooltip.hover title="Lihat Kajian TBC" @click="openDetail(v.id)"><i class="icon-file-eye"></i></a>
          </td>
        </tr>

        <tr v-if="tData.page.total_data < 1">
          <td colspan="10">
            <h5 class="mb-0 text-center">Belum ada data monitoring</h5>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-end" v-if="tData.page.total_data > tData.page.per_page">
      <b-pagination class="mb-0" v-model="pageNoTData" :per-page="tData.page.per_page" :total-rows="tData.page.total_data" />
    </div>

    <b-modal id="detailKajian" size="xl" title="Detail Kajian TBC" hide-footer>
      <div class="row mt-2">
        <div class="col-md-12 mb-2">
          <div class="bg_card_blue p-3">
            <table class="table table-borderless table-sm text-uppercase">
              <tbody>
                <tr>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>No. Reg</h4>
                      <p>{{ parentData.patientData.aranr_reg_code }}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>No. Rekam Medis</h4>
                      <p>{{ parentData.patientData.ap_code }}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Nama Pasien</h4>
                      <p>{{ parentData.patientData.ap_fullname }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Tempat, Tanggal Lahir </h4>
                      <p>{{ parentData.patientData.ap_pob }}, {{ parentData.patientData.ap_dob | moment("DD MMM YYYY") }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Usia</h4>
                      <p>{{parentData.patientData.ap_usia_with_ket}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jenis Kelamin</h4>
                      <p>{{ parentData.patientData.cg_label }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Anamnesa</h6>
        </div>

        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-6">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Keluhan Utama</h4>
                          <p>{{ dataModal.keluhan_utama }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Riwayat Penyakit Sekarang</h4>
                          <p>{{ dataModal.riwayat_penyakit_sekarang }}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Riwayat Penyakit Dahulu</h4>
                          <p>{{ dataModal.riwayat_penyakit_dahulu }}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Alergi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive" v-if="dataModal.has_alergi == 'Y'">

                        <table class="table table-bordered table-striped table-hover table-sm patient-table">
                          <thead>
                            <tr>
                              <th>Jenis</th>
                              <th>Informasi Alergi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v, k) in dataModal.alergi" :key="`alergi-${k}`">
                              <td class="text-capitalize">{{ (v.jenis||'').toLowerCase() }}</td>
                              <td>{{ v.name }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5 class="text-center mb-0" v-else>Pasien tidak memiliki alergi</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
        </div>
        <div class="card-body p-3">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tekanan Darah</h4>
                      <p>{{ dataModal.sistolik }}/{{ dataModal.diastolik }} mmHG</p>
                    </div>
                  </td>
                  <td colspan="2">
                    <div class="result_tab">
                      <h4>Nadi</h4>
                      <p>{{ dataModal.nadi }}x/mnt - <span class="text-capitalize">{{ (dataModal.nadi_type||'').toLowerCase() }}</span></p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Pernafasan</h4>
                      <p>{{ dataModal.pernafasan }}x/mnt</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>SPO2</h4>
                      <p>{{ dataModal.sp_o2 }}%</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Suhu</h4>
                      <p>{{ dataModal.suhu }}C</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Berat Badan</h4>
                      <p>{{ dataModal.berat_badan }}kg</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tinggi</h4>
                      <p>{{ dataModal.tinggi_badan }}Cm</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Lingkar Kepala</h4>
                      <p>{{ dataModal.lingkar_kepala }}cm</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="result_tab">
                      <h4>BMI</h4>
                      <p>{{ dataModal.bmi }}kg/m2</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Pengkajian Fisik</h6>
        </div>
        <div class="card-body p-3">
          <div>
            <table class="table table-bordered table-sm">
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Kondisi Umum</h4>
                    <p class="text-capitalize">{{ (dataModal.kondisi_umum||'').toLowerCase() }}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Glasgow Coma Scale (GCS)</h4>
                    <ul>
                      <li>E: tidak ada respon meskipun sudah dirangsang <span class="font-weight-semibold text-info-800">(1)</span></li>
                      <li>V: mengucapkan kata-kata yang tidak jelas <span class="font-weight-semibold text-info-800">(3)</span></li>
                      <li>M: menarik anggota gerak karena rangsangan nyeri <span class="font-weight-semibold text-info-800">(4)</span></li>
                    </ul>
                  </div>
                </td>
              </tr>
            </table>
            <div class="wrap_line mt-3">
              <h3>Kepala</h3>
              <table class="table table-bordered table-sm">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Konjungtiva</h4>
                      <p>{{ dataModal.kep_konjungtiva }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Sianosis</h4>
                      <p>{{ dataModal.kep_sianosis }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Sklera</h4>
                      <p>{{ dataModal.kep_sklera }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Hidung</h4>
                      <p>{{ dataModal.kep_hidung }}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="wrap_line">
              <h3>Thorax / Dada</h3>
              <table class="table table-bordered table-sm">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Paru</h4>
                      <p>{{ dataModal.trx_respirasi }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Respirasi</h4>
                      <p>{{ dataModal.trx_respirasi }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Alat Bantu Nafas</h4>
                      <p>{{ dataModal.trx_alat_bantu_nafas }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jantung</h4>
                      <p>{{ dataModal.trx_jantung }} <span v-if="dataModal.trx_jantung_note">- {{ dataModal.trx_jantung_note }}</span></p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="wrap_line">
              <h3>Abdomen</h3>
              <table class="table table-bordered table-sm">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Nyeri Tekan</h4>
                      <p>{{ dataModal.abd_nyeri == 'Y' ? 'Ya' : 'Tidak' }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Bising Usus</h4>
                      <p>{{ dataModal.abd_bising_usus == 'Y' ? 'Ya' : 'Tidak' }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Kondisi Abdomen</h4>
                      <p>{{ dataModal.abd_kondisi }}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <table class="table table-bordered table-sm">
              <tr>
                <td width="50%">
                  <div class="result_tab">
                    <h4>Hasil Pemeriksaan TCM</h4>
                    <p>{{ dataModal.aka_hasil_tcm }}</p>
                  </div>
                </td>
                <td width="50%">
                  <div class="result_tab">
                    <h4>Hasil Pemeriksaan BTA</h4>
                    <p>{{ dataModal.aka_hasil_bta }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Hasil Rontgen Thorax</h4>
                    <p>{{ dataModal.aka_hasil_thorax }}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Hasil Pemeriksaan PA / Mantoux Test</h4>
                    <p>{{ dataModal.aka_hasil_test }}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Edukasi </h6>
        </div>
        <div class="card-body p-3">
          <div>
            <div class="d-flex align-items-center mb-2" v-for="(v, k) in dataModal.edukasi" :key="`edukasi-${k}`">
              <i class="icon-checkmark-circle text-success"></i>
              <span class="ml-1 text-capitalize">{{ (v||'').toLowerCase() }}</span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </template>
</div>
</template>

<script>
import Gen from "@/libs/Gen"

const _ = global._

export default {
  name: "TBC",

  props: {
    regId: Number
  },

  data() {
    return {
      dataModal: {},
      diagnosa: [],

      tData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },
      pageNoTData: 1,
      loading: {
        coreData: false
      },

      patientData: {},
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    openDetail(id) {
      Gen.apiRest(
        "/get/" + "RanapPasienKajianTBC", {
          params: {
            type: "get-detail",
            id
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', res.data.data)
        this.$set(this, 'diagnosa', res.data.diagnosa)

        this.$bvModal.show("detailKajian")
      })
    },

    getList() {
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/" + "RanapPasienKajianTBC", {
          params: {
            type: "get-list",
            id_registrasi: this.regId
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })
    },
  },

  computed: {
    parentData(){
      return this.$parent.$parent.$parent.$parent.$parent
    }
  }

}
</script>
