<template>
<b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
  <b-tab title="Edukasi Terintegrasi">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive mb-0">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="2">Uraian Penjelasan Edukasi</th>
                <th>Penerima Penjelasan / Sasaran</th>
              </tr>
            </thead>
            <tbody v-for="(v, k) in edukasiTerintegrasi" :key="`et-${k}`">
              <tr class="table-info">
                <td colspan="4">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">Form Edukasi <span class="text-capitalize">{{ (v.type||'').toLowerCase() }}</span> - {{ v.education_date | moment("LLL") }} </div>
                      <small class="text-secondary">Pelapor: <span class="text-capitalize">{{ v.type.toLowerCase() }}</span> - {{ v.nama_petugas }}</small>
                      <b-badge variant="danger" class="ml-2" v-if="v.show_reeducation_label">Re-Edukasi</b-badge>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top" width="35%">
                  <div class="mb-2">
                    <div class="font-weight-semibold">Materi Edukasi</div>
                    <ul class="pl-3 mb-0">
                      <li v-for="(materi, kMateri) in v.materi" :key="`mat-${kMateri}`">{{ materi.label }}</li>
                    </ul>
                  </div>
                  <div class="mb-2">
                    <div class="font-weight-semibold">Tingkat Pemahaman Awal</div>
                    <span>{{ objMrPemahamanAwal[v.pemahaman_awal] }}</span>
                  </div>
                  <div class="mb-2" v-if="v.question">
                    <div class="font-weight-semibold">Pertanyaan</div>
                    <span>{{ v.question }}</span>
                  </div>
                </td>
                <td class="align-top" width="35%">
                  <div class="mb-2">
                    <div class="font-weight-semibold">Metode Edukasi</div>
                    <span>{{ v.metode.map(dtMetode => dtMetode.label).join(", ") }}</span>
                  </div>
                  <div class="mb-2">
                    <div class="font-weight-semibold">Sarana Edukasi</div>
                    <span>{{ v.sarana.map(dtMetode => dtMetode.label).join(", ") }}</span>
                  </div>
                  <div class="mb-2">
                    <div class="font-weight-semibold">Evaluasi</div>
                    <span>{{ objMrEvaluasi[v.evaluasi] }}</span>
                  </div>
                  <!-- Jika ada Re-edukasi -->
                  <div class="mb-2" v-if="v.reschedule_date">
                    <div class="font-weight-semibold">Tanggal Re-edukasi</div>
                    <span>{{ v.reschedule_date | moment("DD MMMM YYYY") }}</span>
                  </div>
                </td>
                <td class="align-top">
                  <span>{{ v.relation_patient }} - {{ v.receiver_name }}</span>
                </td>
              </tr>
            </tbody>

            <tbody v-if="!(edukasiTerintegrasi||[]).length">
              <tr>
                <td colspan="99">
                  <h5 class="text-center">
                    Belum ada data edukasi terintegrasi
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-tab>

  <b-tab title="Edukasi Harian">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive mb-0">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="2">Uraian Penjelasan Edukasi</th>
                <th>Penerima Penjelasan / Sasaran</th>
              </tr>
            </thead>
            <tbody v-for="(v, k) in edukasiHarian" :key="`eh-${k}`">
              <tr class="table-info">
                <td colspan="4">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">{{ v.education_date | moment("LLL") }}</div>
                      <small class="text-secondary">
                        Pelapor: <span class="text-capitalize">{{ v.type.toLowerCase() }}</span> - {{ v.nama_petugas }}</small>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top" colspan="2">
                  <p v-html="v.description"></p>
                </td>
                <td class="align-top">
                  <span>{{ v.relation_patient }} - {{ v.receiver_name }}</span>
                </td>
              </tr>
            </tbody>

            <tbody v-if="!(edukasiHarian||[]).length">
              <tr>
                <td colspan="99">
                  <h5 class="text-center">
                    Belum ada data edukasi harian
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-tab>
</b-tabs>
</template>

<script>
import Gen from '@/libs/Gen'

const _ = global._

export default {
  name: "EdukasiTerintegrasi",
  props: {
    regId: Number
  },

  data() {
    return {
      mrPemahamanAwal: [],
      mrEvaluasi: [],

      edukasiTerintegrasi: [],
      edukasiHarian: [],

      loading: {
        coreData: false
      }
    }
  },

  mounted(){
    this.getData()
  },

  methods: {
    getData() {
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/RanapEdukasiTerintegrasi", {
          params: {
            type: "get-detail",
            id_registrasi: this.regId
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })

      Gen.apiRest(
        "/get/" + "RanapETPerawat", {
          params: {
            type: "get-detail",
            id_registrasi: this.regId
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
  },

  computed: {
    objMrPemahamanAwal(){
      return this.mrPemahamanAwal.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    objMrEvaluasi(){
      return this.mrEvaluasi.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    }
  }
}
</script>
