<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 mb-2">
        <div class="bg_card_blue p-3">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Reg</h4>
                    <p>{{isParent.rowReg.aranr_reg_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{isParent.rowReg.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Pasien</h4>
                    <p>{{isParent.rowReg.ap_fullname||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir </h4>
                    <p>{{isParent.rowReg.ap_address||"-"}}, 
                    <span v-if="isParent.rowReg.ap_dob">{{isParent.rowReg.ap_dob | moment("DD MMMM YYYY")}}</span>
                    <span v-else> - </span>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{isParent.rowReg.ap_usia_with_ket}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{isParent.rowReg.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Kontra Indikasi Absolut </h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div class="d-flex mb-2" v-for="(v,k) in (rowData.arankpksd_kontra_absolut||[])" :key="k">
                <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                <span class="d-inline-block align-middle"> {{v}} </span>
              </div>
              <span v-if="!(rowData.arankpksd_kontra_absolut||[]).length">Tidak ada data</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Kontra Indikasi Relatif</h6>
          </div>
          <div class="card-body py-2">
            <div class="req-list">
              <div class="d-flex mb-2" v-for="(v,k) in (rowData.arankpksd_kontra_relatif||[])" :key="k">
                <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                <span class="d-inline-block align-middle"> {{v}} </span>
              </div>
              <span v-if="!(rowData.arankpksd_kontra_relatif||[]).length">Tidak ada data</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  data() {
    return {
      rowData: {},
      rowReg: {}
    }
  },
  methods: {
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    apiGetForm(params = {}, page = 1){
      if(!this.isParent.regId) if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = (this.isParent.regId ? "RanapPasienKajianStriptokinase" : this.modulePage)+'/'+(this.$parent.regId||this.$route.params.pageSlug)+'/'+this.isParent.rowEdit.arankpks_id
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })
    },
  },
  mounted() {
    this.apiGetForm()
  }
}

</script>