<template>
  <fieldset>
    <div class="wrap_line_heading">
      <h6 class="font-weight-semibold">Biodata Ibu</h6>
      <div class="row">
        <div class="form-group col-md-4">
          <label>Nama Lengkap <small class="txt_mandatory" v-if="isParent.row.ap_is_pasien_bbl == 'Y'">*</small></label>
          <b-form-input v-model="isParent.row.arpdo_ibu_nama" :formatter="isParent.$parent.normalText" type="text" name="namaLengkap" id="namaLengkap" class="form-control" placeholder="Nama Lengkap" />
          
          <VValidate 
            v-if="isParent.row.ap_is_pasien_bbl == 'Y'"
            name="Nama Lengkap Ibu" 
            v-model="isParent.row.arpdo_ibu_nama" 
            :rules="toValidate(isParent.mrValidation.arpdo_ibu_nama)"
          />
        </div>
        <div class="form-group col-md-4 col-lg-3">
          <label for="ibuUmur">Tanggal Lahir <small v-if="isParent.row.ap_is_pasien_bbl == 'Y'" class="txt_mandatory">*</small></label>
          <div class="input-group">
            <div class="input-group mb-3">
              <datepicker
                input-class="form-control transparent"
                placeholder="Tanggal Lahir" class="my-datepicker"
                calendar-class="my-datepicker_calendar"
                v-model="isParent.row.arpdo_ibu_dob"
              >
              </datepicker>
              <div class="input-group-append calendar-group">
                <span class="input-group-text" id="basic-addon2"><i
                    class="icon-calendar"></i></span>
              </div>
            </div>
          </div>

          <VValidate 
            v-if="isParent.row.ap_is_pasien_bbl == 'Y'"
            name="Tanggal Lahir Ibu" 
            v-model="isParent.row.arpdo_ibu_nama" 
            :rules="toValidate(isParent.mrValidation.arpdo_ibu_nama)"
          />
        </div>
        <div class="form-group col-md-3">
          <label for="ibuSukuBangsa">Suku/bangsa </label>
          <v-select placeholder="Pilih Suku" v-model="isParent.row.arpdo_ibu_suku"
              :options="isParent.mRoSuku" label="text" :clearable="true"
              :reduce="v=>v.value">
          </v-select>
        </div>
        <div class="form-group col-md-4 col-lg-2">
          <label>Pendidikan </label>
          <v-select placeholder="Pilih Pendidikan" v-model="isParent.row.arpdo_ibu_pendidikan"
            :options="isParent.mPendidikan" label="text" :clearable="true"
            :reduce="v=>v.value">
          </v-select>
        </div>
        <div class="form-group col-md-3">
          <label>Pekerjaan </label>
          <v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.arpdo_ibu_pekerjaan"
              :options="isParent.mPekerjaan" label="text" :clearable="true"
              :reduce="v=>v.value">
          </v-select>
        </div>

        <div class="form-group col-md-3" v-if="isParent.row.arpdo_ibu_pekerjaan == 99999">
          <label>Pekerjaan Lainnya <small class="text-muted"></small> <small class="txt_mandatory">*</small></label>
          <b-form-input v-model="isParent.row.arpdo_ibu_pekerjaan_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Pekerjaan Lainnya" />
          <VValidate  name="Pekerjaan Ibu Lainnya"  v-model="isParent.row.arpdo_ibu_pekerjaan_text" :rules="{required: 1, min: 2, max: 128}" />
        </div>

        <div class="form-group col-md-3">
          <label>Agama </label>
          <v-select placeholder="Pilih Agama" v-model="isParent.row.arpdo_ibu_agama"
            :options="isParent.mAgama" label="text" :clearable="true"
            :reduce="v=>v.value">
          </v-select>
        </div>
        
        <div class="form-group col-md-3" v-if="isParent.row.arpdo_ibu_agama == 99999">
          <label>Agama Lainnya <small class="text-muted"></small> <small
          class="txt_mandatory">*</small></label>
          <b-form-input v-model="isParent.row.arpdo_ibu_agama_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Agama Lainnya" />
          <VValidate 
              name="Agama Ibu Lainnya" 
              v-model="isParent.row.arpdo_ibu_agama_text" 
              :rules="toValidate(isParent.mrValidation.arpdo_ibu_agama_text)"
          />
        </div>
        
        <div class="form-group col-md-3">
          <label>Status Perkawinan</label>
          <v-select placeholder="Pilih Status Perkawinan" v-model="isParent.row.arpdo_ibu_status_perkawinan"
            :options="Config.mr.StatusPernikahan" label="text" :clearable="true"
            :reduce="v=>v.value">
          </v-select>
        </div>
        <div class="form-group col-md-9">
          <label>Alamat KTP <small class="txt_mandatory">*</small></label>
          <textarea v-model="isParent.row.arpdo_ibu_alamat_ktp" name="Alamat" id="Alamat" class="form-control" placeholder="Alamat "></textarea>
          <VValidate 
            v-if="isParent.row.ap_is_pasien_bbl == 'Y'"
            name="Alamat KTP Ibu" 
            v-model="isParent.row.arpdo_ibu_alamat_ktp" 
            :rules="toValidate(isParent.mrValidation.arpdo_ibu_alamat_ktp)"
          />
        </div>
      </div>
    </div>

    <div class="wrap_line_heading mt-3">
      <h6 class="font-weight-semibold">Biodata Ayah</h6>
      <div class="row">
        <div class="form-group col-md-4">
          <label>Nama Lengkap</label>
          <b-form-input v-model="isParent.row.arpdo_ayah_nama" :formatter="isParent.$parent.normalText" type="text" name="namaLengkap" id="namaLengkap" class="form-control" placeholder="Nama Lengkap" />
          
        </div>
        <div class="form-group col-md-4 col-lg-3">
          <label for="AyahUmur">Tanggal Lahir</label>
          <div class="input-group">
            <div class="input-group mb-3">
              <datepicker
                input-class="form-control transparent"
                placeholder="Tanggal Lahir" class="my-datepicker"
                calendar-class="my-datepicker_calendar"
                v-model="isParent.row.arpdo_ayah_dob"
              >
              </datepicker>
              <div class="input-group-append calendar-group">
                <span class="input-group-text" id="basic-addon2"><i
                    class="icon-calendar"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="AyahSukuBangsa">Suku/bangsa </label>
          <v-select placeholder="Pilih Suku" v-model="isParent.row.arpdo_ayah_suku"
              :options="isParent.mRoSuku" label="text" :clearable="true"
              :reduce="v=>v.value">
          </v-select>
        </div>
        <div class="form-group col-md-4 col-lg-2">
          <label>Pendidikan </label>
          <v-select placeholder="Pilih Pendidikan" v-model="isParent.row.arpdo_ayah_pendidikan"
              :options="isParent.mPendidikan" label="text" :clearable="true"
              :reduce="v=>v.value">
          </v-select>
        </div>
        <div class="form-group col-md-4 col-lg-3">
          <label>Pekerjaan </label>
          <v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.arpdo_ayah_pekerjaan"
              :options="isParent.mPekerjaan" label="text" :clearable="true"
              :reduce="v=>v.value">
          </v-select>
        </div>

        <div class="form-group col-md-4 col-lg-3" v-if="isParent.row.arpdo_ayah_pekerjaan == 99999">
            <label>Pekerjaan Lainnya <small class="text-muted"></small> <small
            class="txt_mandatory">*</small></label>
            <b-form-input v-model="isParent.row.arpdo_ayah_pekerjaan_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Pekerjaan Lainnya" />
            <VValidate 
                name="Pekerjaan Ayah Lainnya" 
                v-model="isParent.row.arpdo_ayah_pekerjaan_text" 
                :rules="{required: 1, min: 2, max: 128}"
            />
        </div>

        <div class="form-group col-md-3">
          <label>Agama </label>
          <v-select placeholder="Pilih Agama" v-model="isParent.row.arpdo_ayah_agama"
            :options="isParent.mAgama" label="text" :clearable="true"
            :reduce="v=>v.value">
          </v-select>
        </div>
        
        <div class="form-group col-md-3" v-if="isParent.row.arpdo_ayah_agama == 99999">
          <label>Agama Lainnya <small class="text-muted"></small> <small
          class="txt_mandatory">*</small></label>
          <b-form-input v-model="isParent.row.arpdo_ayah_agama_text" :formatter="isParent.$parent.normalText" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Agama Lainnya" />
          <VValidate 
              name="Agama Ayah Lainnya" 
              v-model="isParent.row.arpdo_ayah_agama_text" 
              :rules="toValidate(isParent.mrValidation.arpdo_ayah_agama_text)"
          />
        </div>
        
        <div class="form-group col-md-3">
          <label>Status Perkawinan</label>
          <v-select placeholder="Pilih Status Perkawinan" v-model="isParent.row.arpdo_ayah_status_perkawinan"
            :options="Config.mr.StatusPernikahan" label="text" :clearable="true"
            :reduce="v=>v.value">
          </v-select>
        </div>
        <div class="form-group col-md-9">
          <label>Alamat KTP</label>
          <textarea v-model="isParent.row.arpdo_ayah_alamat_ktp" name="Alamat" id="Alamat" class="form-control" placeholder="Alamat "></textarea>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'

export default {
  computed: {    
    Config(){ return Config },
    isParent(){
        return this.$parent.$parent.$parent.$parent
    }
  }, 
  components: {
    Datepicker
  },
  methods: {
    toValidate(val){
        return {...val}
    },
  },
}
</script>