<template>
<div class="card">
  <div class="card-header bg-white">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="card-title font-weight-semibold">Early Warning System</h6>

      <b-dropdown id="dropdown-1" text="Tambah Data" class="m-md-2" variant="success" toggle-class="btn-labeled btn-labeled-left" v-if="$parent.moduleRole('add_monitoring') && !$parent.regId">
        <template #button-content>
          <b class="mr-2"><i class="icon-plus2"></i></b>
          <span>Tambah Data</span>
        </template>

        <b-dropdown-item @click="$parent.$emit('changeViewType', 'FORM_EWS')">Monitoring EWS</b-dropdown-item>
        <b-dropdown-item @click="$parent.$emit('changeViewType', 'FORM_BALANCE_CAIRAN')">Balance Cairan</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-md-6">
      <div class="card-body">
        <div class="chart-container">
          <label for="">Skor EWS</label>
          <v-chart class="chart" :option="optionEWS" autoresize />
        </div>
      </div>
    </div>
    <div class="col-md-6 border-left">
      <div class="card-body">
        <div class="chart-container">
          <label for="">Hemodinamik</label>
          <v-chart class="chart" :option="optionHemodinami" autoresize />
        </div>
      </div>
    </div>
    <div class="col-12 border-top">
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="card-body">
            <div class="chart-container">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <label for="">Balance Cairan</label>
                <button variant="info" type="button" class="btn btn-info btn-labeled btn-labeled-left" @click="openBCHistory()">
                  <b><i class="icon-history"></i></b>
                  <span>Lihat Data Balance Cairan</span>
                </button>
              </div>
              <v-chart class="chart" :option="optionBalanceCairan" autoresize />
            </div>
          </div>
        </div>
        <div class="col-md-6 border-left">
          <div class="card-body">
            <div class="chart-container">
              <label for="">Tekanan Darah</label>
              <v-chart class="chart" :option="optionTekananDarah" autoresize />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 border-top">
      <div class="row no-gutters">
        <div class="col-md-6">
          <div class="card-body">
            <label for="">Pengkajian Nyeri</label>
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Tanggal/Jam</th>
                  <th>Skor/Intervensi</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="tKajianNyeriData.is_loading">
                  <tr>
                    <td colspan="5">
                      <p class="mb-0 text-center">Loading...</p>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(v, k) in tKajianNyeriData.data" :key="`kn-${k}`">
                    <td>{{ k+1 }}</td>
                    <td>{{ v.created_date | moment("LLL") }}</td>
                    <td>{{ v.skor }}/{{ v.intervensi||'-' }}</td>
                  </tr>

                  <tr v-if="tKajianNyeriData.page.total_data == 0">
                    <td colspan="5">
                      <p class="mb-0 text-center">Tidak ada data</p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            <div class="d-flex justify-content-end mt-2" v-if="tKajianNyeriData.page.total_data > tKajianNyeriData.page.per_page">
              <b-pagination class="mb-0" v-model="pageNoKajianNyeri" :per-page="tKajianNyeriData.page.per_page" :total-rows="tKajianNyeriData.page.total_data" />
            </div>
          </div>
        </div>
        <div class="col-md-6 border-left">
          <div class="card-body">
            <label for="">Pengkajian Risiko Jatuh</label>
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Tanggal/Jam</th>
                  <th>Skor/Intervensi</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="tKajianRisikoJatuhData.is_loading">
                  <tr>
                    <td colspan="5">
                      <p class="mb-0 text-center">Loading...</p>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(v, k) in tKajianRisikoJatuhData.data" :key="`kn-${k}`">
                    <td>{{ k+1 }}</td>
                    <td>{{ v.arankprj_created_date | moment("LLL") }}</td>
                    <td>{{ v.arankprj_skor }}/{{ v.arankprj_intervensi||'-' }}</td>
                  </tr>

                  <tr v-if="tKajianRisikoJatuhData.page.total_data == 0">
                    <td colspan="5">
                      <p class="mb-0 text-center">Tidak ada data</p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            <div class="d-flex justify-content-end mt-2" v-if="tKajianRisikoJatuhData.page.total_data > tKajianRisikoJatuhData.page.per_page">
              <b-pagination class="mb-0" v-model="pageNoKajianRisikoJatuh" :per-page="tKajianRisikoJatuhData.page.per_page" :total-rows="tKajianRisikoJatuhData.page.total_data" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 border-top">
      <div class="row no-gutters">
        <div class="col-md-6 border-right">
          <div class="card-body">
            <label for="">Pengawasan GDA</label>
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Hasil GDA</th>
                  <th>Terapi</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="tGDAData.is_loading">
                  <tr>
                    <td colspan="5">
                      <p class="mb-0 text-center">Loading...</p>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(v, k) in tGDAData.data" :key="`kn-${k}`">
                    <td>{{ k+1 }}</td>
                    <td>{{ v.gda }}</td>
                    <td>{{ v.terapi||'-' }}</td>
                  </tr>

                  <tr v-if="tGDAData.page.total_data == 0">
                    <td colspan="5">
                      <p class="mb-0 text-center">Tidak ada data</p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            <div class="d-flex justify-content-end mt-2" v-if="tGDAData.page.total_data > tGDAData.page.per_page">
              <b-pagination class="mb-0" v-model="pageNoKajianGDA" :per-page="tGDAData.page.per_page" :total-rows="tGDAData.page.total_data" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <b-modal id="historyBCData" size="xl" hide-footer title="Riwayat Balance Cairan">
    <div class="table-responsive">
      <table class="table table-bordered table-sm" style="min-width:2500px;">
        <thead class="text-center">
          <tr>
            <th rowspan="3" width="112">Waktu</th>
            <th rowspan="3" width="86">Total Balance Cairan</th>
            <th colspan="11">Intake</th>
            <th colspan="12">Output</th>
          </tr>
          <tr>
            <!-- Intake -->
            <th colspan="2">Infus</th>
            <th colspan="2">Transfusi Darah</th>
            <th colspan="2">Minum</th>
            <th colspan="2">Titrasi Obat</th>
            <th colspan="2">Intake Lainnya</th>
            <th rowspan="2" width="86">Total Intake</th>
            <th colspan="2">Urine</th>
            <th colspan="2">Muntah</th>
            <th colspan="2">BAB</th>
            <th colspan="2">Pendarahan/Phlebotomy</th>
            <th colspan="2">Output Lainnya</th>
            <th rowspan="2">IWL</th>
            <th rowspan="2" width="86">Total Output</th>
          </tr>
          <tr>

            <!-- Infus -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Transfusi Darah -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Minum -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Titrasi Obat -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Intake Lainnya -->
            <th>Pemberian</th>
            <th>Total</th>

            <!-- Urine -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Muntah -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- BAB -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Pendarahan -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Output Lainnya -->
            <th>Pengeluaran</th>
            <th>Total</th>

            <!-- Output -->
            <!-- <th>IWL</th>
                      <th>Urine</th>
                      <th>Muntah</th>
                      <th>BAB</th>
                      <th>Pendarahan</th> -->
            <!-- <th>Detail Output</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, k) in tBCHistoryData.data" :key="`history-${k}`">
            <td>{{ v.created_date | moment("DD-MM-YYYY HH:mm") }}</td>
            <td>{{ v.total_balance_cairan }}cc</td>
            <td>              
              <ul class="pl-2 mb-0">
                <li class="my-1" v-for="(dtInfus, kInfus) in v.infus" :key="`dtInfus${kInfus}`">
                  <div>
                    {{ dtInfus.name || '-' }}
                    <a href="javascript:;" class="ml-1 infusDetails" v-b-tooltip.right.html :title="`
                        <ul class='m-0 pl-2 text-left'>
                          <li>Jml. Awal: ${dtInfus.jumlah_awal}cc</li>
                          <li>Sisa Infus: ${dtInfus.sisa}cc</li>
                          <li>Prog. Tetesan: ${dtInfus.tetesan}${dtInfus.tetesan_type||'tpm'}</li>
                          <li>Botol Ke: ${dtInfus.botol_ke}</li>
                          <li>Dipasang: ${timeLabel(dtInfus.waktu_pemasangan)}</li>
                          <li>Habis: ${timeLabel(dtInfus.waktu_habis)}</li>
                        </ul>
                      `">
                      <i class="icon-info22 text-info"></i>
                      <b-badge variant="warning" v-if="dtInfus.type" class="ml-1">{{ dtInfus.type }}</b-badge>
                    </a>
                  </div>
                  <div class="d-flex flex-column">
                    <!-- <small class="text-secondary">Jml. Awal: 500cc</small> -->
                    <!-- <small class="text-secondary">Jml. Tambahan: 125cc</small> -->
                    <small class="text-secondary">Sisa Infus: {{ dtInfus.sisa }}cc</small>
                    <!-- <small class="text-secondary">Prog. Tetesan: 5tpm</small> -->
                    <!-- <small class="text-secondary">Kantong ke: 2</small> -->
                    <small class="text-secondary">Dipasang: {{ timeLabel(dtInfus.waktu_pemasangan) }}</small>
                    <small class="text-secondary">Habis: {{ timeLabel(dtInfus.waktu_habis) }}</small>
                  </div>
                </li>
              </ul>
            </td>
            <td>{{ v.total_infus }}cc</td>
            <td>
              <ul class="pl-2 mb-0" v-if="v.is_transfusi_darah == 'Y'">
                <li class="my-1" v-for="(dtTransfusi, kTransfusi) in v.transfusi_darah" :key="`dtTransfusi${kTransfusi}`">
                  <div>
                    {{ dtTransfusi.jenis.label }}
                    <a href="javascript:;" class="ml-1 infusDetails" v-b-tooltip.right.html :title="`
                        <ul class='m-0 pl-2 text-left'>
                          <li>Jml. Awal: ${dtTransfusi.jumlah_awal}cc</li>
                          <li>Sisa Infus: ${dtTransfusi.sisa}cc</li>
                          <li>Prog. Tetesan: ${dtTransfusi.tetesan}tpm</li>
                          <li>Botol Ke: ${dtTransfusi.botol_ke}</li>
                          <li>Dipasang: ${timeLabel(dtTransfusi.waktu_pemasangan)}</li>
                          <li>Habis: ${timeLabel(dtTransfusi.waktu_habis)}</li>
                        </ul>
                      `">
                      <i class="icon-info22 text-info"></i>
                    </a>
                  </div>
                  <div class="d-flex flex-column">
                    <!-- <small class="text-secondary">Jml. Awal: 500cc</small> -->
                    <!-- <small class="text-secondary">Jml. Tambahan: 125cc</small> -->
                    <small class="text-secondary">Sisa Infus: {{ dtTransfusi.sisa }}cc</small>
                    <!-- <small class="text-secondary">Prog. Tetesan: 5tpm</small> -->
                    <!-- <small class="text-secondary">Kantong ke: 2</small> -->
                    <small class="text-secondary">Dipasang: {{ timeLabel(dtTransfusi.waktu_pemasangan) }}</small>
                    <small class="text-secondary">Habis: {{ timeLabel(dtTransfusi.waktu_habis) }}</small>
                  </div>
                </li>

                <span v-if="!(v.transfusi_darah||[]).length">-</span>
              </ul>

              <span v-else>-</span>
            </td>
            <td>{{ v.total_transfusi_darah||0 }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtMinum, kMinum) in v.minum" :key="`dtminum-${kMinum}`">
                  <span>{{ dtMinum.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtMinum.time) }}</small></div>
                </li>
              </ul>
            </td>
            <td>{{ v.total_minum }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtObat, kObat) in v.titrasi_obat" :key="`dtobat-${kObat}`">
                  <span>{{ dtObat.name }} {{ dtObat.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtObat.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.titrasi_obat||[]).length">-</span>
            </td>
            <td>{{ v.total_titrasi_obat }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtIntakeLainnya, kIntakeLainnya) in v.intake_lainnya" :key="`dtintakelainnya-${kIntakeLainnya}`">
                  <span>{{ dtIntakeLainnya.name }} {{ dtIntakeLainnya.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtIntakeLainnya.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.intake_lainnya||[]).length">-</span>
            </td>
            <td>{{ v.total_intake_lainnya }}cc</td>
            <td>{{ v.total_intake }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtUrine, kUrine) in v.urine" :key="`dturine-${kUrine}`">
                  <span>{{ dtUrine.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtUrine.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.urine||[]).length">-</span>
            </td>
            <td>{{ v.total_urine }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtMuntah, kMuntah) in v.muntah" :key="`dtmuntah-${kMuntah}`">
                  <span>{{ dtMuntah.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtMuntah.time) }}</small></div>
                </li>
              </ul>
            </td>
            <td>{{ v.total_muntah }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtBab, kBab) in v.bab" :key="`dtbab-${kBab}`">
                  <span>{{ dtBab.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtBab.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.bab||[]).length">-</span>
            </td>
            <td>{{ v.total_bab }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtPendarahan, kPendarahan) in v.pendarahan" :key="`dtpendarahan-${kPendarahan}`">
                  <span>{{ dtPendarahan.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtPendarahan.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.pendarahan||[]).length">-</span>
            </td>
            <td>{{ v.total_pendarahan }}cc</td>
            <td>
              <ul class="mb-0 pl-2">
                <li class="my-1" v-for="(dtOutputLainnya, kOutputLainnya) in v.output_lainnya" :key="`dtoutput_lainnya-${kOutputLainnya}`">
                  <span>{{ dtOutputLainnya.name }} {{ dtOutputLainnya.total }}cc</span>
                  <div><small class="text-secondary">pada {{ timeLabel(dtOutputLainnya.time) }}</small></div>
                </li>
              </ul>

              <span v-if="!(v.output_lainnya||[]).length">-</span>
            </td>
            <td>{{ v.total_output_lainnya }}cc</td>
            <td>{{ v.iwl }}cc</td>
            <td>{{ v.total_output }}cc</td>            
          </tr>

          <tr v-if="tBCHistoryData.page.total_data == 0">
            <td colspan="99">
              <span v-if="tBCHistoryData.page.is_loading">Loading...</span>
              <span v-else>Data Balance Cairan Belum Tersedia</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-end" v-if="tBCHistoryData.page.total_data > tBCHistoryData.page.per_page">
      <b-pagination class="mb-0" v-model="pageNoTData" :per-page="tBCHistoryData.page.per_page" :total-rows="tBCHistoryData.page.total_data" />
    </div>
  </b-modal>
</div>
</template>

<script>
import {
  use
} from 'echarts/core'
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  LineChart
} from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  MarkAreaComponent
} from 'echarts/components'
import VChart from 'vue-echarts'
import Gen from '@/libs/Gen'

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
])

export default {
  name: "ViewData",
  components: {
    VChart,
  },
  props: {

  },
  data() {
    return {
      tBCHistoryData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },
      pageNoTData: 1,

      tKajianNyeriData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },
      pageNoKajianNyeri: 1,

      tKajianRisikoJatuhData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },
      pageNoKajianRisikoJatuh: 1,

      tGDAData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },
      pageNoKajianGDA: 1,
    }
  },

  mounted() {
    this.getDataKajianNyeri(1)
    this.getDataKajianRisikoJatuh(1)
    this.getDataGDA(1)
  },

  methods: {
    timeLabel(timeObj) {
      if (!timeObj) return '-'
      if (typeof timeObj != 'object') return '-'

      const {
        HH,
        mm
      } = timeObj
      return `${HH}:${mm}`
    },
    getDataBCHistory(page = 1) {
      this.$set(this.tBCHistoryData.page, 'is_loading', true)
      Gen.apiRest(
        "/get/" + 'RanapBalanceCairan', {
          params: {
            page,
            type: 'get-history',
            id_registrasi: (this.$parent.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res => {
        const {
          historyData
        } = res.data
        this.$set(this, 'tBCHistoryData', historyData)

        this.$set(this.tBCHistoryData.page, 'is_loading', false)
      })
    },
    getDataKajianNyeri(page = 1) {
      this.$set(this.tKajianNyeriData.page, 'is_loading', true)
      Gen.apiRest(
        "/get/" + 'RanapPasienKajianNyeri', {
          params: {
            page,
            type: 'get-history',
            id_registrasi: (this.$parent.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res => {
        const {
          kajianNyeriData
        } = res.data
        this.$set(this, 'tKajianNyeriData', kajianNyeriData)

        this.$set(this.tKajianNyeriData.page, 'is_loading', false)
      })
    },
    getDataKajianRisikoJatuh(page = 1) {
      this.$set(this.tKajianRisikoJatuhData.page, 'is_loading', true)
      Gen.apiRest(
        "/get/" + 'RanapPasienKajianResikoJatuh', {
          params: {
            page,
            type: 'get-history',
            id_registrasi: (this.$parent.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res => {
        const {
          kajianRisikoJatuhData
        } = res.data
        this.$set(this, 'tKajianRisikoJatuhData', kajianRisikoJatuhData)

        this.$set(this.tKajianRisikoJatuhData.page, 'is_loading', false)
      })
    },

    getDataGDA(page = 1) {
      this.$set(this.tGDAData.page, 'is_loading', true)
      Gen.apiRest(
        "/get/" + 'RanapPasienMonitoringEWS', {
          params: {
            page,
            type: 'get-gda-data',
            id_registrasi: (this.$parent.regId||this.$route.params.pageSlug)
          }
        },
      ).then(res => {
        const {
          GDAData
        } = res.data
        this.$set(this, 'tGDAData', GDAData)

        this.$set(this.tGDAData.page, 'is_loading', false)
      })
    },
    openBCHistory() {
      this.$bvModal.show('historyBCData')

      this.getDataBCHistory(1)
    }
  },

  computed: {
    optionEWS() {
      return {
        color: ['#162C5E', '#03A9F4'],
        // Global text styles
        textStyle: {
          fontFamily: 'Roboto, Arial, Verdana, sans-serif',
          fontSize: 13
        },

        // Chart animation duration
        animationDuration: 750,

        // Setup grid
        grid: {
          left: 0,
          right: 5,
          top: 35,
          bottom: 0,
          containLabel: true
        },

        // Add legend
        legend: {
          data: ['Skor EWS'],
          itemHeight: 8,
          itemGap: 20
        },

        // Add tooltip
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            color: '#fff'
          }
        },

        // Horizontal axis
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: this.$parent.ews.xAxis,
          axisLabel: {
            color: '#333',
            rotate: 30,
            fontSize: 11,
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          }
        }],

        // Vertical axis
        yAxis: [{
          type: 'value',
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
            }
          }
        }],

        // Add series
        series: [{
          name: 'Skor EWS',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbolSize: 7,
          data: this.$parent.ews.data,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },

          markArea: {
            data: this.$parent.ews.xAxis.length ? this.markArea : []
          }
        }]
      }
    },
    markArea() {
      const markArea = [
        [{
            name: 'Risiko Rendah',
            yAxis: 2.5,
            itemStyle: {
              color: 'rgba(249, 215, 29, .33)'
            },
            label: {
              fontSize: 11,
            }
          },
          {
            yAxis: 4.25,
          }
        ],
        [{
            name: 'Risiko Sedang',
            yAxis: 4.75,
            itemStyle: {
              color: 'rgba(255, 140, 0, .33)'
            },
            label: {
              fontSize: 11,
            }
          },
          {
            yAxis: 5.25,
          }
        ],
        [{
            name: 'Risiko Tinggi',
            yAxis: 5.75,
            itemStyle: {
              color: 'rgba(255, 0, 0, .33)'
            },
            label: {
              fontSize: 11,
            }
          },
          {
            yAxis: 'max',
          }
        ],
      ]

      const filterDataMore6 = this.$parent.ews.data.filter(dt => dt >= 6)
      if (!filterDataMore6.length) markArea.pop()
      return markArea
    },
    optionHemodinami() {
      return {
        // Global text styles
        textStyle: {
          fontFamily: 'Roboto, Arial, Verdana, sans-serif',
          fontSize: 13
        },

        // Chart animation duration
        animationDuration: 750,

        // Setup grid
        grid: {
          left: 0,
          right: 5,
          top: 70,
          bottom: 0,
          containLabel: true
        },

        // Add legend
        legend: {
          itemHeight: 8,
          itemGap: 20
        },

        // Add tooltip
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            color: '#fff'
          },
          // formatter(params, ticket, callback){
          //   return params[0].seriesName
          // }
        },

        // Horizontal axis
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: this.$parent.hermodinamy.xAxis,
          axisLabel: {
            color: '#333',
            rotate: 30,
            fontSize: 11,
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          }
        }],

        // Vertical axis
        yAxis: [{
          type: 'value',
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
            }
          }
        }],

        // Add series
        series: [{
            name: 'Pernafasan',
            type: 'line',
            smooth: true,
            symbolSize: 7,
            data: this.$parent.hermodinamy.data.pernafasan,
            itemStyle: {
              normal: {
                borderWidth: 2
              }
            },
          },
          {
            name: 'SpO2',
            type: 'line',
            smooth: true,
            symbolSize: 7,
            data: this.$parent.hermodinamy.data.spo2,
            itemStyle: {
              normal: {
                borderWidth: 2
              }
            },
          },
          {
            name: 'Nadi',
            type: 'line',
            smooth: true,
            symbolSize: 7,
            data: this.$parent.hermodinamy.data.nadi,
            itemStyle: {
              normal: {
                borderWidth: 2
              }
            },
          },
          {
            name: 'Suhu',
            type: 'line',
            smooth: true,
            symbolSize: 7,
            data: this.$parent.hermodinamy.data.suhu,
            itemStyle: {
              normal: {
                borderWidth: 2
              }
            },
          },
        ]
      }
    },
    optionTekananDarah() {
      return {
        // Global text styles
        textStyle: {
          fontFamily: 'Roboto, Arial, Verdana, sans-serif',
          fontSize: 13
        },

        // Chart animation duration
        animationDuration: 750,

        // Setup grid
        grid: {
          left: 0,
          right: 5,
          top: 70,
          bottom: 0,
          containLabel: true
        },

        // Add legend
        legend: {
          itemHeight: 8,
          itemGap: 20
        },

        // Add tooltip
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            color: '#fff'
          },
          // formatter(params, ticket, callback){
          //   return params[0].seriesName
          // }
        },

        // Horizontal axis
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: this.$parent.tekananDarah.xAxis,
          axisLabel: {
            color: '#333',
            rotate: 30,
            fontSize: 11,
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          }
        }],

        // Vertical axis
        yAxis: [{
          type: 'value',
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
            }
          }
        }],

        // Add series
        series: [{
            name: 'Sistolik',
            type: 'line',
            smooth: true,
            symbolSize: 7,
            data: this.$parent.tekananDarah.data.sistolik,
            itemStyle: {
              normal: {
                borderWidth: 2
              }
            },
          },
          {
            name: 'Diastolik',
            type: 'line',
            smooth: true,
            symbolSize: 7,
            data: this.$parent.tekananDarah.data.diastolik,
            itemStyle: {
              normal: {
                borderWidth: 2
              }
            },
          },
        ]
      }
    },
    optionBalanceCairan() {
      return {
        color: ['#162C5E', '#03A9F4'],
        // Global text styles
        textStyle: {
          fontFamily: 'Roboto, Arial, Verdana, sans-serif',
          fontSize: 13
        },

        // Chart animation duration
        animationDuration: 750,

        // Setup grid
        grid: {
          left: 0,
          right: 5,
          top: 35,
          bottom: 0,
          containLabel: true
        },

        // Add legend
        legend: {
          itemHeight: 8,
          itemGap: 20
        },

        // Add tooltip
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            color: '#fff'
          },
          formatter: `
            {b} <br /> 
            <div class='d-flex align-items-center'>
              <div class='round-dark mr-1'></div> 
              <div>{a}: <strong>{c}cc</strong></div>
            </div>
          `,
        },

        // Horizontal axis
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: this.$parent.balanceCairan.xAxis,
          axisLabel: {
            color: '#333',
            rotate: 30,
            fontSize: 11,
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          }
        }],

        // Vertical axis
        yAxis: [{
          type: 'value',
          axisLabel: {
            color: '#333'
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#eee']
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
            }
          }
        }],

        // Add series
        series: [{
          name: 'Balance Cairan',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbolSize: 7,
          data: this.$parent.balanceCairan.data,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
        }, ]
      }
    }
  },

  watch: {
    pageNoTData(v) {
      this.getDataBCHistory(v)
    },
    pageNoKajianNyeri(v) {
      this.getDataKajianNyeri(v)
    },
    pageNoKajianRisikoJatuh(v) {
      this.getDataKajianRisikoJatuh(v)
    },
    pageNoGDA(v) {
      this.getDataGDA(v)
    },
  }
}
</script>

<style scoped>
.chart {
  height: 320px;
}
</style>
