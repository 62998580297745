<template>
<div>
  <div class="row p-3" v-if="loading.coreData">
    <div class="col-md-12">
      <div class="d-flex justify-content-center align-items-center text-primary">
        <b-spinner class="mr-2 mb-0" small></b-spinner>
        <h6 class="font-weight-semibold mb-0">Loading...</h6>
      </div>
    </div>
  </div>

  <template v-else>
    <table class="table table-bordered table-sm patient-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Tanggal/Jam</th>
          <th>Skor/Intervensi</th>
          <th>Perawat</th>
          <th>Status</th>
          <th style="width:15%">Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(v,k) in (dataKajian||[])" :key="k" :class="v.arankpkn_is_parent == 'Y' ? 'table-info' : ''">
          <td>
            <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{k+1}}</span>
          </td>
          <td>{{v.arankpkn_date | moment('DD MMMM YYYY')}}, {{v.arankpkn_jam}}
            <span v-if="v.arankpkn_is_parent == 'Y'" class="badge bg-success ml-2">Assesmen Awal </span>
            <span v-if="v.arankpkn_is_parent != 'Y'" class="badge bg-success ml-2">Monitoring </span>
            <p class="mt-1" style="font-size: 11px;" v-if="v.arankpkn_updated_date"> <b>Terakhir Diupdate pada : </b> <br />{{v.arankpkn_updated_date | moment('DD MMMM YYYY HH:mm:ss')}} </p>
          </td>
          <td>
            <span v-if="v.arankpkn_intervensi">
              {{v.arankpkn_skor||0}} /
              {{v.arankpkn_intervensi}}
            </span>
            <span v-else> - </span>
          </td>
          <td>{{v.name_petugas||"-"}}</td>
          <td>
            <span v-if="v.arankpkn_is_draft == 'Y' && !v.arankpkn_status" class="badge bg-warning">Draft </span>
            <template v-else>
              <span v-if="v.arankpkn_status == 'DONE'" class="badge bg-success">Selesai Monitoring</span>
              <span v-else class="badge bg-info">Dalam Monitoring </span>
            </template>
          </td>
          <td>
            <a href="javascript:;" @click="toModal(v,'xl','Detail Kajian Nyeri','Detail')" v-if="v.arankpkn_is_draft != 'Y'" class="btn btn-icon ml-1 mb-1 alpha-blue border-blue" v-b-tooltip.hover title="Lihat Kajian Nyeri"><i class="icon-file-eye"></i></a>
          </td>
        </tr>
        <tr v-if="!(dataKajian||[]).length">
          <td colspan="99" class="text-center">Data Tidak ada</td>
        </tr>
      </tbody>
    </table>

    <b-modal v-model="openModal" :size="modalConfig.size" no-close-on-backdrop hide-footer :title="modalConfig.title">
      <Detail v-if="isModal == 'Detail'" />
    </b-modal>
  </template>
</div>
</template>

<script>
import Gen from '@/libs/Gen.js'
import Detail from '../../../RanapPasienKajianNyeri/Detail.vue'

const _ = global._

export default {
  name: "Nyeri",
  components: {
    Detail
  },

  data() {
    return {
      dataKajian: [],
      rowEdit: {},
      rowReg: {},
      row: {},
      rowData: {},

      isModal: '',
      modalConfig: {
        title: '',
        size: 'sm'
      },
      openModal: false,

      loading: {
        coreData: true,
      }
    }
  },

  props: {
    regId: Number,
  },

  mounted(){
    this.getData()
  },

  methods: {
    getData() {
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/" + `RanapPasienKajianNyeri/${this.regId}`, {}
      ).then(res => {
        this.$set(this.loading, 'coreData', false)
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      }).catch(() => {
        this.$set(this.loading, 'coreData', false)
      })
    },

    toModal(row, size, title, isModal) {
      this.rowEdit = row
      this.modalConfig = {
        title: title,
        size: size
      }
      this.isModal = isModal
      this.openModal = true
    },
  }
}
</script>
