<template>
<div>
  <div class="row p-3" v-if="loading.coreData">
    <div class="col-md-12">
      <div class="d-flex justify-content-center align-items-center text-primary">
        <b-spinner class="mr-2 mb-0" small></b-spinner>
        <h6 class="font-weight-semibold mb-0">Loading...</h6>
      </div>
    </div>
  </div>

  <template v-else> 
    <table class="table table-bordered table-sm patient-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Tanggal/Jam</th>
          <th>Perawat</th>
          <th>Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(v, k) in tData.data" :key="`datas-${k}`">
          <td>
            <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{ k+1 }}</span>
          </td>
          <td>
            {{ (v.updated_date||v.created_date) | moment('LLL') }}

            <p class="mb-0 mt-2" v-if="v.is_draft == 'Y'">
              <b-badge variant="danger">Draft</b-badge>
            </p>
          </td>
          <td>{{ v.nama_petugas }}</td>
          <td>
            <a href="javascript:;" @click="openDetail(v)" v-if="v.is_draft == 'N'" class="btn btn-icon alpha-blue border-blue mr-2" v-b-tooltip.hover title="Lihat Kajian Terminal"><i class="icon-file-eye"></i></a>
            <a href="javascript:;" @click="downloadKajian(v)" v-if="v.is_draft == 'N'" class="btn btn-icon alpha-blue border-blue mr-2" v-b-tooltip.hover title="Download Kajian Terminal"><i class="icon-download"></i></a>
          </td>
        </tr>

        <tr v-if="tData.page.total_data < 1">
          <td colspan="10">
            <h5 class="mb-0 text-center">Belum ada data monitoring</h5>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end" v-if="tData.page.total_data > tData.page.per_page">
      <b-pagination class="mb-0" v-model="pageNoTData" :per-page="tData.page.per_page" :total-rows="tData.page.total_data" />
      </div>

    <b-modal id="detail" size="xl" hide-footer title="Form Terminal">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">1. Gejala seperti mau muntah dan kesulitan bernafas
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Kegawatan Pernafasan</h6>
                    </div>
                    <div class="card-body">
                      <div class="det-check-item mt-3" v-for="(v, k) in dataModal.kegawatan_pernafasan" :key="`mg_${k}`">
                        <i class="icon-checkmark-circle text-success"></i>
                        <div class="d-flex flex-column">
                          <span>{{v.label||"-"}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Kehilangan tonus otot</h6>
                    </div>
                    <div class="card-body">
                      <div class="form-group mt-3">
                        <div class="det-check-item" v-for="(v, k) in dataModal.kehilangan_tonus_otot" :key="`mg_${k}`">
                          <i class="icon-checkmark-circle text-success"></i>
                          <div class="d-flex flex-column">
                            <span>{{v.label||"-"}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Nyeri</h6>
                    </div>
                    <div class="card-body">
                      <div class="form-group mt-3">
                        <span class="text-capitalize">
                          {{ dataModal.nyeri == 'Y' ? "Ya" : "Tidak" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Perlambatan sirkulasi</h6>
                    </div>
                    <div class="card-body">
                      <div class="det-check-item mt-3" v-for="(v, k) in dataModal.perlambatan_sirkulasi" :key="`mg_${k}`">
                        <i class="icon-checkmark-circle text-success"></i>
                        <div class="d-flex flex-column">
                          <span>{{v.label||"-"}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Faktor yang meningkatkan dan membangkitkan gejala
                fisik</h5>
            </div>
            <div class="card-body">
              <div class="form-group mt-3">
                <div class="det-check-item" v-for="(v, k) in dataModal.faktor_gejala_fisik" :key="`fgf_${k}`">
                  <i class="icon-checkmark-circle text-success"></i>
                  <div class="d-flex flex-column">
                    <span>{{v.label||"-"}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Manajemen gejala saat ini dan respon pasien</h5>
            </div>
            <div class="card-body">
              <label>Masalah perawatan</label>
              <div class="det-check-item" v-for="(v, k) in dataModal.manajemen_gejala" :key="`mg_${k}`">
                <i class="icon-checkmark-circle text-success"></i>
                <div class="d-flex flex-column">
                  <span>{{v.label||"-"}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Orientasi spiritual pasien dan keluarga</h5>
            </div>
            <div class="card-body">
              <label>Apakah perlu pelayanan spiritual?</label>
              <div class="d-flex">
                <span class="text-capitalize">{{ dataModal.orientasi_spiritual == 'Y' ? "Ya" : "Tidak" }}</span>

                <div class="ml-2" v-if="dataModal.orientasi_spiritual == 'Y'">
                  , oleh {{ dataModal.orientasi_spiritual_note }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Unsur dan kebutuhan spiritual pasien dan keluarga
                seperti putus asa, penderitaan, rasa bersalah, atau pengampunan</h5>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <tr>
                    <td>Perlu didoakan</td>
                    <td>
                      <p class="mb-0 mt-2">
                        {{ dataModal.need_to_pray == 'Y' ? "Ya" : "Tidak" }} {{ dataModal.need_to_pray_note ? `, ${dataModal.need_to_pray_note}` : '' }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Perlu bimbingan rohani</td>
                    <td>
                      <p class="mb-0 mt-2">
                        {{ dataModal.need_spiritual_guide == 'Y' ? "Ya" : "Tidak" }} {{ dataModal.need_spiritual_guide_note ? `, ${dataModal.need_spiritual_guide_note}` : '' }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Perlu pendampingan rohani</td>
                    <td>
                      <p class="mb-0 mt-2">
                        {{ dataModal.need_spiritual_assistance == 'Y' ? "Ya" : "Tidak" }} {{ dataModal.need_spiritual_assistance_note ? `, ${dataModal.need_spiritual_assistance_note}` : '' }}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Status psikososial pasien dan keluarga
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Apakah ada orang yang ingin dihubungi
                        saat ini?</h6>
                    </div>
                    <div class="card-body">
                      <div class="form-group mt-3">
                        <div class="d-flex">
                          <span>{{ dataModal.contact_person == 'Y' ? "Ya" : "Tidak" }}</span>

                          <template v-if="dataModal.contact_person == 'Y'">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Siapa</th>
                                    <th>Dimana</th>
                                    <th>Hubungan</th>
                                    <th>No. Selular</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{{ dataModal.contact_person_name }}</td>
                                    <td>{{ dataModal.contact_person_location }}</td>
                                    <td>{{ dataModal.contact_person_relation }}</td>
                                    <td>{{ dataModal.contact_person_phone }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Bagaimana rencana perawatan selanjutnya?
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="form-group mt-3">
                        <div class="d-flex">
                          {{ objMrNextCarePlan[dataModal.next_care_plan] }}

                          <template v-if="dataModal.next_care_plan == 'DIRAWAT_DI_RUMAH'">
                            <div class="table-responsive mr-3">
                              <table class="table">
                                <tr>
                                  <td>
                                    <span>Apakah lingkungan rumah disiapkan?</span>

                                    <p class="mb-0 mt-2">{{ dataModal.is_environment_prepared == 'Y' ? 'Ya' : 'Tidak' }}</p>
                                  </td>

                                  <td v-if="dataModal.is_environment_prepared == 'Y'">
                                    <span>Apakah ada yang mampu merawat pasien di rumah?</span>

                                    <p class="mb-0 mt-2">{{ dataModal.is_any_people_care == 'Y' ? 'Ya' : 'Tidak' }}</p>
                                  </td>

                                  <td v-if="dataModal.is_environment_prepared == 'Y'">
                                    <span>Apakah perlu difasilitasi RS (Home Care)?</span>

                                    <p class="mb-0 mt-2">{{ dataModal.is_need_home_care == 'Y' ? 'Ya' : 'Tidak' }}</p>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </template>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Reaksi pasien atas penyakitnya</h6>
                    </div>
                    <div class="card-body">
                      <span class="d-block mt-3">Asesmen Informasi</span>
                      <div class="mt-2">
                        <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_pasien_asesmen" :key="`mg_${k}`">
                          <i class="icon-checkmark-circle text-success"></i>
                          <div class="d-flex flex-column">
                            <span>{{v.label||"-"}}</span>
                          </div>
                        </div>
                      </div>

                      <span class="mt-3 d-block">Masalah Keperawatan</span>
                      <div class="mt-2">
                        <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_pasien_keperawatan" :key="`mg_${k}`">
                          <i class="icon-checkmark-circle text-success"></i>
                          <div class="d-flex flex-column">
                            <span>{{v.label||"-"}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Reaksi keluarga atas penyakitnya</h6>
                    </div>
                    <div class="card-body">
                      <span class="d-block mt-3">Asesmen Informasi</span>
                      <div class="mt-2">
                        <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_keluarga_asesmen" :key="`mg_${k}`">
                          <i class="icon-checkmark-circle text-success"></i>
                          <div class="d-flex flex-column">
                            <span>{{v.label||"-"}}</span>
                          </div>
                        </div>
                      </div>

                      <span class="mt-3 d-block">Masalah Keperawatan</span>
                      <div class="mt-2">
                        <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_keluarga_keperawatan" :key="`mg_${k}`">
                          <i class="icon-checkmark-circle text-success"></i>
                          <div class="d-flex flex-column">
                            <span>{{v.label||"-"}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Kebutuhan dukungan atau kelonggaran pelayanan
                bagi pasien dan keluarga</h5>
            </div>
            <div class="card-body">
              <div class="mt-2">
                <div class="det-check-item" v-for="(v, k) in dataModal.kebutuhan_dukungan" :key="`kd_${k}`">
                  <i class="icon-checkmark-circle text-success"></i>
                  <div class="d-flex flex-column">
                    <span>{{v.label||"-"}}</span> <span v-if="v.value == 'OTHER'">, {{ v.note }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Kebutuhan alternatif pelayanan bagi pasien dan
                keluarga</h5>
            </div>
            <div class="card-body">
              <div class="mt-2">
                <div class="det-check-item" v-for="(v, k) in dataModal.kebutuhan_alternatif" :key="`mg_${k}`">
                  <i class="icon-checkmark-circle text-success"></i>
                  <div class="d-flex flex-column">
                    <span>{{v.label||"-"}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-info">
              <h5 class="card-title font-weight-semibold">Faktor resiko bagi keluarga yang ditinggalkan
              </h5>
            </div>
            <div class="card-body">
              <span class="d-block mt-3">Asesmen Informasi</span>
              <div class="mt-2">
                <div class="det-check-item" v-for="(v, k) in dataModal.resiko_keluarga_asesmen" :key="`mg_${k}`">
                  <i class="icon-checkmark-circle text-success"></i>
                  <div class="d-flex flex-column">
                    <span>{{v.label||"-"}}</span>
                  </div>
                </div>
              </div>

              <span class="mt-3 d-block">Masalah Keperawatan</span>
              <div class="mt-2">
                <div class="det-check-item" v-for="(v, k) in dataModal.resiko_keluarga_keperawatan" :key="`mg_${k}`">
                  <i class="icon-checkmark-circle text-success"></i>
                  <div class="d-flex flex-column">
                    <span>{{v.label||"-"}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </template>
</div>
</template>

<script>
import Gen from "@/libs/Gen"

const _ = global._

export default {
  name: "Terminal",

  props: {
    regId: Number
  },

  data(){
    return {
      dataModal: {},
      mrNextCarePlan: [],

      tData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },

      pageNoTData: 1,

      loading: {
        coreData: false
      }
    }
  },

  mounted(){
    this.getList()
  },

  methods: {
    openDetail(data) {      
      this.$set(this, 'dataModal', data)
      this.$bvModal.show('detail')

      this.getForm()
    },

     getList() {
      this.$set(this.loading, 'coreData', true)
      Gen.apiRest(
        "/get/" + "RanapPasienKajianTerminal", {
          params: {
            type: "get-list",
            id_registrasi: this.regId
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this.loading, 'coreData', false)
      })
    },

    getForm() {
      Gen.apiRest(
        "/get/" + "RanappasienKajianTerminal", {
          params: {
            type: "get-form",
            id_registrasi: this.regId,
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    }
  },

  computed: {
    objMrNextCarePlan(){
      return this.mrNextCarePlan.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    }
  }
}
</script>
