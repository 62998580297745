<template>
<div class="card">
  <div class="card-header bg-white">
    <h6 class="card-title font-weight-semibold">Tambah Monitoring MEOWS</h6>
  </div>

  <validation-observer ref="VFormMEOWS">
    <b-form @submit.prevent="doSubmit()">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label>Tekanan Darah<small class="txt_mandatory">*</small></label>
                  <div class="input-group">
                    <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="formData.sistolik" :formatter="Formatter.number" />
                    <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                    <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="formData.diastolik" :formatter="Formatter.number" />
                    <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                  </div>

                  <VValidate name="Tekanan Darah" :value="formData.sistolik && formData.diastolik" :rules="mrValidation.sistolik" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Nadi<small class="txt_mandatory">*</small></label>
                  <div class="input-group">
                    <b-form-input type="text" class="form-control" v-model="formData.nadi" :formatter="Formatter.number" />

                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                    <div class="input-group-append">
                      <v-select :options="MonitoringOptions.mrNadi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="formData.nadi_type" />
                    </div>
                  </div>

                  <VValidate name="Nadi" :value="formData.nadi && formData.nadi_type" :rules="mrValidation.nadi" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Pernafasan<small class="txt_mandatory">*</small></label>
                  <div class="input-group">
                    <b-form-input type="text" class="form-control" v-model="formData.pernafasan" :formatter="Formatter.number" />
                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                  </div>

                  <VValidate name="Pernafasan" v-model="formData.pernafasan" :rules="mrValidation.pernafasan" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>SPO2<small class="txt_mandatory">*</small></label>
                  <div class="input-group">
                    <b-form-input type="text" class="form-control" v-model="formData.spo2" :formatter="Formatter.decimalNumber" />
                    <div class="input-group-append"><span class="input-group-text">%</span>
                    </div>
                  </div>

                  <VValidate name="SPO2" v-model="formData.spo2" :rules="mrValidation.spo2" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Suplemen O2<small class="txt_mandatory">*</small></label>
                  <div class="input-group">
                    <b-form-input type="text" class="form-control" v-model="formData.suplemen_o2" :formatter="Formatter.number" />
                    <div class="input-group-append"><span class="input-group-text">L/mnt</span>
                    </div>
                  </div>

                  <VValidate name="Suplemen O2" v-model="formData.suplemen_o2" :rules="mrValidation.suplemen_o2" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Suhu<small class="txt_mandatory">*</small></label>
                  <div class="input-group">
                    <b-form-input type="text" class="form-control" v-model="formData.suhu" :formatter="Formatter.decimalNumber" />
                    <div class="input-group-append"><span class="input-group-text">&deg;C</span>
                    </div>
                  </div>

                  <VValidate name="Suhu" v-model="formData.suhu" :rules="mrValidation.suhu" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Kesadaran<small class="txt_mandatory">*</small></label>
                  <v-select :options="optionsMEOWS.mrKesadaran" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" v-model="formData.kesadaran" />

                  <VValidate name="Kesadaran" v-model="formData.kesadaran" :rules="mrValidation.kesadaran" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Nyeri<small class="txt_mandatory">*</small></label>
                  <v-select :options="optionsMEOWS.mrNyeri" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" v-model="formData.nyeri" />

                  <VValidate name="Nyeri" v-model="formData.nyeri" :rules="mrValidation.nyeri" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Discharge / Lachia<small class="txt_mandatory">*</small></label>
                  <v-select :options="optionsMEOWS.mrNyeri" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" v-model="formData.discharge" />

                  <VValidate name="Discharge / Lachia" v-model="formData.discharge" :rules="mrValidation.discharge" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Proteinuria<small class="txt_mandatory">*</small></label>
                  <v-select :options="optionsMEOWS.mrProteinuria" placeholder="-- Pilih --" v-model="formData.proteinuria" />

                  <VValidate name="Proteinuria" v-model="formData.proteinuria" :rules="mrValidation.proteinuria" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Berat Badan<small class="txt_mandatory">*</small></label>
                  <div class="input-group">
                    <b-form-input type="text" class="form-control" v-model="formData.berat_badan" :formatter="Formatter.decimalNumber" />
                    <div class="input-group-append"><span class="input-group-text">kg</span>
                    </div>
                  </div>

                  <VValidate name="Berat Badan" v-model="formData.berat_badan" :rules="mrValidation.berat_badan" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>GDA</label>
                  <div class="input-group">
                    <b-form-input type="text" class="form-control" v-model="formData.gda" :formatter="Formatter.decimalNumber" />
                    <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="formData.gda">
                <div class="form-group">
                  <label>Terapi <span class="text-danger">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="formData.terapi" />

                  <VValidate name="Terapi" v-model="formData.terapi" rules="required" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8" v-if="historyData.page.total_data > 0">
            <div class="table-responsive">
              <table class="table table-bordered table-compact">
                <thead>
                  <tr>
                    <th>Waktu</th>
                    <th>Total Skor</th>
                    <th>Pernafasan</th>
                    <th>SpO2</th>
                    <th>Sup. O2</th>
                    <th>Tek. Darah</th>
                    <th>Nadi</th>
                    <th>Kesadaran</th>
                    <th>Suhu</th>
                    <th>Nyeri</th>
                    <th>Discharge</th>
                    <th>Proteinuria</th>
                    <th>Berat Badan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in historyData.data" :key="k">
                    <td>{{ v.armm_created_date | moment("LLL") }}</td>
                    <td :class="{
                        'bg-danger': v.armm_score_meows >= 5.75,
                        'bg-orange': v.armm_score_meows >= 4.75 && v.armm_score_meows < 5.75,
                        'triase_yellow': v.armm_score_meows < 4.75
                      }">
                      <strong class="text-white">
                        {{ v.armm_score_meows }}
                      </strong>
                    </td>
                    <td>{{ v.armm_pernafasan }}x/mnt</td>
                    <td>{{ v.armm_spo2 }}%</td>
                    <td>{{ v.armm_suplemen_o2 }}L/mnt</td>
                    <td>{{ v.armm_sistolik }}/{{ v.armm_diastolik }}mmHG</td>
                    <td>{{ v.armm_nadi }}x/mnt</td>
                    <td>{{ objMrKesadaran[v.armm_kesadaran] }}</td>
                    <td>{{ v.armm_suhu }}°C</td>
                    <td>{{ objMrNyeri[v.armm_nyeri] }}</td>
                    <td>{{ objMrDischarge[v.armm_discharge] }}</td>
                    <td>{{ v.armm_proteinuria }}</td>
                    <td>{{ v.armm_berat_badan }}kg</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-end" v-if="historyData.page.total_data > historyData.page.per_page">
              <b-pagination class="mb-0" v-model="$parent.pageNoTData" :per-page="historyData.page.per_page" :total-rows="historyData.page.total_data" />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <a href="javascript:;" @click="$parent.$emit('changeViewType', 'VIEW')" class="btn">Kembali</a>
          <button type="submit" class="btn btn-labeled btn-labeled-left btn-success">
            <b><i class="icon-checkmark"></i></b>
            <span>Simpan</span>
          </button>
        </div>
      </div>
    </b-form>
  </validation-observer>
</div>
</template>

<script>
import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'

export default {
  name: "FormData",

  data() {
    return {
    }
  },

  methods: {
    doSubmit() {
      this.$refs['VFormMEOWS'].validate().then(success => {
        if (!success) return false

        this.$parent.doSubmitMEOWS({
          ...this.formData,
          type: 'add'
        })
      })
    }
  },

  computed: {
    optionsMEOWS() {
      return MonitoringOptions.MEOWS
    },
    MonitoringOptions() {
      return MonitoringOptions
    },

    Formatter() {
      return Formatter
    },

    historyData() {
      return this.$parent.tHistoryData
    },

    objMrKesadaran() {
      return this.optionsMEOWS.mrKesadaran.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    objMrNyeri() {
      return this.optionsMEOWS.mrNyeri.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    objMrDischarge() {
      return this.optionsMEOWS.mrDischarge.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    mrValidation() {
      return this.$parent.mrValidation
    },

    formData() {
      return this.$parent.formData
    },
  }
}
</script>
