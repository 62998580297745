<template>
<div class="card">
  <div class="card-header bg-white">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="card-title font-weight-semibold">Tambah Monitoring Intensif</h6>
      <b-button data-toggle="modal" variant="info" @click="openHistory()" class="btn-labeled btn-labeled-left">
        <b><i class="icon-history"></i></b>
        <span>Lihat Data Monitoring</span>
      </b-button>
    </div>
  </div>

  <validation-observer ref="VFormIntensive">
    <b-form @submit.prevent="doSubmit()">
      <div class="card-body">
        <div class="form-group">
          <label>Jam Pengisian</label>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
            <vue-timepicker manual-input v-model="formData.filling_time" format="HH:mm" input-class="form-control"></vue-timepicker>
          </div>
        </div>
        <div class="wrap_line">
          <h3>Tanda-tanda Vital</h3>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Tekanan Darah</label>
                <div class="input-group">
                  <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="formData.sistolik" :formatter="Formatter.number" />
                  <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                  <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="formData.diastolik" :formatter="Formatter.number" />
                  <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Nadi</label>
                <div class="input-group">
                  <b-form-input type="text" class="form-control" v-model="formData.nadi" :formatter="Formatter.number" />

                  <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                  <div class="input-group-append">
                    <v-select :options="MonitoringOptions.mrNadi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="formData.nadi_type" />
                  </div>
                </div>

                <!-- <VValidate name="Nadi" :value="formData.nadi && formData.nadi_type" :rules="mrValidation.nadi" /> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Pernafasan</label>
                <div class="input-group">
                  <b-form-input type="text" class="form-control" v-model="formData.pernafasan" :formatter="Formatter.number" />
                  <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                </div>

                <!-- <VValidate name="Pernafasan" v-model="formData.pernafasan" :rules="mrValidation.pernafasan" /> -->
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>SPO2</label>
                <div class="input-group">
                  <b-form-input type="text" class="form-control" v-model="formData.spo2" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">%</span>
                  </div>
                </div>

                <!-- <VValidate name="SPO2" v-model="formData.spo2" :rules="mrValidation.spo2" /> -->
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Suhu</label>
                <div class="input-group">
                  <b-form-input type="text" class="form-control" v-model="formData.suhu" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">&deg;C</span>
                  </div>
                </div>

                <!-- <VValidate name="Suhu" v-model="formData.suhu" :rules="mrValidation.suhu" /> -->
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>GDA</label>
                <div class="input-group">
                  <b-form-input type="text" class="form-control" v-model="formData.gda" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2" v-if="formData.gda">
              <div class="form-group">
                <label>Terapi</label>
                <b-form-input type="text" class="form-control" v-model="formData.terapi" />

                <!-- <VValidate 
                  name="Terapi"
                  v-model="formData.terapi"
                  rules="required"
                /> -->
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Berat Badan</label>
                <div class="input-group">
                  <b-form-input type="text" class="form-control" v-model="formData.berat_badan" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">kg</span>
                  </div>
                </div>

                <!-- <VValidate name="Berat Badan" v-model="formData.berat_badan" :rules="mrValidation.berat_badan" /> -->
              </div>
            </div>
          </div>
          <div>
            <label for="crtIntensif">CRT</label>
            <table class="table table-bordered table-sm">
              <thead>
                <tr>
                  <th colspan="2">Tangan</th>
                  <th colspan="2">Kaki</th>
                </tr>
                <tr>
                  <th>Kanan</th>
                  <th>Kiri</th>
                  <th>Kanan</th>
                  <th>Kiri</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b-form-radio-group v-model="formData.crt_tangan_kanan" :options="$parent.mrCRT" value-field="value" text-field="label"></b-form-radio-group>

                    <VValidate name="CRT Tangan Kanan" v-model="formData.crt_tangan_kanan" :rules="mrValidation.crt_tangan_kanan" />
                  </td>
                  <td>
                    <b-form-radio-group v-model="formData.crt_tangan_kiri" :options="$parent.mrCRT" value-field="value" text-field="label"></b-form-radio-group>

                    <VValidate name="CRT Tangan Kiri" v-model="formData.crt_tangan_kiri" :rules="mrValidation.crt_tangan_kiri" />
                  </td>
                  <td>
                    <b-form-radio-group v-model="formData.crt_kaki_kanan" :options="$parent.mrCRT" value-field="value" text-field="label"></b-form-radio-group>

                    <VValidate name="CRT Kaki Kanan" v-model="formData.crt_kaki_kanan" :rules="mrValidation.crt_kaki_kanan" />
                  </td>
                  <td>
                    <b-form-radio-group v-model="formData.crt_kaki_kiri" :options="$parent.mrCRT" value-field="value" text-field="label"></b-form-radio-group>

                    <VValidate name="CRT Kaki Kiri" v-model="formData.crt_kaki_kiri" :rules="mrValidation.crt_kaki_kiri" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="wrap_line">
          <h3>Ventilasi</h3>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="ventTipeVent">Tipe Ventilasi</label>
                <b-row>
                  <b-col :md="formData.ventilasi_type == 'Lainnya' ? 4 : 12">
                    <v-select :options="$parent.mrVentilasiType" v-model="formData.ventilasi_type" placeholder="-- Pilih Tipe Ventilasi --"></v-select>
                  </b-col>
                  <b-col md="8" v-if="formData.ventilasi_type == 'Lainnya'">
                    <b-form-input v-model="formData.ventilasi_type_other" placeholder="Tipe Ventilasi" />
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="ventFio2">Fio</label>
                <b-form-input v-model="formData.fio" placeholder="Fio2" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="ventPernafasan">RR</label>
                <div class="input-group">
                  <b-form-input v-model="formData.ventilasi_pernafasan" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label for="ventPeak">P. Peak</label>

                <div class="input-group">
                  <b-form-input v-model="formData.p_peak" type="tel" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">cmH<sub>2</sub>O</span></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="ventPeep">PEEP</label>

                <div class="input-group">
                  <b-form-input v-model="formData.peep" type="tel" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">cmH<sub>2</sub>O</span></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="ventMenitVolumen">Menit Volume</label>
                <b-form-input v-model="formData.menit_volume" type="tel" :formatter="Formatter.decimalNumber" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label for="ventMenitVolumen">Observasi ETCO2</label>
                <div class="input-group">
                  <b-form-input v-model="formData.observasi_etco2" type="tel" :formatter="Formatter.decimalNumber" />
                  <div class="input-group-append"><span class="input-group-text">mmHg</span></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label for="ventMenitVolumen">Observasi I : E</label>
                <b-form-input v-model="formData.observasi_ie" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="wrap_line">
          <h3>Neuro</h3>
          <div class="row g-2">
            <div class="col-lg-7">
              <div>
                <label for="">Mata</label>
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th rowspan="2">#</th>
                      <th colspan="2">Mata</th>
                    </tr>
                    <tr>
                      <th style="width: 250px;">Kanan</th>
                      <th style="width: 250px;">Kiri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="table-secondary"><span class="font-weight-semibold">Ukuran Pupil</span></th>
                      <td>
                        <div class="input-group">
                          <b-form-input type="number" placeholder="1-10" min="1" max="10" :formatter="Formatter.number" v-model="formData.pupil_mata_kanan" />
                          <div class="input-group-append">
                            <span class="input-group-text">mm</span>
                          </div>
                        </div>

                        <VValidate name="Pupil Mata Kanan" v-model="formData.pupil_mata_kanan" :rules="mrValidation.pupil_mata_kanan" />
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input type="number" placeholder="1-10" min="1" max="10" :formatter="Formatter.number" v-model="formData.pupil_mata_kiri" />
                          <div class="input-group-append">
                            <span class="input-group-text">mm</span>
                          </div>
                        </div>

                        <VValidate name="Pupil Mata Kiri" v-model="formData.pupil_mata_kiri" :rules="mrValidation.pupil_mata_kiri" />
                      </td>
                    </tr>
                    <tr>
                      <th class="table-secondary"><span class="font-weight-semibold">Reaksi Cahaya</span></th>
                      <td>
                        <b-form-group>
                          <b-form-radio-group v-model="formData.rc_mata_kanan">
                            <b-form-radio :value="v" v-for="(v, k) in $parent.mrReaksiCahaya" :key="k">{{ v }}</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </td>
                      <td>
                        <b-form-group>
                          <b-form-radio-group v-model="formData.rc_mata_kiri">
                            <b-form-radio :value="v" v-for="(v, k) in $parent.mrReaksiCahaya" :key="k">{{ v }}</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12">
              <div>
                <label for="crtIntensif">Kekuatan Otot</label>
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th colspan="2">Tangan</th>
                      <th colspan="2">Kaki</th>
                    </tr>
                    <tr>
                      <th style="width: 296px;">Kanan</th>
                      <th style="width: 296px;">Kiri</th>
                      <th style="width: 296px;">Kanan</th>
                      <th style="width: 296px;">Kiri</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b-form-input type="number" min="1" max="5" :formatter="Formatter.number" placeholder="1-5" v-model="formData.ko_tangan_kanan" />

                        <VValidate name="Kekuatan Otot Tangan Kanan" v-model="formData.ko_tangan_kanan" :rules="mrValidation.ko_tangan_kanan" />
                      </td>
                      <td>
                        <b-form-input type="number" min="1" max="5" :formatter="Formatter.number" placeholder="1-5" v-model="formData.ko_tangan_kiri" />

                        <VValidate name="Kekuatan Otot Tangan Kiri" v-model="formData.ko_tangan_kiri" :rules="mrValidation.ko_tangan_kiri" />
                      </td>
                      <td>
                        <b-form-input type="number" min="1" max="5" :formatter="Formatter.number" placeholder="1-5" v-model="formData.ko_kaki_kanan" />

                        <VValidate name="Kekuatan Otot Kaki Kanan" v-model="formData.ko_kaki_kanan" :rules="mrValidation.ko_kaki_kanan" />
                      </td>
                      <td>
                        <b-form-input type="number" min="1" max="5" :formatter="Formatter.number" placeholder="1-5" v-model="formData.ko_kaki_kiri" />

                        <VValidate name="Kekuatan Otot Kaki Kiri" v-model="formData.ko_kaki_kiri" :rules="mrValidation.ko_kaki_kiri" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12">
              <div>
                <label for="gcsIntensif">Tingkat Kesadaran (GCS)</label>
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Pengkajian</th>
                      <th>Nilai</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in $parent.mrGCS.responseMata" :key="k">
                      <td class="table-warning" :rowspan="$parent.mrGCS.responseMata.length + 1" v-if="k === 0">
                        <span class="font-weight-semibold">Respons Mata</span>
                      </td>
                      <td>
                        <b-form-radio v-model="formData.gcs_response_mata" :value="v.value" @change="setScoreGCS">
                          <template v-if="v.gcs_is_other">
                            <template v-if="v.is_form">
                              <div class="d-flex w-100">
                                <b-form-input v-model="v.label" placeholder="cth: Tidak Dapat Membuka Mata Sama Sekali Terhadap Rangsangan Apapun"></b-form-input>

                                <div class="ml-3 mr-3">
                                  <b-form-input type="number" v-model="v.value" placeholder="cth: 3"></b-form-input>
                                  <small class="text-muted">*) Kosongkan jika tidak terdefinisi</small>
                                </div>

                                <div class="d-flex button-action-gcs">
                                  <b-button variant="outline-success" class="mr-2" v-b-tooltip.hover title="Konfirmasi" round @click="confirmOtherGCS($parent.mrGCS.responseMata, k)">
                                    <i class="icon-check"></i>
                                  </b-button>
                                  <b-button variant="outline-danger" @click="deleteOtherGCS($parent.mrGCS.responseMata, k)" v-b-tooltip.hover title="Hapus" round>
                                    <i class="icon-trash"></i>
                                  </b-button>
                                </div>
                              </div>

                            </template>
                            <template v-else>
                              <div class="d-flex">
                                <div>
                                  <strong>[LAINNYA]</strong> {{ v.label }} <span class="text-blue-800 font-weight-semibold">({{ (v.value||"Tidak Terdefinisi").replace(prefixCustomGCS, '') }})</span>
                                </div>


                                <div class="d-flex button-action-gcs ml-3">
                                  <b-button variant="outline-info" class="mr-2" v-b-tooltip.hover title="Ubah Informasi" round @click="editOtherGCS($parent.mrGCS.responseMata, k)">
                                    <i class="icon-pen"></i>
                                  </b-button>
                                  <b-button variant="outline-danger" @click="deleteOtherGCS($parent.mrGCS.responseMata, k)" v-b-tooltip.hover title="Hapus" round>
                                    <i class="icon-trash"></i>
                                  </b-button>
                                </div>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            {{ v.label }} <span class="text-blue-800 font-weight-semibold">({{ v.value }})</span>
                          </template>
                        </b-form-radio>
                      </td>
                    </tr>

                    <tr v-if="!haveOtherGCS($parent.mrGCS.responseMata)">
                      <td colspan="3">
                        <b-button variant="info" pill @click="addOtherGCS($parent.mrGCS.responseMata)">
                          <i class="icon-add mr-2"></i> Tambah Respons Mata Lainnya
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-for="(v, k) in $parent.mrGCS.responseVerbal" :key="k" @change="setScoreGCS">
                      <td class="table-warning" :rowspan="$parent.mrGCS.responseVerbal.length + 1" v-if="k === 0">
                        <span class="font-weight-semibold">Respons Verbal</span>
                      </td>
                      <td>
                        <b-form-radio v-model="formData.gcs_response_verbal" :value="v.value">
                          <template v-if="v.gcs_is_other">
                            <template v-if="v.is_form">
                              <div class="d-flex w-100">
                                <b-form-input v-model="v.label" placeholder="cth: Tidak Dapat Membuka Mata Sama Sekali Terhadap Rangsangan Apapun"></b-form-input>

                                <div class="ml-3 mr-3">
                                  <b-form-input type="number" v-model="v.value" placeholder="cth: 3"></b-form-input>
                                  <small class="text-muted">*) Kosongkan jika tidak terdefinisi</small>
                                </div>

                                <div class="d-flex button-action-gcs">
                                  <b-button variant="outline-success" class="mr-2" v-b-tooltip.hover title="Konfirmasi" round @click="confirmOtherGCS($parent.mrGCS.responseVerbal, k)">
                                    <i class="icon-check"></i>
                                  </b-button>
                                  <b-button variant="outline-danger" @click="deleteOtherGCS($parent.mrGCS.responseVerbal, k)" v-b-tooltip.hover title="Hapus" round>
                                    <i class="icon-trash"></i>
                                  </b-button>
                                </div>
                              </div>

                            </template>
                            <template v-else>
                              <div class="d-flex">
                                <div>
                                  <strong>[LAINNYA]</strong> {{ v.label }} <span class="text-blue-800 font-weight-semibold">({{ (v.value||"Tidak Terdefinisi").replace(prefixCustomGCS, '') }})</span>
                                </div>


                                <div class="d-flex button-action-gcs ml-3">
                                  <b-button variant="outline-info" class="mr-2" v-b-tooltip.hover title="Ubah Informasi" round @click="editOtherGCS($parent.mrGCS.responseVerbal, k)">
                                    <i class="icon-pen"></i>
                                  </b-button>
                                  <b-button variant="outline-danger" @click="deleteOtherGCS($parent.mrGCS.responseVerbal, k)" v-b-tooltip.hover title="Hapus" round>
                                    <i class="icon-trash"></i>
                                  </b-button>
                                </div>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            {{ v.label }} <span class="text-blue-800 font-weight-semibold">({{ v.value }})</span>
                          </template>
                        </b-form-radio>
                      </td>
                    </tr>

                    <tr v-if="!haveOtherGCS($parent.mrGCS.responseVerbal)">
                      <td colspan="3">
                        <b-button variant="info" pill @click="addOtherGCS($parent.mrGCS.responseVerbal)">
                          <i class="icon-add mr-2"></i> Tambah Respons Verbal Lainnya
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-for="(v, k) in $parent.mrGCS.responseMotorik" :key="k" @change="setScoreGCS">
                      <td class="table-warning" :rowspan="$parent.mrGCS.responseMotorik.length + 1" v-if="k === 0">
                        <span class="font-weight-semibold">Respons Motorik</span>
                      </td>
                      <td>                        
                        <b-form-radio v-model="formData.gcs_response_motorik" :value="v.value">
                          <template v-if="v.gcs_is_other">
                            <template v-if="v.is_form">
                              <div class="d-flex w-100">
                                <b-form-input v-model="v.label" placeholder="cth: Tidak Dapat Membuka Mata Sama Sekali Terhadap Rangsangan Apapun"></b-form-input>

                                <div class="ml-3 mr-3">
                                  <b-form-input type="number" v-model="v.value" placeholder="cth: 3"></b-form-input>
                                  <small class="text-muted">*) Kosongkan jika tidak terdefinisi</small>
                                </div>

                                <div class="d-flex button-action-gcs">
                                  <b-button variant="outline-success" class="mr-2" v-b-tooltip.hover title="Konfirmasi" round @click="confirmOtherGCS($parent.mrGCS.responseMotorik, k)">
                                    <i class="icon-check"></i>
                                  </b-button>
                                  <b-button variant="outline-danger" @click="deleteOtherGCS($parent.mrGCS.responseMotorik, k)" v-b-tooltip.hover title="Hapus" round>
                                    <i class="icon-trash"></i>
                                  </b-button>
                                </div>
                              </div>

                            </template>
                            <template v-else>
                              <div class="d-flex">
                                <div>
                                  <strong>[LAINNYA]</strong> {{ v.label }} <span class="text-blue-800 font-weight-semibold">({{ (v.value||"Tidak Terdefinisi").replace(prefixCustomGCS, '') }})</span>
                                </div>


                                <div class="d-flex button-action-gcs ml-3">
                                  <b-button variant="outline-info" class="mr-2" v-b-tooltip.hover title="Ubah Informasi" round @click="editOtherGCS($parent.mrGCS.responseMotorik, k)">
                                    <i class="icon-pen"></i>
                                  </b-button>
                                  <b-button variant="outline-danger" @click="deleteOtherGCS($parent.mrGCS.responseMotorik, k)" v-b-tooltip.hover title="Hapus" round>
                                    <i class="icon-trash"></i>
                                  </b-button>
                                </div>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            {{ v.label }} <span class="text-blue-800 font-weight-semibold">({{ v.value }})</span>
                          </template>
                        </b-form-radio>
                      </td>
                    </tr>

                    <tr v-if="!haveOtherGCS($parent.mrGCS.responseMotorik)">
                      <td colspan="3">
                        <b-button variant="info" pill @click="addOtherGCS($parent.mrGCS.responseMotorik)">
                          <i class="icon-add mr-2"></i> Tambah Respons Motorik Lainnya
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="table-info" colspan="9">
                        <template v-if="isFillCustomGCS">
                          <div class="d-flex">
                            <div class="d-flex align-items-center">
                              <span class="font-weight-semibold" style="width: 100px;">Nilai GCS: </span>
                              <b-form-input type="text" :formatter="Formatter.number" v-model="formData.score_gcs" style="width: 60px;"></b-form-input>
                            </div>

                            <div class="d-flex">
                              <span class="font-weight-semibold border-left ml-2 pl-2">
                                <b-form-input v-model="formData.gcs_category_custom" placeholder="Interpretasi"></b-form-input>
                              </span>
                            </div>
                          </div>
                        </template>

                        <template v-else> 
                          <span class="font-weight-semibold">Nilai GCS: {{ formData.score_gcs }}</span> <span class="font-weight-semibold border-left ml-2 pl-2">{{ categoryGCS(formData.score_gcs) }}</span>
                        </template>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap_line">
          <h3>Fototerapi</h3>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="fttUmum">Keadaan Umum</label>
                <v-select :options="$parent.mrKeadaanUmum" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" v-model="formData.keadaan_umum" :clearable="true" />

                <VValidate name="Keadaan Umum" v-model="formData.keadaan_umum" :rules="mrValidation.keadaan_umum" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="fttGerakan">Gerakan</label>
                <v-select :options="$parent.mrGerakan" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" v-model="formData.gerakan" :clearable="true" />

                <VValidate name="Gerakan" v-model="formData.gerakan" :rules="mrValidation.gerakan" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="fttMinum">Minum</label>
                <div class="input-group">
                  <b-form-input type="number" v-model="formData.minum" :formatter="Formatter.number" />
                  <div class="input-group-append"><span class="input-group-text">cc</span>
                  </div>
                </div>

                <VValidate name="Minum" v-model="formData.minum" :rules="mrValidation.minum" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="fttBak">BAK</label>
                <div class="input-group">
                  <b-form-input type="number" v-model="formData.bak" :formatter="Formatter.number" />
                  <div class="input-group-append"><span class="input-group-text">cc</span>
                  </div>
                </div>

                <VValidate name="BAK" v-model="formData.bak" :rules="mrValidation.bak" />
              </div>
            </div>
            <div class="col-md-3 col-lg-3">
              <div class="form-group">
                <label for="fttJamPasang">Jam Pasang</label>
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                  <vue-timepicker manual-input v-model="formData.install_time" format="HH:mm" input-class="form-control"></vue-timepicker>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-lg-3">
              <div>
                <label for="intensifJamSelesai">Jam Selesai</label>
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                  <vue-timepicker manual-input v-model="formData.finish_time" format="HH:mm" input-class="form-control"></vue-timepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <a href="javascript:;" @click="$parent.$emit('changeViewType', 'VIEW')" class="btn">Kembali</a>
          <button type="submit" class="btn btn-labeled btn-labeled-left btn-success">
            <b><i class="icon-checkmark"></i></b>
            <span>Simpan</span>
          </button>
        </div>
      </div>
    </b-form>
  </validation-observer>

  <b-modal id="historyData" size="xl" hide-footer title="Riwayat Monitoring Intensif">
    <div class="table-responsive">
      <table class="table table-bordered table-sm">
        <thead class="text-center">
          <tr>
            <th rowspan="3" style="min-width: 150px;">Waktu</th>
            <th colspan="9">Tanda Vital</th>
            <th colspan="8">Ventilasi</th>
            <th colspan="5">Neuro</th>
            <th colspan="6">Fototerapi</th>
          </tr>
          <tr>
            <!-- Tanda Vital -->
            <th rowspan="2">Tensi</th>
            <th rowspan="2">Nadi</th>
            <th rowspan="2">Pernafasan</th>
            <th rowspan="2">SpO2</th>
            <th rowspan="2">Suhu</th>
            <th rowspan="2">GDA</th>
            <th rowspan="2">Berat Badan</th>
            <th colspan="2">CRT</th>

            <!-- Ventilasi -->
            <th rowspan="2">Tipe Ventilasi</th>
            <th rowspan="2">Fio2</th>
            <th rowspan="2">RR</th>
            <th rowspan="2">P Peak</th>
            <th rowspan="2">PEEP</th>
            <th rowspan="2">Menit Volume</th>
            <th rowspan="2">Observasi ETCO2</th>
            <th rowspan="2">Observasi I : E</th>

            <!-- Neuro -->
            <th colspan="2">Mata</th>
            <th rowspan="2" style="min-width: 100px;">Tangan</th>
            <th rowspan="2" style="min-width: 100px;">Kaki</th>
            <th rowspan="2" style="min-width: 160px;">GCS</th>

            <!-- Fototerapi -->
            <th rowspan="2">Keadaan Umum</th>
            <th rowspan="2">Gerakan</th>
            <th rowspan="2">Minum</th>
            <th rowspan="2">BAK</th>
            <th rowspan="2">Jam Pasang</th>
            <th rowspan="2">Jam Selesai</th>
          </tr>
          <tr>
            <!-- CRT -->
            <th style="min-width: 150px;">Tangan</th>
            <th style="min-width: 150px;">Kaki</th>

            <!-- Neuro Mata -->
            <th style="min-width: 150px;">Ukuran Pupil</th>
            <th style="min-width: 150px;">Reaksi Cahaya</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, k) in historyData.data" :key="k">
            <td>{{ v.armi_created_date | moment("LL") }} {{ v.armi_jam_pengisian ? $parent.timeSQLtoNormal(v.armi_jam_pengisian) : '' }}</td>
            <td>{{ v.armi_sistolik || '-' }}/{{ v.armi_diastolik || '-' }}mmHg</td>
            <td>{{ v.armi_nadi }}x/mnt</td>
            <td>{{ v.armi_pernafasan }}x/mnt</td>
            <td>{{ v.armi_spo2 }}%</td>
            <td>{{ v.armi_suhu }}&deg;C</td>
            <td>{{ v.armi_gda || '-' }}mg/dL</td>
            <td>{{ v.armi_berat_badan || '-' }}kg</td>
            <td>
              <div>Kanan: {{ objMrCRT[v.armi_crt_tangan_kanan] || '-' }}</div>
              <div>Kiri: {{ objMrCRT[v.armi_crt_tangan_kiri] || '-' }}</div>
            </td>
            <td>
              <div>Kanan: {{ objMrCRT[v.armi_crt_kaki_kanan] || '-' }}</div>
              <div>Kiri: {{ objMrCRT[v.armi_crt_kaki_kiri] || '-' }}</div>
            </td>
            <td>{{ v.armi_ventilasi_type || '-' }} {{ v.armi_ventilasi_type == 'Lainnya' ? `(${v.armi_ventilasi_type_other})` : '' }}</td>
            <td>{{ v.armi_fio || '-' }}</td>
            <td>{{ v.armi_ventilasi_pernafasan || '-' }}x/mnt</td>
            <td>{{ v.armi_p_peak || '-' }}cmH<sub>2</sub>O</td>
            <td>{{ v.armi_peep || '-' }}cmH<sub>2</sub>O</td>
            <td>{{ v.armi_menit_volume || '-' }}</td>
            <td>{{ v.armi_observasi_etco2 || '-' }}mmHg</td>
            <td>{{ v.armi_observasi_ie || '-' }}</td>
            <td>
              <div>Kanan: {{ v.armi_pupil_mata_kanan || '-' }}mm</div>
              <div>Kiri: {{ v.armi_pupil_mata_kiri || '-' }}mm</div>
            </td>
            <td>
              <div>Kanan: {{ v.armi_rc_mata_kanan || '-' }}</div>
              <div>Kiri: {{ v.armi_rc_mata_kiri || '-' }}</div>
            </td>
            <td>
              <div>Kanan: {{ v.armi_ko_tangan_kanan || '-' }}</div>
              <div>Kiri: {{ v.armi_ko_tangan_kiri || '-' }}</div>
            </td>
            <td>
              <div>Kanan: {{ v.armi_ko_kaki_kanan || '-' }}</div>
              <div>Kiri: {{ v.armi_ko_kaki_kiri || '-' }}</div>
            </td>
            <td>
              <div>E: {{ (v.armi_gcs_response_mata || 'Tidak Terdefinisi').replace(prefixCustomGCS, '') }}</div>
              <div>M: {{ (v.armi_gcs_response_motorik || 'Tidak Terdefinisi').replace(prefixCustomGCS, '') }}</div>
              <div>V: {{ (v.armi_gcs_response_verbal || 'Tidak Terdefinisi').replace(prefixCustomGCS, '') }}</div>

              <div class="mt-2">
                <template v-if="v.armi_gcs_category_custom">
                  {{ v.armi_gcs_category_custom }}
                </template>
                 <template v-else>
                  {{ categoryGCS((v.armi_gcs_response_mata||0) + (v.armi_gcs_response_motorik||0) + (v.armi_gcs_response_verbal||0)) }}
                 </template>
              </div>
            </td>
            <td>{{ objMrKeadaanUmum[v.armi_keadaan_umum] || '-' }}</td>
            <td>{{ objMrGerakan[v.armi_gerakan] || '-' }}</td>
            <td>{{ v.armi_minum || '-' }}cc</td>
            <td>{{ v.armi_bak || '-' }}cc</td>
            <td>{{ v.armi_install_time ? $parent.timeSQLtoNormal(v.armi_install_time) : '-' }}</td>
            <td>{{ v.armi_finish_time ? $parent.timeSQLtoNormal(v.armi_finish_time) : '-' }}</td>
          </tr>
          <tr v-if="historyData.page.total_data < 1">
            <td colspan="25">Belum ada riwayat monitoring intensif</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-end" v-if="historyData.page.total_data > historyData.page.per_page">
      <b-pagination class="mb-0" v-model="$parent.pageNoTData" :per-page="historyData.page.per_page" :total-rows="historyData.page.total_data" />
    </div>
  </b-modal>
</div>
</template>

<script>
import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "FormData",
  components: {
    VueTimepicker
  },

  data() {
    return {
      prefixCustomGCS: 'cus_'
    }
  },

  methods: {
    openHistory() {
      this.$bvModal.show("historyData")

      this.$parent.getHistoryData(1)
    },

    categoryGCS(score) {
      if (score === 0) return '-'

      if (score === 3) {
        return this.$parent.mrGCSCategory['COMA']
      } else if (score === 4) {
        return this.$parent.mrGCSCategory['SEMI_COMA']
      } else if (score >= 5 && score <= 6) {
        return this.$parent.mrGCSCategory['SOPOR']
      } else if (score >= 7 && score <= 9) {
        return this.$parent.mrGCSCategory['SOMNOLEN']
      } else if (score >= 10 && score <= 11) {
        return this.$parent.mrGCSCategory['DELIRIUM']
      } else if (score >= 12 && score <= 13) {
        return this.$parent.mrGCSCategory['APATIS']
      } else if (score >= 14 && score <= 15) {
        return this.$parent.mrGCSCategory['COSPOSMENTIS']
      } else {
        return '-'
      }
    },

    haveOtherGCS(dataGCS){
      let index = dataGCS.findIndex(dt => dt.gcs_is_other)
      return index >= 0
    },

    addOtherGCS(dataGCS){
      dataGCS.push({ gcs_is_other: true, label: null, value: null, is_form: true  })
    },

    editOtherGCS(dataGCS, key) {
      this.$set(dataGCS[key], 'is_form', true)
      this.$set(dataGCS[key], 'value', dataGCS[key].value.replace(this.prefixCustomGCS, ""))
    },

    confirmOtherGCS(dataGCS, key) {
      this.$set(dataGCS[key], 'is_form', false)
      if(dataGCS[key].value) this.$set(dataGCS[key], 'value', `${this.prefixCustomGCS}${dataGCS[key].value}`)
    },

    deleteOtherGCS(dataGCS, key) {
      dataGCS.splice(key, 1)

      this.setScoreGCS()
    },

    setScoreGCS(){
      const {
        gcs_response_mata,
        gcs_response_motorik,
        gcs_response_verbal
      } = this.formData

      let indexResponseMata = this.$parent.mrGCS.responseMata.findIndex(dt => dt.value == gcs_response_mata && dt.gcs_is_other)
      let indexResponseVerbal = this.$parent.mrGCS.responseVerbal.findIndex(dt => dt.value == gcs_response_verbal && dt.gcs_is_other)
      let indexResponseMotorik = this.$parent.mrGCS.responseMotorik.findIndex(dt => dt.value == gcs_response_motorik && dt.gcs_is_other)

      let responseMataValue = gcs_response_mata,
          responseVerbalValue = gcs_response_verbal,
          responseMotorikValue = gcs_response_motorik

      if(indexResponseMata >= 0) responseMataValue = (gcs_response_mata||'').replace(this.prefixCustomGCS, '')
      if(indexResponseVerbal >= 0) responseVerbalValue = (gcs_response_verbal||'').replace(this.prefixCustomGCS, '')
      if(indexResponseMotorik >= 0) responseMotorikValue = (gcs_response_motorik||'').replace(this.prefixCustomGCS, '')

      const total = (+(responseMataValue) || 0) + (+(responseMotorikValue) || 0) + (+(responseVerbalValue) || 0)

      setTimeout(() => {       
        this.$set(this.formData, 'score_gcs', total)
      }, 0)
    },

    doSubmit() {
      this.$refs['VFormIntensive'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            this.$parent.doSubmitIntensive({
              ...this.formData,
              gcs_options: this.$parent.mrGCS,              
              type: 'add'
            })
          }
        })
      })
    }
  },

  computed: {
    optionsEWS() {
      return MonitoringOptions.EWS
    },
    MonitoringOptions() {
      return MonitoringOptions
    },

    Formatter() {
      return Formatter
    },

    historyData() {
      return this.$parent.tHistoryData
    },

    mrValidation() {
      return this.$parent.mrValidation
    },
    
    formData() {
      return this.$parent.formData
    },

    isFillCustomGCS(){
      const {
        gcs_response_mata,
        gcs_response_motorik,
        gcs_response_verbal
      } = this.formData

      let indexResponseMata = this.$parent.mrGCS.responseMata.findIndex(dt => dt.value == gcs_response_mata && dt.gcs_is_other)
      let indexResponseVerbal = this.$parent.mrGCS.responseVerbal.findIndex(dt => dt.value == gcs_response_verbal && dt.gcs_is_other)
      let indexResponseMotorik = this.$parent.mrGCS.responseMotorik.findIndex(dt => dt.value == gcs_response_motorik && dt.gcs_is_other)

      return indexResponseMata >= 0 || indexResponseMotorik >= 0 || indexResponseVerbal >= 0
    },

    objMrCRT() {
      return this.$parent.mrCRT.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    objMrKeadaanUmum() {
      return this.$parent.mrKeadaanUmum.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    objMrGerakan() {
      return this.$parent.mrGerakan.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

  },
}
</script>

<style lang="scss" scoped>
.button-action-gcs {
  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
