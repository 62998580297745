<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 mb-2">
        <div class="bg_card_blue p-3">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Reg</h4>
                    <p>{{isParent.rowReg.aranr_reg_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{isParent.rowReg.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Pasien</h4>
                    <p>{{isParent.rowReg.ap_fullname||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir </h4>
                    <p>{{isParent.rowReg.ap_address||"-"}}, 
                    <span v-if="isParent.rowReg.ap_dob">{{isParent.rowReg.ap_dob | moment("DD MMMM YYYY")}}</span>
                    <span v-else> - </span>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{isParent.rowReg.ap_usia_with_ket}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{isParent.rowReg.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">

          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Diagnosa</h4>
                    <p>{{rowData.arankpkrm_diagnosa||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Restrain</h4>
                    <p>{{rowData.arankpkrm_jenis_restrain == 'F'?'Restrain Farmakologi':'Restrain Non Farmakologi'}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Lokasi Pemasangan</h4>
                    <p v-for="(v,k) in (rowData.arankpkrm_lokasi_pemantauan||[])" :key="k">
                      {{v}}
                    </p>
                    <p v-if="!(rowData.arankpkrm_lokasi_pemantauan||[]).length"> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Tanggal Pemantauan</h4>
                    <p>{{rowData.arankpkr_date | moment("DD MMMM YYYY")}}, {{rowData.arankpkr_jam}}</p>
                  </div>
                </td>

              </tr>

            </tbody>

          </table>
        </div>
        
        <div class="card" v-if="rowData.arankpkrm_jenis_restrain == 'N'">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Restrain Non Farmakologi</h6>
          </div>
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td style="width:50%;">
                  <div class="result_tab">
                    <h4>Pukul</h4>
                    <p>{{rowData.arankpkrm_pukul||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Ikatan</h4>
                    <p>{{rowData.arankpkrm_jenis_ikatan||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="width:50%;">
                  <table class="table-bordered table table-sm">
                    <thead>
                      <tr>
                        <th colspan="2">Kondisi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Edema</h4>
                            <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankpkrm_edema)||"-"}}</p>
                          </div>
                        </td>

                        <td>
                          <div class="result_tab">
                            <h4>Sensorik</h4>
                            <p>{{getConfigDynamic(Config.mr.baikNoOpt,rowData.arankpkrm_sensorik)||"-"}}</p>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Iritasi</h4>
                            <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arankpkrm_iritasi)||"-"}}</p>
                          </div>
                        </td>

                        <td>
                          <div class="result_tab">
                            <h4>Sirkulasi</h4>
                            <p>{{getConfigDynamic(Config.mr.baikNoOpt,rowData.arankpkrm_sirkulasi)||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Hasil</h4>
                    <p>{{getConfigDynamic(Config.mr.configManfaat,rowData.arankpkrm_is_manfaat)||"-"}}</p>
                  </div>
                </td>

                <td>
                  <div class="result_tab">
                    <h4>Petugas</h4>
                    <p>PPA: {{rowData.arankpkrm_petugas_ppa||"-"}}, ({{rowData.arankpkrm_petugas_nama||"-"}})</p>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>
        </div>

         
        <div class="card" v-else>
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Restrain Farmakologi</h6>
          </div>
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pukul</h4>
                    <p>{{rowData.arankpkrm_pukul||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="width:50%;">
                  <table class="table-bordered table table-sm">
                    <thead>
                      <tr>
                        <th colspan="2">Tanda Tanda Vital</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Tekanan Darah</h4>
                            <p>{{rowData.arankpkrm_ttv_tekanan_darah_min||"-"}}/{{rowData.arankpkrm_ttv_tekanan_darah_max||"-"}} mmHG</p>
                          </div>
                        </td>

                        <td>
                          <div class="result_tab">
                            <h4>Nadi</h4>
                            <p>{{rowData.arankpkrm_ttv_nadi||"-"}} x/mnt/{{getConfigDynamic(Config.mr.StatusRegular,rowData.arankpkrm_ttv_label)||"-"}}</p>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Suhu</h4>
                            <p>{{rowData.arankpkrm_ttv_suhu||"-"}} c</p>
                          </div>
                        </td>

                        <td>
                          <div class="result_tab">
                            <h4>Kesadaran</h4>
                            <template v-if="rowData.arankpkrm_response_gcs">
                              <div class="">
                              <span class="font-weight-semibold">Nilai GCS: {{rowData.arankpkrm_response_gcs}}</span>
                              <span v-if="rowData.arankpkrm_response_gcs >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                              <span v-else-if="rowData.arankpkrm_response_gcs >= 12 && rowData.arankpkrm_response_gcs <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                              <span v-else-if="rowData.arankpkrm_response_gcs >= 10 && rowData.arankpkrm_response_gcs <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                              <span v-else-if="rowData.arankpkrm_response_gcs >= 7 && rowData.arankpkrm_response_gcs <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                              <span v-else-if="rowData.arankpkrm_response_gcs >= 5 && rowData.arankpkrm_response_gcs <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                              <span v-else-if="rowData.arankpkrm_response_gcs >= 4 && rowData.arankpkrm_response_gcs <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                              <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                              </div>
                            </template>
                            <span v-else> - </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Perawat yang Mengisi</h4>
                    <p>{{rowData.arankpkrm_perawat_nama||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <table class="table-bordered table table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Jenis</th>
                        <th>Nama Obat</th>
                        <th>Frekuensi</th>
                        <th>Rute</th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v,k) in (resepObat||[])" :key="k">
                        <td>{{k+1}}</td>
                        <td>{{v.arankpkrr_jenis == 1 ? 'Obat' : v.arankpkrr_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</td>
                        <td>{{v.arankpkrr_nama||"-"}}</td>
                        <td>{{v.mdo_name||"-"}}</td>
                        <td>{{v.arankpkrr_rute||"-"}}</td>
                        <td>{{v.arankpkrr_keterangan||"-"}}</td>
                      </tr>
                      <tr v-if="!(resepObat||[]).length">
                        <td colspan="99" class="text-center">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </td>

                <td>
                  <div class="result_tab">
                    <h4>Perawat yang memberikan obat</h4>
                    <p>{{rowData.arankpkrm_perawat_pemberi_obat||"-"}}</p>
                  </div>
                </td>

                <td>
                  <div class="result_tab">
                    <h4>Tindak Lanjut</h4>
                    <p>{{rowData.arankpkr_is_next_monitoring == 'Y'?'Monitoring Dilanjutkan':'Monitoring Dihentikan'}}</p>
                  </div>
                </td>

                <td>
                  <div class="result_tab">
                    <h4>Pemeriksaan Selanjutnya</h4>
                    <p v-if="rowData.arankpkr_is_next_monitoring == 'Y'">{{rowData.arankpkrm_waktu_result | moment("DD MMMM YYYY, HH:mm")}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  data() {
    return {
      rowData: {},
      rowReg: {},
      resepObat: [],
    }
  },
  methods: {
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    apiGetForm(params = {}, page = 1){
      if(!this.regId) if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = (this.isParent.regId ? "RanapPasienKajianRestrain" : this.modulePage)+'/'+(this.$parent.regId||this.$route.params.pageSlug)+'/'+this.isParent.rowEdit.arankpkr_id+'?monitoring=1'
            
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          console.log("here")
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })
    },
  },
  mounted() {
    this.apiGetForm()
  }
}
</script>