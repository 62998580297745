<template>
<div>
  <table class="table table-bordered table-sm patient-table">
    <thead>
      <tr>
        <th>No. Reg</th>
        <th>Perawat</th>
        <th>Tanggal</th>
        <th>Kategori</th>
        <th>Kejadian</th>
        <th>Aksi</th>
      </tr>
    </thead>
    <tbody v-for="(v,k) in (riwayatHais||[])" :key="k+'reg'">
      <tr v-for="(v1,k1) in (v.data||[])" :key="k1+'regData'">
        <td v-if="k1 == 0" :rowspan="v.rowspan">
          <a class="font-weight-semibold border-bottom">{{v.aranr_reg_code}}</a>
        </td>
        <td>
          <a class="font-weight-semibold border-bottom">{{v1.bu_full_name||"-"}}</a>
        </td>
        <td>{{v1.aranha_created_date | moment("DD MMM YYYY")}}</td>
        <td><span class="text-danger font-weight-semibold">{{v1.mh_name||"-"}}</span></td>
        <td>{{getKejadian(v1.aranha_kejadian)||"-"}}</td>
        <td v-if="k1 == 0" :rowspan="v.rowspan">
          <a href="javascript:;" @click="getDetailHais(v.aranr_id)" data-toggle="modal" data-target="#DetailHais" class="btn alpha-primary border-primary text-primary-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>

          <a href="javascript:;" @click="downloadReport(v.aranr_id)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Unduh Laporan HAIs"><i class="icon-download"></i></a>
        </td>
      </tr>
    </tbody>

    <tbody v-if="!(riwayatHais||[]).length">
      <tr>
        <td colspan="6">Tidak ada data HAIs</td>
      </tr>
    </tbody>
  </table>
  <b-modal v-model="openTindakan" :title="'Riwayat Hais'" size="xl" hide-footer>
    <template v-if="(dataHais||[]).length">
      <div class="modal-header d-block">
        <ul class="nav nav-tabs nav-tabs-bottom mb-0">
          <li v-for="(v,k) in (dataHais||[])" :key="k+'hais'" class="nav-item"><a href="javascript:;" :class="activeTabHais == (k+1) ? 'nav-link active' : 'nav-link'" data-toggle="tab" data-target="#tabHais1" @click="changeTabHais(k+1)">Kejadian #{{k+1}}</a></li>
        </ul>
      </div>
      <div class="modal-body">
        <div class="tab-content">
          <div v-for="(v,k) in (dataHais||[])" :key="k+'hais2'" :class="activeTabHais == (k+1) ? 'tab-pane fade show active': 'tab-pane fade'" id="tabHais1">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody class="detail_bo">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>No. Reg</h4>
                        <p>{{v.ar_reg_code||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Perawat</h4>
                        <p>{{v.bu_full_name||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Tanggal</h4>
                        <p>{{v.aranha_created_date | moment("DD MMM YYYY")}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kategori</h4>
                        <p> {{v.mh_name||"-"}}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Komponen</h4>
                        <ul>
                          <li v-for="(v1,k1) in (v.aranha_komponen||[])" :key="k1">{{v1||"-"}}</li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <table class="table table-bordered  table-sm patient-table">
                          <thead>
                            <tr>
                              <th>Kejadian</th>
                              <th>Catatan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(v2,k2) in (v.aranha_kejadian||[])">
                              <tr v-if="v2.value" :key="k2">
                                <td>{{v2.name||"-"}}</td>
                                <td>{{v2.val_name||"-"}}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <span v-else class="mt-3 d-block">Tidak ada Data Hais</span>
  </b-modal>
</div>
</template>

<script>
import $ from 'jquery'
import Gen from '@/libs/Gen'

const _ = global._
const moment = require('moment')

export default {
  name: "Hais",

  props: {
    regId: Number
  },

  data() {
    return {
      openTindakan: false,
      activeTabHais: 1,
      riwayatHais: [],
      dataHais: [],
    }
  },

  mounted() {
    this.getDataHais()
  },

  methods: {
    getDataHais() {
      Gen.apiRest(
        "/get/" + `RanapHais/${this.regId}`, {}
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    getDetailHais(id) {
      this.$parent.loadingOverlay = true
      Gen.apiRest(
        "/do/" + "RanapHais", {
          data: {
            id: id,
            type: "get-data-hais"
          }
        },
        "POST"
      ).then(res => {
        this.$parent.loadingOverlay = false
        this.openTindakan = true
        this.dataHais = res.data.dataHais
      }).catch(() => {
        this.$parent.loadingOverlay = false
      })
    },
    getKejadian(data) {
      let newArr = []
      for (let i = 0; i < (data || []).length; i++) {
        if (data[i]['value']) {
          newArr.push(data[i]['name'])
        }
      }
      return newArr.join(", ")
    },
    downloadReport(aranr_id) {
      let data = {
        exptype: 'xlsx',
        type: "export",
        id: aranr_id
      }
      let self = this

      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_API_URL + `/report/haisRanap?token=IXs1029102asoaksoas102901290`,
        data: data,
        cache: false,
        xhrFields: {
          responseType: 'blob'
        },
        success: data => {
          self.loadingOverlay = false
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(data)
          link.download = `Hais-${moment().format("YYYY-MM-DD")}.xlsx`
          link.click()
        },
        fail: data => {
          self.loadingOverlay = false
          alert('Not downloaded')
        }
      })
    }
  }
}
</script>
