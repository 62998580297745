<template>
  <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
    <b-tab title="Perawat">
      <div class="mb-3">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td colspan="3">
                <div class="result_tab">
                  <h4>Tanggal/Jam</h4>
                  <p>{{ (dataPerawat.updated_date || dataPerawat.created_date) | moment("LLL") }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>PPA</h4>
                  <p class="text-capitalize">{{ dataPerawat.ppa }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{ dataPerawat.nama_petugas }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Status</h4>

                  <span class="badge bg-warning mb-1" v-if="dataPerawat.status === 'MENUNGGU'">Menunggu </span>
                  <span class="badge bg-teal mb-1" v-else>Selesai </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <template v-if="dataPerawat.status == 'SELESAI'">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-12" v-for="(v, k) in dataPerawat.pendidikan_kesehatan" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle">
                          {{ v.label }} {{ v.note ? `: ${v.note}` : `` }} <span class="text-capitalize" v-if="v.suffix">{{ v.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Hasil Pemeriksaan dan Surat yang Dibawakan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-12" v-for="(v, k) in dataPerawat.hasil_pemeriksaan" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle">
                          {{ v.label }} {{ v.note ? `: ${v.note}` : `` }} <span class="text-capitalize" v-if="v.suffix">{{ v.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pemeriksaan Medis Sebelum Kontrol</h6>
              </div>
              <div class="card-body p-3">
                <p class="mb-0">{{ dataPerawat.pemeriksaan_medis }}</p>
              </div>
            </div>

            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Jadwal Kontrol Berikutnya</h6>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Nama Dokter</th>
                      <th>Tanggal</th>
                      <th>Waktu Periksa</th>
                      <th>Tempat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in dataPerawat.jadwal_kontrol" :key="`jk-${k}`">
                      <td>{{ v.nama_dokter }}</td>
                      <td>{{ v.date | moment("LL") }}</td>
                      <td>{{ v.waktu_periksa || "-" }}</td>
                      <td>{{ v.place }}</td>
                    </tr>

                    <tr v-if="!(dataPerawat.jadwal_kontrol||[]).length">
                      <td colspan="3">
                        <p class="mb-0 text-center">Tidak ada data jadwal kontrol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Tanda Tangan Perawat/Bidan, <br>
              ({{ dataPerawat.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataPerawat.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>

        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Discharge planning belum diatur
          </b-alert>
        </div>
      </div>
    </b-tab>

    <b-tab title="Fisioterapi">
      <div class="row">
        <template v-if="dataFisio.status == 'SELESAI'">        
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-6" v-for="(v, k) in pendidikanKesehatanGroupsFisio" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2" v-for="(dt, kdt) in v" :key="`dt-${kdt}`">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle"> 
                          {{ dt.label }} {{ dt.note ? `: ${dt.note}` : `` }} <span class="text-capitalize" v-if="dt.suffix">{{ dt.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Mengetahui, <br>
              ({{ dataFisio.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataFisio.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>

        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Discharge planning belum diatur
          </b-alert>
        </div>
      </div>
    </b-tab>

    <b-tab title="Farmasi">
      <div class="row">
        <template v-if="dataFarmasi.status == 'SELESAI'">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="320">Jenis</th>
                    <th width="320">Nama</th>
                    <th width="150">Jumlah</th>
                    <th width="210">Frekuensi</th>
                    <th>Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in dataFarmasi.dataResep" :key="`dr-${k}`">
                    <td>
                      {{ v.mjo_name }}
                    </td>
                    <td>
                      {{ v.ardpfar_nama }}
                    </td>
                    <td>{{ v.ardpfar_jumlah }} {{ v.ardpfar_satuan }}</td>
                    <td>{{ v.mdo_name }}</td>
                    <td>{{ v.ardpfar_keterangan || '-' }}</td>
                  </tr>

                  <tr v-if="!(dataFarmasi.dataResep||[]).length">
                    <td colspan="5">
                      <p class="mb-0 text-center">
                        Tidak ada data resep obat
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Mengetahui, <br>
              ({{ dataFarmasi.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataFarmasi.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>
        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Discharge planning belum diatur
          </b-alert>
        </div>
      </div>
    </b-tab>

    <b-tab title="Dietisien">
      <div class="row">
        <template v-if="dataDietisien.status == 'SELESAI'">        
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-md-6" v-for="(v, k) in pendidikanKesehatanGroupsDietisien" :key="`pkg-${k}`">
                    <div class="req-list">
                      <div class="d-flex mb-2" v-for="(dt, kdt) in v" :key="`dt-${kdt}`">
                        <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                        <span class="d-inline-block align-middle"> 
                          {{ dt.label }} {{ dt.note ? `: ${dt.note}` : `` }} <span class="text-capitalize" v-if="dt.suffix">{{ dt.suffix }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Mengetahui, <br>
              ({{ dataDietisien.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataDietisien.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>


        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Discharge planning belum diatur
          </b-alert>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import Gen from '@/libs/Gen'

const _ = global._

export default { 
  name: "DischargePlanning",

  props: {
    regId: Number
  },

  data() {
    return {
      dataPerawat: {},
      dataFisio: {},
      dataFarmasi: {},
      dataDietisien: {}
    }
  },

  mounted(){
    this.getDataPerawat()
    this.getDataFarmasi()
    this.getDataDietisien()
    this.getDataFisio()
  },

  methods: {
    getDataPerawat(){
      Gen.apiRest(
        "/get/" + 'RanapDPPerawat', {
          params: {
            id_registrasi: this.regId,
            type: 'get-detail'
          }
        },
      ).then(res => {
        this.$set(this, 'dataPerawat', {
          ...res.data.data,
        })
      })
    },
    getDataFarmasi(){
      Gen.apiRest(
        "/get/" + "RanapDPFarmasi", {
          params: {
            type: "get-detail",
            id_registrasi: this.regId,
          }
        },
      ).then(res => {
        this.$set(this, 'dataFarmasi', {
          ...res.data.data,
          dataResep: res.data.dataResep,
          ...res.data.data.data
        })
      })
    },

    getDataDietisien(){
      Gen.apiRest(
        "/get/" + "RanapDPDietisien", {
          params: {
            type: "get-detail",
            id_registrasi: this.regId
          }
        },
      ).then(res => {
        this.$set(this, 'dataDietisien', {...res.data.data})
      })
    },

    getDataFisio(){
      Gen.apiRest(
        "/get/" + "RanapDPFisioterapi", {
          params: {
            type: "get-detail",
            id_registrasi: this.regId
          }
        },
      ).then(res => {
        this.$set(this, 'dataFisio', {...res.data.data})
      })
    },
  },

  computed: {
    pendidikanKesehatanGroupsDietisien(){
      return _.chunk(this.dataDietisien.pendidikan_kesehatan, 2)
    },
    pendidikanKesehatanGroupsFisio(){
      return _.chunk(this.dataFisio.pendidikan_kesehatan, 2)
    },
  },

  
}
</script>