<template>
<div>
  <table class="table table-bordered table-sm patient-table">
    <thead>
      <tr>
        <th>Nama Dokumen</th>
        <th>Status</th>
        <th>Aksi</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Persetujuan Umum</td>
        <td>
          <span v-if="row.aranres_general_consent_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_general_consent_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_general_consent_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_general_consent_temp',row.aranres_id,'General Consent')" v-if="row.aranres_general_consent_status == 'U' || row.aranres_general_consent_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>

          <a href="javascript:;" @click="downloadFile('aranres_general_consent_temp',row.aranres_id,'General Consent')" v-if="row.aranres_general_consent_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_general_consent_file)" target="_blank" v-if="row.aranres_general_consent_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>
      <tr>
        <td>Persetujuan Rawat Inap Pasien</td>
        <td>
          <span v-if="row.aranres_pd_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_pd_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_pd_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_pd_temp',row.aranres_id,'Persetujuan Rawat Inap')" v-if="row.aranres_pd_status == 'U' || row.aranres_pd_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_pd_temp',row.aranres_id,'Persetujuan Rawat Inap')" v-if="row.aranres_pd_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_pd_file)" target="_blank" v-if="row.aranres_pd_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.aranres_jaminan == Config.var.isBPJS && row.aranres_is_naik == 'Y'">
        <td>Pernyataan Naik Kelas <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
        <td>
          <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Naik Kelas')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Naik Kelas')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.aranres_jaminan == Config.var.isBPJS && row.aranres_is_naik == 'N'">
        <td>Pernyataan Turun Kelas</td>
        <td>
          <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Turun Kelas')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Turun Kelas')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.aranres_jaminan == Config.var.isAsuransi">
        <td>Pernyataan Pasien Asuransi</td>
        <td>
          <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Asuransi')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Asuransi')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.aranres_jaminan == Config.var.isUmum">
        <td>Pernyataan Pasien Non-Asuransi</td>
        <td>
          <span v-if="row.aranres_pj_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_pj_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_pj_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Non-Asuransi')" v-if="row.aranres_pj_status == 'U' || row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_pj_template',row.aranres_id,'Pernyataan Pasien Non-Asuransi')" v-if="row.aranres_pj_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_pj_file)" target="_blank" v-if="row.aranres_pj_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.aranres_bm_is == 'Y'">
        <td>Pernyataan Belum Menikah <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
        <td>
          <span v-if="row.aranres_bm_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_bm_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_bm_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_bm_temp',row.aranres_id,'Pernyataan Belum Menikah')" v-if="row.aranres_bm_status == 'U' || row.aranres_bm_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_bm_temp',row.aranres_id,'Pernyataan Belum Menikah')" v-if="row.aranres_bm_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_bm_file)" target="_blank" v-if="row.aranres_bm_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.aranres_kn_is == 'Y'">
        <td>Keterangan Nikah Secara Agama <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
        <td>
          <span v-if="row.aranres_kn_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_kn_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_kn_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_kn_temp',row.aranres_id,'Keterangan Nikah Secara Agama')" v-if="row.aranres_kn_status == 'U' || row.aranres_kn_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_kn_temp',row.aranres_id,'Keterangan Nikah Secara Agama')" v-if="row.aranres_kn_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_kn_file)" target="_blank" v-if="row.aranres_kn_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.aranres_kron_is == 'Y'">
        <td>Pernyataan Kronologi Kejadian <span class="font-weight-semibold text-danger-800">(Perlu Materai)</span></td>
        <td>
          <span v-if="row.aranres_kron_status == 'U'" class="badge badge-info">Unggah TTD</span>
          <span v-else-if="row.aranres_kron_status == 'DD'" class="badge badge-success">Dokumen TTD Diunggah</span>
          <span v-else-if="row.aranres_kron_status == 'DT'" class="badge badge-success">Di Tandatangani</span>
          <span v-else> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="downloadFile('aranres_kron_temp',row.aranres_id,'Keterangan Kronologi Kejadian')" v-if="row.aranres_kron_status == 'U' || row.aranres_kron_status == 'DD'" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Dokumen Template"><i class="icon-download"></i></a>          

          <a href="javascript:;" @click="downloadFile('aranres_kron_temp',row.aranres_id,'Keterangan Kronologi Kejadian')" v-if="row.aranres_kron_status == 'DT'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>

          <a :href="$parent.uploader(row.aranres_kron_file)" target="_blank" v-if="row.aranres_kron_status == 'DD'" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Manual"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.surat_pengantar">
        <td>Surat Pengantar Rawat Inap</td>
        <td>
          <span> - </span>
        </td>
        <td>
          <a href="javascript:;" @click="doOpenPengantar()" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
        </td>
      </tr>

      <tr v-if="row.surat_penetapan_dpjp">
        <td>Surat Penetapan DPJP</td>
        <td>
          <span> - </span>
        </td>
        <td>
          <a :href="$parent.uploader(row.surat_penetapan_dpjp)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen TTD Digital"><i class="icon-download"></i></a>
        </td>
      </tr>

    </tbody>
  </table>
</div>
</template>

<script>
import $ from 'jquery'
import Gen from '@/libs/Gen'
import Config from '@/libs/Config'

const _ = global._
const moment = require('moment')

export default {
  name: "DokumenPersetujuanRanap",

  props: {
    regId: Number
  },

  data() {
    return {
      row: {},

    }
  },

  mounted() {
    this.getDataDocument()
  },

  methods: {
    getDataDocument() {
      Gen.apiRest(
        "/get/" + `RanapReservasi/${this.regId}`, {}
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    downloadFile(field, id, val) {
      let data = {
        exptype: 'xlsx',
        type: "export",
        id: id,
        field: field
      }
      let self = this
      self.$parent.loadingOverlay = true
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_API_URL + `/dokumen-ttd-ranap/DokumenTTD?token=IXs1029102asoaksoas102901290`,
        data: data,
        cache: false,
        xhrFields: {
          responseType: 'blob'
        },
        success: data => {
          self.$parent.loadingOverlay = false
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(data)
          link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
          link.click()
        },
        fail: data => {
          self.$parent.loadingOverlay = false
          alert('Not downloaded')
        }
      })
    },
  },

  computed: {
    Config() {
      return Config
    },
  }
}
</script>
